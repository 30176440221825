import {Pred, PredIndexed, PredIndexedOptional} from "remeda/dist/es/_types";
import {purry} from "remeda";

/**
 * partition the elements of an array to two or more arrays as specified in a callback function.
 * @param array The array to partition.
 * @param fn the callback function.
 * @signature
 *    R.partition(array, fn)
 *    R.partition.indexed(array, fn)
 * @example
 *    R.partition([1, 2, 3], x => x % 2 === 1) // => [[1, 3], [2]]
 *    R.partition.indexed([1, 2, 3], (x, i, array) => x % 2 === 1) // => [[1, 3], [2]]
 * @data_first
 * @indexed
 * @category Array
 */;
export function partition<T>(array: readonly T[], fn: Pred<T, boolean | number>): T[][];

/**
 * Partition the elements of an array to two or more arrays.
 * @param fn the callback function.
 * @signature
 *    R.partition(fn)(array)
 *    R.partition.indexed(fn)(array)
 * @example
 *    R.pipe([1, 2, 3], R.partition(x => x % 2 === 1)) // => [[1, 3], [2]]
 *    R.pipe([1, 2, 3], R.partition.indexed((x, i) => x % 2 === 1)) // => [[1, 3], [2]]
 * @data_last
 * @indexed
 * @category Array
 */
export function partition<T>(fn: Pred<T, boolean | number>): (array: readonly T[]) => T[][];

export function partition() {
    return purry(_partition(false), arguments);
}

const _partition = (indexed: boolean) => <T>(
    array: T[],
    fn: PredIndexedOptional<T, boolean | number>
) => {
    let partitions: T[][] = [[], []];

    for (let [index, val] of array.entries()) {
        let part = indexed ? fn(val, index, array) : fn(val);
        if (typeof part === "number") {
            while (part >= partitions.length) {
                partitions.push([]);
            }
            partitions[part].push(val);
        } else {
            partitions[part ? 0 : 1].push(val);
        }
    }

    return partitions;
};

export namespace partition {
    export function indexed<T>(
        array: readonly T[],
        fn: PredIndexed<T, boolean | number>
    ): T[][];
    /**
     * @data_last
     */
    export function indexed<T>(
        fn: PredIndexed<T, boolean | number>
    ): (array: readonly T[]) => T[][];
    export function indexed() {
        return purry(_partition(true), arguments);
    }
}
