import { TypeInfo } from "@blockwell/eth-types";
import { DumbappFormInput, DumbappValue } from "./schema";

export function queryDataToValue(data: string | string[], typing?: TypeInfo) {
    if (typing?.name === "tuple") {
        let values = Array.isArray(data) ? data : data.split(/\n/);
        let converted: DumbappValue[] = [];
        let i = 0;
        for (let val of values) {
            converted.push(queryDataToValue(val, typing.components[i]));
            ++i;
        }
        return converted;
    }
    if (typing?.array) {
        let values = Array.isArray(data) ? data : data.split(/\n/);
        if (typing?.name === "bool") {
            return values.map(stringToBool);
        } else {
            return values;
        }
    }
    if (Array.isArray(data)) {
        data = data[0];
    }
    if (typing?.name === "bool") {
        return stringToBool(data);
    }
    return data;
}

export function formDataToValue(data: DumbappFormInput, typing?: TypeInfo) {
    if (Array.isArray(data)) {
        let array = data.map((it) => {
            let values = Object.values(it);
            if (values.length === 1) {
                return values[0];
            }
            if (values.length === 0) {
                return "";
            }
            console.warn("formData array object had more than one value: ", values);
            return values as any as string;
        });

        if (typing?.name === "bool") {
            return array.map(stringToBool);
        }
        return array;
    }
    return data;
}

export function stringToBool(data: string): boolean {
    switch (data.toLowerCase()) {
        case "0":
        case "false":
        case "no":
            return false;
        default:
            return true;
    }
}
