import { Wallet, WalletConnectionEvent, WalletFactory, WalletState } from "../Wallet";
import { getMetamaskContext, MetamaskContext } from "./MetamaskContext";
import {
    DumbappArguments,
    ExecutionController,
    ExecutionExecuteResult, ExecutionHandler,
    ExecutionState,
    ExecutionStepArguments
} from "../../state";
import { EthNetwork, getChain } from "@blockwell/chains";
import { DumbappGasEstimate, DumbappResult, EthExecutor, ExecutorParameters } from "../../executor";
import { DumbappSubmission } from "../../submission";
import { DumbappStep } from "../../schema";
import Emittery from "emittery";

export class MetamaskWallet implements Wallet {
    type = "metamask";

    get events(): Emittery<{ connection: WalletConnectionEvent }> {
        return this.context.events;
    }

    get state() {
        return this.context.state;
    }

    constructor(public context: MetamaskContext, private parameters: ExecutorParameters) {}

    checkConnection(): Promise<boolean> {
        return this.context.checkConnection();
    }

    async connect(): Promise<WalletState> {
        await this.context.connect();
        return this.context.state;
    }

    getAccount(): string {
        return this.context.account;
    }

    requestNetwork(network: EthNetwork): Promise<number> {
        return this.context.requestNetwork(network);
    }

    register(controller: ExecutionController): void {
        controller.addHandler("MetamaskWallet", "prepareWallet", this.context.prepare);
        controller.addHandler("MetamaskWallet", "preExecute", this.context.preExecute);
        controller.addHandler("MetamaskWallet", "execute", this.executeHandler);
    }

    unregister(controller: ExecutionController): void {
        controller.removeHandler("MetamaskWallet");
    }

    estimateGas(
        state: ExecutionState,
        step: DumbappStep,
        args: ExecutionStepArguments
    ): Promise<DumbappGasEstimate> {
        let net: EthNetwork = getChain(args.chainId);

        return EthExecutor.estimateGas(
            {ext: this.context.ethereum, from: state.wallet.account},
            state.dumbapp,
            step,
            args,
            net
        );
    }

    public readonly executeHandler: ExecutionHandler = async (state, args) => {
        let executor = new EthExecutor(this.type, this.context.ethereum, this.parameters.contracts);
        return await executor.execute(state, args);
    };

    updateStatus(
        submission: DumbappSubmission
    ): Promise<DumbappResult> {
        let executor = new EthExecutor(this.type, this.context.ethereum, this.parameters.contracts);
        return executor.updateStatus(submission);
    }
}

export const metamaskFactory: WalletFactory<MetamaskWallet> = {
    type: "metamask",
    async createWallet(parameters, window): Promise<MetamaskWallet> {
        let context = await getMetamaskContext(window);

        return new MetamaskWallet(context, parameters);
    },
};
