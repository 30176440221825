import { BaseFragment } from "@blockwell/eth-types";
import equals from "fast-deep-equal";
import { clone, omit, pick } from "remeda";
import standardAbis from "./standard-abis";

export class AbiRegistry {
    private map: Map<string, BaseFragment> = new Map();

    constructor() {
        this.addAll(standardAbis);
    }

    get(key: string): BaseFragment | undefined {
        return this.map.get(key);
    }

    addAll(abis: { [k: string]: BaseFragment }) {
        for (let [key, abi] of Object.entries(abis)) {
            this.set(key, abi);
        }
    }

    add(abi: BaseFragment): string {
        let key = abi.name;

        let suffix = 2;
        while (this.map.has(key)) {
            // Normalize the ABIs first
            let existing = deserialize(serialize(this.get(key)));
            let normalized = deserialize(serialize(abi));

            if (equals(existing, normalized)) {
                // If they're equal, just use that key
                return key;
            }

            key = abi.name + suffix;
            ++suffix;
        }

        this.set(key, abi);

        return key;
    }

    set(key: string, value: BaseFragment): AbiRegistry {
        let val = clone(value);
        if (!val.type) {
            val.type = "function";
            val.stateMutability = "view";
        }
        this.map.set(key, Object.freeze(val));
        return this;
    }

    toJSON() {
        let json: Record<string, BaseFragment> = {};

        for (let [key, val] of this.map.entries()) {
            if (val.type === "function" && val.stateMutability === "view") {
                val = omit(val, ["type", "stateMutability"]);
            }

            json[key] = val;
        }

        return json;
    }
}

function serialize(element: BaseFragment) {
    if (!element.stateMutability) {
        if (element.constant === false) {
            if (element.payable) {
                element.stateMutability = 'payable';
            } else {
                element.stateMutability = 'nonpayable';
            }
        } else {
            element.stateMutability = "view";
        }
    }
    if (!element.type) {
        element.type = "function";
    }

    return JSON.stringify(element, (key, value) => {
        if (value.type) {
            let data: any = pick(value, ['name', 'type', 'indexed', 'anonymous', 'stateMutability', 'inputs', 'outputs', 'components']);
            if (data.name === "") {
                delete data.name;
            }
            return data;
        }
        return value;
    });
}

function deserialize(data: string): BaseFragment {
    let fragment: BaseFragment = JSON.parse(data);
    return fragment;
}
