import { CachedContractsApi, ContractData } from "@blockwell/apiminer-client";
import { DumbappSubmission } from "../submission";
import { chainEqual, EthNetwork, getChain, InfuraNode } from "@blockwell/chains";
import {
    DumbappStep,
    DumbappSubmissionStepData,
    DumbappSubmissionStepError,
    DumbappSubmissionStepNotice,
} from "../schema";
import { DumbappSubmissionStep } from "../submission";
import { ExecutorBase } from "./ExecutorBase";
import {
    DumbappArguments,
    ExecutionExecuteResult,
    ExecutionState,
    ExecutionStepArguments,
} from "../state";
import { ExternalProvider } from "@ethersproject/providers";
import { Dumbapp } from "../Dumbapp";
import { Chain, ChainClientSender } from "@blockwell/chain-client";
import BigNumber from "bignumber.js";
import { EthLog, parameterToString } from "@blockwell/eth-types";
import { clone } from "remeda";
import { delay } from "@blockwell/util";
import { DumbappSubmissionData } from "../submission";
import { isContractStep, isEtherStep } from "../util";
import { ExecutorParameters } from "./ExecutorParameters";
import { DumbappGasError, DumbappGasEstimate } from "./DumbappGasEstimate";
import { JsonFragment } from "@ethersproject/abi";

interface UpdateContext {
    submission: DumbappSubmission;
    stepIndex: number;
    dumbappStep: DumbappStep;
    step: DumbappSubmissionStep;
    update: EthSubmissionStep;
    net: EthNetwork;
}

/**
 * This class tracks the results of Promises from sending transactions.
 *
 * The reason it exists is MetaMask. MetaMask does not provide any way to track
 * transactions beyond the Promise it returns, so when the Promise stalls indefinitely
 * we run into problems with updates. Instead of waiting for the Promise indefinitely,
 * we wait for 1 second, and if it's not resolved we assume the submission's status is
 * waiting for MetaMask confirmation, and move on. Then when the status gets updated later,
 * this class will check the status of the Promise.
 *
 * It's possible for the browser window to refresh or be closed in this state, at which
 * point we have no choice but to tell the user the transaction's status is unknown.
 *
 * Things are made more complicated by the fact that the user can have multiple browser
 * windows open, any one of which can check status updates. If a different window tries
 * to check the status, it may think MetaMask was lost, so we have to guard against that.
 *
 */
class TransactionResults {
    private promises: Record<string, Promise<any>> = {};
    private results: Record<
        string,
        | {
              error: {
                  error?: Error;
                  code: string | number;
                  message?: string;
                  data?: Record<string, any>;
              };
          }
        | { hash: string }
    > = {};

    constructor() {
        this.results = {};
    }

    /**
     * Adds a transaction hash Promise to be resolved.
     */
    add(id: string, promise: Promise<any>) {
        this.promises[id] = promise;
        promise
            .then((hash) => {
                this.results[id] = { hash };
            })
            .catch((err) => {
                console.error(err);
                this.results[id] = { error: err };
            })
            .finally(() => {
                delete this.promises[id];
            });
    }

    /**
     * Get the status of a transaction.
     *
     * Returns `true` if the Promise is still pending, an object with the `error` attribute
     * if there was an error, a string if there's a transaction hash, and null if the result
     * doesn't exist.
     */
    get(id: string) {
        if (this.promises[id]) {
            return true;
        }
        let res = this.results[id];
        if (res) {
            if ("error" in res) {
                return res;
            }
            return res.hash;
        }
        return null;
    }
}

const results = new TransactionResults();

export class EthExecutor extends ExecutorBase {
    constructor(
        public type: string,
        public ethereum?: ExternalProvider,
        public api?: CachedContractsApi
    ) {
        super();
    }

    async estimateGas(
        state: ExecutionState,
        step: DumbappStep,
        args: ExecutionStepArguments,
        parameters: ExecutorParameters
    ) {
        let net: EthNetwork = getChain(args.chainId);

        return EthExecutor.estimateGas(
            { ext: this.ethereum, from: state.wallet.account },
            state.dumbapp,
            step,
            args,
            net
        );
    }

    static async decodeError(err: any, abi?: JsonFragment[]) {
        let response: { code: number; data?: string; message: string } =
            err.error?.data?.originalError || err.error.data || err.error.error;

        if (!response && err.error?.response) {
            let res = err.error.response;
            if (typeof res === "string") {
                try {
                    let data = JSON.parse(err.error.response);
                    response = data.error;
                } catch (err) {
                    console.log("Failed to parse response from node:", res);
                }
            }
        }

        if (response && response.code === 3 && response.data) {
            let result: DumbappGasError = {
                code: "execution_error",
            };
            let decoded = await Chain.decodeError(response.data, abi);
            if (decoded) {
                if ("code" in decoded) {
                    result.message = decoded.defaultMessage || decoded.identifier;
                } else {
                    result.message =
                        decoded.name +
                        "(" +
                        decoded.inputs.map((it) => parameterToString(it)).join(", ") +
                        ")";
                }
            } else {
                result.message = "Error data: " + response.data;
            }
            return result;
        }
        return null;
    }

    static async estimateGas(
        sender: ChainClientSender,
        dumbapp: Dumbapp,
        step: DumbappStep,
        args: ExecutionStepArguments,
        net: EthNetwork
    ): Promise<DumbappGasEstimate> {
        let price = await Chain.gasPrice(net.chainId);
        let gasPrice: BigNumber;
        if (price.type === 2) {
            gasPrice = new BigNumber(price.maxFeePerGas);
        } else {
            gasPrice = new BigNumber(price.gasPrice);
        }

        let estimate: DumbappGasEstimate = {
            gasPrice,
            value: new BigNumber(0),
        };

        if (dumbapp.steps.length === 1) {
            if (!sender.from) {
                estimate.code = "invalid";
                estimate.message = "Sign In or load a MetaMask wallet to see an estimate.";
                return estimate;
            }

            if (args.missing) {
                estimate.code = "invalid";
                estimate.message =
                    "Some form fields are missing, fill in the form fully to get an estimate.";
                return estimate;
            }

            const handleError = async (err: any) => {
                if (err.code === "INVALID_ARGUMENT") {
                    estimate.code = "invalid";
                    estimate.message = "Some form fields are missing or invalid.";
                } else if (err.code === "INSUFFICIENT_FUNDS") {
                    estimate.code = "funds";
                    let match = /have (\d+) want (\d+) \(supplied gas (\d+)\)/.exec(err.message);
                    if (match) {
                        let want = match[2];
                        estimate.value = new BigNumber(want).shiftedBy(-1 * net.decimals);
                    }
                    return;
                } else if (err.code === "UNPREDICTABLE_GAS_LIMIT") {
                    let decoded = await EthExecutor.decodeError(err, args.contract?.abi);

                    if (decoded?.code) {
                        estimate.code = decoded.code;
                        estimate.message =
                            decoded.message ||
                            "Transaction failed when estimating gas, sending it will likely fail.";
                        return;
                    }
                }
                estimate.code = "error";
                estimate.message =
                    "Transaction failed when estimating gas, sending it will likely fail.";
            };

            if (isEtherStep(step)) {
                if (!args.address || !args.value) {
                    estimate.code = "invalid";
                    estimate.message = "Fill in the form fully for an estimate.";
                } else {
                    try {
                        estimate.value = new BigNumber(args.value).shiftedBy(-1 * net.decimals);
                        let est = await Chain.estimateTransactionGas(
                            sender,
                            args.address,
                            args.value
                        );
                        estimate.gasLimit = est.toNumber();
                    } catch (err) {
                        await handleError(err);
                    }
                }
            } else {
                try {
                    if (args.value) {
                        estimate.value = new BigNumber(args.value).shiftedBy(-1 * net.decimals);
                    }
                    let est = await Chain.estimateWriteGas(
                        sender,
                        args.address,
                        [step.abi],
                        step.method,
                        args.args,
                        args.value
                    );
                    estimate.gasLimit = est.toNumber();
                } catch (err) {
                    await handleError(err);
                }
            }

            if (estimate.gasLimit) {
                estimate.gasCost = new BigNumber(estimate.gasLimit)
                    .times(estimate.gasPrice)
                    .shiftedBy(-1 * net.decimals);
            }
        }
        return estimate;
    }

    async execute(state: ExecutionState, args: DumbappArguments): Promise<ExecutionExecuteResult> {
        let chainId = new BigNumber(
            await this.ethereum.request({ method: "eth_chainId" })
        ).toNumber();

        if (chainId !== args[0].chainId) {
            return {
                success: false,
                code: "network_change",
                message: "Wallet is connected to the wrong network.",
            };
        }

        let steps: EthSubmissionStep[] = [];
        let created = new Date().toISOString();

        let uuidData = Dumbapp.uuidData(state.dumbapp.shortcode, created, state.wallet.account);

        let i = 0;
        for (let step of state.dumbapp.steps) {
            let id = Dumbapp.uuidFromData(uuidData + ":" + i);
            let arg = args[i];

            let stp = new EthSubmissionStep(
                {
                    id,
                    status: "new",
                    address: arg.address,
                    network: arg.chainId,
                    created,
                },
                i
            );
            steps.push(stp);
            ++i;
        }

        let data = new DumbappSubmissionData({
            id: state.id,
            created: state.created,
            steps,
            args,
            executor: this.type,
            shortcode: state.dumbapp.shortcode,
            from: state.wallet.account,
            inputs: state.data,
            extras: state.extras,
        });

        data.steps[0] = await this._sendStep(state.dumbapp, data, 0);

        return this.successResult(data);
    }

    async updateStatus(submission: DumbappSubmission) {
        let steps = [].concat(submission.data.steps);

        let update: EthSubmissionStep;
        let updated = false;

        for (let stepIndex = 0; stepIndex < steps.length; stepIndex++) {
            let step = submission.data.steps[stepIndex];
            if (step.pending()) {
                update = await this._updateStep(submission, stepIndex);

                // If the step was updated, store that
                if (update) {
                    steps[stepIndex] = update;
                    updated = true;
                }

                // If no updates are needed, or it's still pending, we don't want to look at the following steps
                if (!update || update.pending()) {
                    break;
                }

                // If the step failed or went to an unknown state, the remaining steps can't be completed
                if (update.status === "error" || update.status === "unknown") {
                    for (let i = stepIndex + 1; i < steps.length; i++) {
                        let cloned = new EthSubmissionStep(clone(steps[i]), i);
                        cloned.status = "error";
                        cloned.error = {
                            code: "dependency_error",
                            message: "A preceding step of the Dumbapp failed.",
                        };
                        steps[i] = cloned;
                    }
                    break;
                }
            }
        }

        if (updated) {
            let cloned = clone(submission.data);
            cloned.steps = steps;
            let dat = new DumbappSubmissionData(cloned);
            return this.successResult(dat);
        }

        return this.successResult(null);
    }

    private async _updateStep(submission: DumbappSubmission, stepIndex: number) {
        let step = submission.data.steps[stepIndex];
        let dumbappStep = submission.dumbapp.steps[stepIndex];
        // All of the update functions would need to create almost all of these variables every time, so we do it just
        // once here and pass them as arguments.
        let args: UpdateContext = {
            submission,
            stepIndex,
            dumbappStep,
            step,
            update: new EthSubmissionStep(clone(step), stepIndex),
            net: getChain(step.network),
        };
        switch (submission.data.steps[stepIndex].status) {
            case "confirm":
                return this._updateConfirmStep(args);
            case "new":
                return this._updateNewStep(args);
            case "nonce":
            case "submitted":
                return this._updateSubmittedStep(args);
        }
        return null;
    }

    private async _checkEthereum(
        update: EthSubmissionStep,
        net: EthNetwork
    ): Promise<EthSubmissionStep | true> {
        if (!this.ethereum) {
            return this._notice(update, "ethereum_unavailable");
        }
        let chainId = new BigNumber(
            await this.ethereum.request({ method: "eth_chainId" })
        ).toNumber();

        if (chainId !== net.networkId) {
            return this._notice(update, "wrong_network", net.networkId);
        }

        if (this.type === "metamask") {
            let accounts = await this.ethereum.request({ method: "eth_accounts" });

            if (accounts.length === 0) {
                return this._notice(update, "not_connected");
            }
        }
        return true;
    }

    private async _updateNewStep({
        submission,
        stepIndex,
        update,
        net,
    }: UpdateContext): Promise<EthSubmissionStep> {
        let check = await this._checkEthereum(update, net);
        if (check !== true) {
            return check;
        }

        return this._sendStep(submission.dumbapp, submission.data, stepIndex);
    }

    private _notice(
        update: EthSubmissionStep,
        code: string,
        network?: string | number
    ): EthSubmissionStep {
        if (
            update.notice?.code === code &&
            (!update.notice?.network || update.notice.network === network)
        ) {
            return null;
        }

        update.notice = {
            code,
        };

        if (network) {
            update.notice.network = network;
        }
        return update;
    }

    private async _updateConfirmStep({
        submission,
        stepIndex,
        dumbappStep,
        step,
        update,
        net,
    }: UpdateContext) {
        let result = results.get(step.id);

        if (result === null) {
            // Has this step been updated recently? If not, assume MetaMask connection is lost.
            if (!update.updated || update.updated < Date.now() - 10000) {
                console.log("MetaMask not updated in 10 seconds, going to unknown");
                update.status = "unknown";
                update.error = {
                    code: "metamask_lost",
                    message:
                        "Lost connection to MetaMask, cannot determine status. The transaction may have been sent.",
                };
                update.notice = null;
                return update;
            }
            console.log("MetaMask updated recently, ignoring unknown status");
            // If it has been updated recently, don't do anything, assume it's probably another browser window
            // tracking it
            return null;
        }
        if (result === true) {
            // Mark that we've updated it, so other browser windows don't mess with it
            update.updated = Date.now();
            return update;
        }
        if (typeof result === "string") {
            update.notice = null;
            update.transactionHash = result;
            update.status = "submitted";
            return update;
        }
        update.status = "error";
        update.notice = null;
        if (result.error.code === 4001) {
            update.error = {
                code: "rejected",
                message: "Transaction rejected.",
            };
        } else if (result.error.code === "INSUFFICIENT_FUNDS") {
            update.error = {
                code: "insufficient_funds",
                message: "Not enough Ether in the account to send this transaction.",
            };
        } else if (result.error.code === "UNPREDICTABLE_GAS_LIMIT") {
            let contract = await this.api.getContract({
                chainId: step.network,
                address: step.address,
            });
            let decoded = await EthExecutor.decodeError(result.error, contract.abi);
            update.error = {
                code: "execution_error",
                message: decoded?.message
                    ? "Execution failed: " + decoded.message
                    : "The smart contract rejected the transaction due to an unknown reason, possibly a missing user group or not enough tokens.",
            };
        } else {
            if (result.error.error?.message) {
                update.error = {
                    code: "result_error",
                    message:
                        "Error in sending transaction: " +
                        result.error.error.message.replace("execution reverted: ", ""),
                };
            } else {
                update.error = {
                    code: "result_error",
                    message: "Error in sending transaction.",
                };
            }
        }
        return update;
    }

    private async _updateSubmittedStep({
        submission,
        stepIndex,
        dumbappStep,
        step,
        update,
        net,
    }: UpdateContext) {
        let provider: EthNetwork | ExternalProvider;
        let chainId = new BigNumber(
            await this.ethereum.request({ method: "eth_chainId" })
        ).toNumber();
        if (chainId === net.chainId) {
            provider = this.ethereum;
        } else {
            provider = net;
        }

        if (!provider) {
            if (update.notice?.code === "no_provider" && update.notice?.network === step.network) {
                // Don't update it, the notice already matches
                return null;
            }
            update.notice = {
                code: "ethereum_unavailable",
                network: step.network,
            };
            return update;
        }

        if (!step.transactionHash) {
            throw new Error(`Submitted step ${step.id} is missing a transactionHash.`);
        }

        let tx = await Chain.receipt(provider, step.transactionHash);

        if (tx) {
            if (tx.status) {
                update.status = "completed";
                update.ended = new Date().toISOString();

                try {
                    if ("method" in dumbappStep) {
                        const abi = await this.api.getContractAbi({
                            chainId: step.network,
                            address: step.address,
                        });

                        if (abi) {
                            update.events = await Chain.decodeLogs(tx, abi);
                        }
                    }
                } catch (err) {
                    console.error("Error retrieving contract ABI", err.message);
                }
            } else {
                update.status = "error";
            }

            update.notice = null;

            return update;
        }

        return null;
    }

    private async _sendStep(dumbapp: Dumbapp, data: DumbappSubmissionData, stepIndex: number) {
        let dumbappStep = dumbapp.steps[stepIndex];
        let step = data.steps[stepIndex];
        let args = data.args[stepIndex];

        if (!["new", "network", "confirm"].includes(step.status)) {
            throw new Error(
                `Submission ${data.id} step ${stepIndex} attempted send, but status was not 'new' or 'confirm'.`
            );
        }

        let promise;
        if (isEtherStep(dumbappStep)) {
            if (!args.address) {
                throw new Error("Ether transfer step missing an address.");
            }
            if (!args.value) {
                throw new Error("Ether transfer step missing a value.");
            }

            promise = Chain.transaction(
                { ext: this.ethereum, from: data.from },
                args.address,
                args.value
            );
        } else {
            promise = Chain.writeContract(
                { ext: this.ethereum, from: data.from },
                args.address,
                [dumbappStep.abi],
                dumbappStep.method,
                args.args,
                args.value
            );
        }

        // Wait for 1 second to get a transaction hash, otherwise we'll defer the result. This is because
        // MetaMask can take a long time to respond, and it only responds with the Promise - there's no event system.
        // We can't check if a transaction was confirmed if we lose the Promise.
        let transactionHash: string = await Promise.any([promise, delay(1000).then(() => null)]);

        if (transactionHash !== null) {
            console.log("Received transactionHash", transactionHash);
            let submitted = new EthSubmissionStep(step, stepIndex);
            submitted.transactionHash = transactionHash;
            submitted.status = "submitted";
            return submitted;
        } else {
            results.add(step.id, promise);
            let updated = new EthSubmissionStep(step, stepIndex);
            updated.status = "confirm";
            updated.notice = {
                code: "confirm",
            };
            console.log("No hash yet, going into confirm status", updated);
            return updated;
        }
    }
}

export class EthSubmissionStep implements DumbappSubmissionStep {
    created: string;
    ended?: string;
    error?: DumbappSubmissionStepError;
    events?: EthLog[];
    id: string;
    network: string | number;
    notice?: DumbappSubmissionStepNotice;
    status:
        | "new"
        | "confirm"
        | "network"
        | "nonce"
        | "submitted"
        | "completed"
        | "unknown"
        | "error";
    transactionHash?: string;
    updated?: number;
    address: string;

    constructor(data: DumbappSubmissionStepData, public index: number) {
        Object.assign(this, data);
    }

    pending() {
        return this.status !== "completed" && this.status !== "error" && this.status !== "unknown";
    }

    completed() {
        return this.status === "completed";
    }

    pendingConfirmation() {
        return this.status === "confirm";
    }
}
