import { Deferrable } from "@ethersproject/properties";
import { TransactionRequest, Web3Provider } from "@ethersproject/providers";
import { BigNumber } from "@ethersproject/bignumber";
import { RpcProvider } from "./RpcProvider";

export class ExtProvider extends Web3Provider {
    async estimateGas(transaction: Deferrable<TransactionRequest>): Promise<BigNumber> {
        return RpcProvider.estimateGas(this, await super.estimateGas(transaction), transaction);
    }
}
