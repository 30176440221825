export class OtherSourceProvider {
    constructor() {
        this.name = "other";
    }
    getValue(provider, source) {
        if (source.name === "sender") {
            return {
                annotation() {
                    return '<span class="user-address">Your Wallet Address</span>';
                },
                value() {
                    return provider.getAccount();
                },
            };
        }
    }
}
