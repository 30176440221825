import { Listener } from "@ethersproject/providers";
import { MutexMap } from "@blockwell/cache/lib/MutexMap";
import { ChainClient } from "./ChainClient";
import { uniq } from "remeda";
import equals from "fast-deep-equal";
import { Log } from "@ethersproject/abstract-provider/src.ts";
import { debounce } from "@blockwell/util";

type ContractListenerEntry = {
    chainId: number;
    address: string;
    topics?: string[];
    variables: number[];
    chainListener?: Listener;
};

export class VariableUpdateListeners {
    protected variables: Record<
        string,
        {
            listener: Listener;
            topics?: string[];
            entry: ContractListenerEntry;
        }
    > = {};
    protected contracts: Record<string, Record<string, ContractListenerEntry>> = {};

    public activeVariable: number;

    constructor(public readonly client: ChainClient) {}

    protected startListeners = () => {
        for (let [chainId, set] of Object.entries(this.contracts)) {
            for (let [address, entry] of Object.entries(set)) {
                let expectedFilter: string[] = null;

                if (entry.variables.length > 0) {
                    let topic0s = uniq(entry.variables.flatMap((it) => this.variables[it].topics));

                    if (topic0s.length > 1 || topic0s[0] === "*") {
                        // More than one event, listen to everything
                        expectedFilter = [];
                    } else if (topic0s[0]) {
                        // All for a single event, so just listen to that
                        expectedFilter = [topic0s[0]];
                    } else {
                        // A listener for any event, so listen to everything
                        expectedFilter = [];
                    }
                }

                if (!equals(expectedFilter, entry.topics)) {
                    if (entry.chainListener) {
                        this.client.off(entry.chainListener);
                        entry.chainListener = null;
                    }

                    entry.topics = expectedFilter;
                    if (expectedFilter) {
                        entry.chainListener = this.chainListener(chainId, address);

                        this.client.onLogs(
                            chainId,
                            { address, topics: expectedFilter },
                            entry.chainListener
                        );
                    }
                }
            }
        }
    };

    protected chainListener(chainId: string, address: string) {
        return (log: Log) => {
            let entry = this.contracts[chainId][address];

            for (let it of entry.variables) {
                let v = this.variables[it];
                if (this.shouldTrigger(log, v)) {
                    v.listener(log);
                }
            }
        };
    }

    protected shouldTrigger(log: Log, v: { topics?: string[] }) {
        if (v.topics?.length > 0) {
            if (v.topics[0] === "*") {
                return true;
            }
            if (!v.topics.includes(log.topics[0])) {
                return false;
            }
        }
        return true;
    }

    // Debouncing to deduplicate listeners for the same contract
    protected debouncedListeners = debounce(this.startListeners, 50, { maxWait: 100 });

    /**
     * Listen to updates on the chain based on events.
     *
     * You MUST set the activeIndex to the variable index first since it's used for
     * tracking, and then make sure to only call this synchronously afterwards.
     */
    listen(chainId: number, filter: { address: string; topics?: string[] }, listener: Listener) {
        let address = filter.address.toLowerCase();
        if (!this.contracts[chainId]) {
            this.contracts[chainId] = {};
        }
        if (!this.contracts[chainId][address]) {
            this.contracts[chainId][address] = {
                address,
                chainId,
                variables: [this.activeVariable],
            };
        } else {
            this.contracts[chainId][address].variables.push(this.activeVariable);
        }
        let entry = this.contracts[chainId][address];

        this.variables[this.activeVariable] = {
            entry,
            listener,
            topics: filter.topics,
        };

        this.debouncedListeners();
    }

    removeListener(variable: number) {
        let data = this.variables[variable];

        if (data) {
            delete this.variables[variable];
            let entry = data.entry;
            entry.variables = entry.variables.filter((it) => it !== variable);
            this.debouncedListeners();
        }
    }

    destroy() {
        for (let [chainId, set] of Object.entries(this.contracts)) {
            for (let [address, entry] of Object.entries(set)) {
                this.client.off(entry.chainListener);
            }
        }
        this.debouncedListeners.cancel();
        this.contracts = {};
        this.variables = {};
    }
}
