import { AbiCoder, Interface, ParamType } from "@ethersproject/abi";
import { Coder, Reader, Writer } from "@ethersproject/abi/lib/coders/abstract-coder";
import { toUtf8Bytes } from "@ethersproject/strings";
import { hexlify } from "@ethersproject/bytes";
import { DynamicBytesCoder } from "@ethersproject/abi/lib/coders/bytes";

/**
 * Custom version of the string coder that returns the hex data instead when
 * the string isn't valid UTF-8.
 */
class CustomStringCoder extends DynamicBytesCoder {
    private decoder = new TextDecoder("utf-8", { fatal: true });

    constructor(localName: string) {
        super("string", localName);
    }

    defaultValue(): string {
        return "";
    }

    encode(writer: Writer, value: any): number {
        return super.encode(writer, toUtf8Bytes(value));
    }

    decode(reader: Reader): any {
        let data = super.decode(reader);
        try {
            return this.decoder.decode(data);
        } catch (err) {
            data = hexlify(data);
        }

        return data;
    }
}

export class CustomCoder extends AbiCoder {
    _getCoder(param: ParamType): Coder {
        if (param.baseType === "string") {
            return new CustomStringCoder(param.name);
        } else {
            return super._getCoder(param);
        }
    }
}

export class CustomInterface extends Interface {
    static getAbiCoder(): AbiCoder {
        return new CustomCoder();
    }
}
