import {
    DumbappArgument,
    DumbappCallSourceParameters,
    DumbappDynamicSourceParameters,
    DumbappFeeSourceParameters,
    DumbappJsonSourceParameters,
    DumbappLiteralSourceParameters,
    DumbappOtherSourceParameters,
    DumbappSource,
    DumbappStepsSourceParameters,
    DumbappUniswapSourceParameters,
    DumbappValue,
} from "./schema";
import { BaseFragment, Typing } from "@blockwell/eth-types";

interface SourceTypeMapping {
    literal: DumbappLiteralSourceParameters;
    call: DumbappCallSourceParameters;
    dynamic: DumbappDynamicSourceParameters;
    fee: DumbappFeeSourceParameters;
    json: DumbappJsonSourceParameters;
    other: DumbappOtherSourceParameters;
    steps: DumbappStepsSourceParameters;
    uniswap: DumbappUniswapSourceParameters;
}

export interface TypedSource<K extends keyof SourceTypeMapping, T extends SourceTypeMapping[K] = SourceTypeMapping[K] > extends DumbappSource {
    parameters: T;
}

export function isLiteralSource(source: DumbappSource): source is TypedSource<"literal"> {
    return source?.type === "literal";
}

export function getLiteralSourceParameters(arg: DumbappArgument): DumbappLiteralSourceParameters {
    if (arg?.value?.type === "literal") {
        return arg.value.parameters;
    }
    return null;
}

export function isCallSource(source: DumbappSource): source is TypedSource<"call"> {
    return source?.type === "call";
}

export function getCallSourceParameters(arg: DumbappArgument): DumbappCallSourceParameters {
    if (arg?.value?.type === "call") {
        return arg.value.parameters;
    }
    return null;
}

export function isDynamicSource(source: DumbappSource): source is TypedSource<"dynamic"> {
    return source?.type === "dynamic";
}

export function getDynamicSourceParameters(arg: DumbappArgument): DumbappDynamicSourceParameters {
    if (arg?.value?.type === "dynamic") {
        return arg.value.parameters;
    }
    return null;
}

export function isFeeSource(source: DumbappSource): source is TypedSource<"fee"> {
    return source?.type === "fee";
}

export function getFeeSourceParameters(arg: DumbappArgument): DumbappFeeSourceParameters {
    if (arg?.value?.type === "fee") {
        return arg.value.parameters;
    }
    return null;
}

export function isJsonSource(source: DumbappSource): source is TypedSource<"json"> {
    return source?.type === "json";
}

export function getJsonSourceParameters(arg: DumbappArgument): DumbappJsonSourceParameters {
    if (arg?.value?.type === "json") {
        return arg.value.parameters;
    }
    return null;
}

export function isOtherSource(source: DumbappSource): source is TypedSource<"other"> {
    return source?.type === "other";
}

export function getOtherSourceParameters(arg: DumbappArgument): DumbappOtherSourceParameters {
    if (arg?.value?.type === "other") {
        return arg.value.parameters;
    }
    return null;
}

export function isStepsSource(source: DumbappSource): source is TypedSource<"steps"> {
    return source?.type === "steps";
}

export function getStepsSourceParameters(arg: DumbappArgument): DumbappStepsSourceParameters {
    if (arg?.value?.type === "steps") {
        return arg.value.parameters;
    }
    return null;
}

export function isUniswapSource(source: DumbappSource): source is TypedSource<"uniswap"> {
    return source?.type === "uniswap";
}

export function getUniswapSourceParameters(arg: DumbappArgument): DumbappUniswapSourceParameters {
    if (arg?.value?.type === "uniswap") {
        return arg.value.parameters;
    }
    return null;
}

function createSource<K extends keyof SourceTypeMapping, T extends SourceTypeMapping[K] = SourceTypeMapping[K]>(type: K, parameters: T): TypedSource<K, T> {
    return {
        type,
        parameters: <T>parameters,
    };
}

export function createLiteralSource(value: DumbappValue) {
    return createSource("literal", {value});
}

export function createDynamicSource(name: string) {
    return createSource("dynamic", {name});
}

export function createCallSource(abi: string | BaseFragment, address?: DumbappSource, args?: DumbappValue[]) {
    return createSource("call", {
        abi,
        address,
        arguments: args
    });
}

export function createFeeSource(base: DumbappSource, multiplier?: DumbappSource, ignoreDecimals?: boolean) {
    let params: DumbappFeeSourceParameters = {
        base, multiplier
    };

    if (ignoreDecimals) {
        params.ignoreDecimals = true;
    }
    return createSource("fee", params);
}

export function createJsonSource(json: DumbappArgument[], mergeWith?: DumbappSource) {
    return createSource("json", {
        json, mergeWith
    })
}

export function createSenderSource() {
    return createSource("other", {
        name: "sender"
    });
}

/**
 * @param step Step number, starting with 1.
 * @param name Name of the property from the step.
 */
export function createStepsSource(step: number, name: DumbappStepsSourceParameters["name"]) {
    return createSource("steps", {
        step, name
    });
}

export const EthValueArgument: DumbappArgument = {
    type: "uint",
    typing: Typing.uint,
    decimals: createLiteralSource(18),
}
export const EthAddressArgument: DumbappArgument = {
    type: "address",
    typing: Typing.address
}
export const EthStringArgument: DumbappArgument = {
    type: "string",
    typing: Typing.string
}
