import { isBooleans, isStrings } from "@blockwell/util";
import {
    BaseFragment,
    BaseFragmentType,
    EthBooleanArrayParameter,
    EthBooleanParameter,
    EthIndexedParameter,
    EthParameter,
    EthStringArrayParameter,
    EthStringParameter,
    EthTupleArrayParameter,
    EthTupleParameter,
    SolidityType,
    TypeInfo,
} from "./schema";
import { EthersJsonFragmentType } from "./ethers-types";

export interface ExtendedJsonFragment extends BaseFragment {
    signature?: string;
    hash?: string;
    key?: string;
}

export function isStringParameter(param: EthParameter): param is EthStringParameter {
    let type = Typing.parse(param);

    return !type.array && type.name !== "bool" && type.name !== "tuple";
}

export function isTupleParameter(param: EthParameter): param is EthTupleParameter {
    let type = Typing.parse(param);

    return !type.array && type.name === "tuple";
}

export function isBooleanParameter(param: EthParameter): param is EthBooleanParameter {
    let type = Typing.parse(param);

    return !type.array && type.name === "bool";
}

export function isStringArrayParameter(param: EthParameter): param is EthStringArrayParameter {
    let type = Typing.parse(param);

    return type.array && type.name !== "bool" && type.name !== "tuple";
}

export function isTupleArrayParameter(param: EthParameter): param is EthTupleArrayParameter {
    let type = Typing.parse(param);

    return type.array && type.name === "tuple";
}

export function isBooleanArrayParameter(param: EthParameter): param is EthBooleanArrayParameter {
    let type = Typing.parse(param);

    return type.array && type.name === "bool";
}

export function isIndexedParameter(param: EthParameter): param is EthIndexedParameter {
    return !!(<any>param).hash;
}

export function parameterToString(param: EthParameter): string {
    if (isTupleParameter(param)) {
        return param.components.map((it) => parameterToString(it)).join(";");
    }
    if (isTupleArrayParameter(param)) {
        return param.values.map((it) => parameterToString(it)).join(":");
    }
    if (isStringArrayParameter(param) || isBooleanArrayParameter(param)) {
        return param.values.map((it) => it.toString()).join(";");
    }
    if (isIndexedParameter(param)) {
        return param.hash;
    }
    return param.value.toString();
}

export class Typing implements TypeInfo {
    static from(info: TypeInfo) {
        let typing = new Typing(info.name);
        Object.assign(typing, info);
        return typing;
    }

    static toString(info: TypeInfo): string {
        return (
            info.name +
            (info.size || "") +
            (info.array ? "[" : "") +
            (info.length || "") +
            (info.array ? "]" : "")
        );
    }

    static parse(input: EthParameter | BaseFragmentType | EthersJsonFragmentType | string) {
        let type: string;
        if (typeof input === "string") {
            type = input;
        } else {
            type = input.type;
        }
        let match = /^([a-z]+)(\d*)(\[(\d*)])?$/.exec(type);

        let typing: Typing;
        if (match) {
            let name = match[1];
            let array: boolean;
            let size: number;
            let length: number;

            if (match[2]) {
                size = parseInt(match[2]);
            } else if (name === "uint" || name === "int") {
                size = 256;
            }

            // alias
            if (name === "byte") {
                name = "bytes";
                size = 1;
            }

            if (match[3]) {
                array = true;
            }
            if (match[4]) {
                length = parseInt(match[4]);
            }
            typing = new Typing(name as SolidityType, array, size, length);
        } else {
            typing = new Typing("any");
        }

        if (typeof input !== "string") {
            if (input.name) {
                typing.parameterName = input.name;
            }

            if ((<any>input).components) {
                typing.components = (<any>input).components.map(
                    (it: EthParameter | BaseFragmentType) => Typing.parse(it)
                );
            }

            if (input.indexed) {
                typing.indexed = true;
            }

            if ("internalType" in input) {
                typing.internalType = input.internalType;
            }
        }

        return Object.freeze(typing);
    }

    declare components?: Typing[];
    declare parameterName?: string;
    declare indexed?: boolean;
    declare array?: boolean;
    declare size?: number;
    declare length?: number;
    declare internalType?: string;

    constructor(
        public name: SolidityType,
        array = false,
        size?: number,
        length?: number
    ) {
        if (array) {
            this.array = array;
        }
        if (size) {
            this.size = size;
        }
        if (length) {
            this.length = length;
        }
    }

    equals(other: Typing) {
        if (
            this.name !== other.name ||
            this.array !== other.array ||
            this.length !== other.length ||
            this.size !== other.size ||
            this.indexed !== other.indexed
        ) {
            return false;
        }

        if (this.components) {
            if (!other.components) {
                return false;
            }

            if (this.components.length !== other.components.length) {
                return false;
            }

            for (let i = 0; i < this.components.length; i++) {
                if (!this.components[i].equals(other.components[i])) {
                    return false;
                }
            }
        } else if (other.components) {
            return false;
        }

        return true;
    }

    arrayElement() {
        let element = new Typing(this.name, false, this.size);
        element.components = this.components;
        return element;
    }

    isPrimitiveArray(): boolean {
        return (
            this.array &&
            (this.name === "string" ||
                this.name === "address" ||
                this.name === "uint" ||
                this.name === "int" ||
                this.name === "bytes" ||
                this.name === "bool")
        );
    }

    toString(): string {
        return Typing.toString(this);
    }

    toJSON() {
        return this.toString();
    }

    toTypeInfo(): TypeInfo {
        let info: TypeInfo = {
            ...this
        }

        if (this.components) {
            info.components = this.components.map(it => it.toTypeInfo());
        }
        return info;
    }

    static readonly uint = Typing.parse("uint");
    static readonly address = Typing.parse("address");
    static readonly bool = Typing.parse("bool");
    static readonly string = Typing.parse("string");
    static readonly any = Typing.parse("any");
}


type ParamValue = string | boolean | ParamValue[];

export function ethParamsToValues(params: EthParameter[]): ParamValue[] {
    return params.map((it) => {
        if (isStringArrayParameter(it) || isBooleanArrayParameter(it)) {
            return it.values;
        }
        if (isStringParameter(it) || isBooleanParameter(it)) {
            return it.value;
        }
        if (isTupleArrayParameter(it)) {
            return ethParamsToValues(it.values);
        }
        if (isTupleParameter(it)) {
            return ethParamsToValues(it.components);
        }

        return it.hash;
    });
}

export function ethResolveParameter(params: EthParameter[], path: string) {
    if (!params) {
        return null;
    }
    let parts = path.split(".");

    let list = params;
    let part = parts.shift();
    while (part) {
        let num = parseInt(part);
        let param: EthParameter;
        if (isNaN(num)) {
            param = list.find((it) => it.name === part);
        } else {
            param = list[num];
        }
        if (!param) {
            return null;
        }

        part = parts.shift();

        if (!part) {
            return ethParamsToValues([param])[0];
        } else {
            if (isBooleanArrayParameter(param) || isStringArrayParameter(param)) {
                let num = parseInt(part);
                if (isNaN(num)) {
                    return null;
                } else {
                    return param.values[num];
                }
            } else if (isTupleParameter(param)) {
                list = param.components;
            } else if (isTupleArrayParameter(param)) {
                list = param.values;
            } else {
                return null;
            }
        }
    }
}

export function ethResolveStringParameter(params: EthParameter[], path: string) {
    let resolved = ethResolveParameter(params, path);

    if (typeof resolved === "string") {
        return resolved;
    }
    return null;
}

export function ethResolveStringArrayParameter(params: EthParameter[], path: string) {
    let resolved = ethResolveParameter(params, path);

    if (isStrings(resolved)) {
        return resolved;
    }
    return null;
}

export function ethResolveBooleanParameter(params: EthParameter[], path: string) {
    let resolved = ethResolveParameter(params, path);

    if (typeof resolved === "boolean") {
        return resolved;
    }
    return null;
}

export function ethResolveBooleanArrayParameter(params: EthParameter[], path: string) {
    let resolved = ethResolveParameter(params, path);

    if (isBooleans(resolved)) {
        return resolved;
    }
    return null;
}

export const resolve = {
    string: ethResolveStringParameter,
    stringArray: ethResolveStringArrayParameter,
    bool: ethResolveBooleanParameter,
    boolArray: ethResolveBooleanArrayParameter
}

export function resolver(params: EthParameter[]) {
    return {
        string: (path: string) => ethResolveStringParameter(params, path),
        stringArray: (path: string) => ethResolveStringArrayParameter(params, path),
        bool: (path: string) => ethResolveBooleanParameter(params, path),
        boolArray: (path: string) => ethResolveBooleanArrayParameter(params, path),
    }
}
