import { DataStringFunctions, isDataStringFunctions, } from "../DumbappValueFunction";
import { getLiteralSourceParameters } from "../source-util";
export class JsonSourceProvider {
    constructor() {
        this.name = "json";
    }
    getValue(provider, source) {
        let data = new DataStringFunctions();
        for (let it of source.json) {
            let literal = getLiteralSourceParameters(it);
            if (literal) {
                data[it.name] = {
                    value() {
                        return literal.value;
                    },
                };
            }
            else {
                data[it.name] = {
                    async value() {
                        let res = await provider.getSourceValue(it.value, it);
                        let resolved = await res.value();
                        if (isDataStringFunctions(resolved)) {
                            throw new Error("Json Source element can't resolve to more JSON: " + resolved);
                        }
                        return resolved;
                    },
                };
            }
        }
        return {
            value() {
                return data;
            },
        };
    }
}
