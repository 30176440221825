import { DumbappResult } from "./DumbappResult";
import { DumbappSubmission } from "../submission";
import { DumbappSubmissionStep } from "../submission";
import { addExtras } from "../extras";
import { ExecutorParameters } from "./ExecutorParameters";
import { WalletProvider } from "../wallets";

export * from "./DumbappResult";
export * from "./EthExecutor";
export * from "./ExecutorBase";
export * from "./DumbappGasEstimate";
export * from "./ExecutorParameters";

export type DumbappUpdateResult = DumbappResult & {
    completed?: boolean;
    failed?: boolean;
};

/**
 * Returns a new DumbappSubmission with updates.
 *
 * Return value also has flags for if the submission was just completed or had an error.
 *
 * If nothing has changed, returns null.
 */
export async function update(
    provider: WalletProvider,
    submission: DumbappSubmission,
    parameters: ExecutorParameters
): Promise<DumbappUpdateResult | null> {
    if (!submission.data.pending()) {
        // Not pending, no update needed
        return null;
    }

    try {
        let wallet = await provider.get(submission.type, parameters);
        let res: DumbappUpdateResult = await wallet.updateStatus(submission);

        if (res.success === true) {
            if (!res.data) {
                // Return null to indicate no changes
                return null;
            }

            if (!submission.data.completed() && res.data.completed()) {
                res.completed = true;
                let extras = await addExtras(
                    parameters,
                    new DumbappSubmission(submission.dumbapp, res.data)
                );
                res.data.extras = Object.assign(res.data.extras || {}, extras);
            }
            if (!submission.data.error() && res.data.error()) {
                res.failed = true;
            }
        }
        return res;
    } catch (err) {
        return {
            success: false,
            code: "unknown",
            message: err.message,
            errorData: err,
        };
    }
}

export function stepStatus(step: DumbappSubmissionStep) {
    if (step.status === "confirm") {
        return {
            text: "Waiting For MetaMask",
            class: "waiting",
            icon: "pending",
        };
    }
    if (step.pendingConfirmation() || step.status === "new") {
        return {
            text: "Waiting",
            class: "waiting",
            icon: "pending",
        };
    }
    if (step.status === "unknown") {
        return {
            text: "Unknown",
            class: "unknown",
            icon: "warning",
        };
    }
    if (step.pending()) {
        return {
            text: "Pending...",
            class: "pending",
            progress: true,
        };
    }
    if (step.status === "error") {
        return {
            text: "Failed",
            class: "failed",
            icon: "error",
        };
    }
    if (step.completed()) {
        return {
            text: "Success",
            class: "confirmed",
            icon: "check_circle",
        };
    }
    return {};
}
