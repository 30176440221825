import ApiGroup from "./ApiGroup";

export interface ApiMinerBlock {
    baseFeePerGas: string;
    difficulty: string;
    extraData: string;
    gasLimit: number;
    gasUsed: number;
    hash: string;
    logsBloom: string;
    miner: string;
    mixHash: string;
    nonce: string;
    number: number;
    parentHash: string;
    receiptsRoot: string;
    sha3Uncles: string;
    size: number;
    stateRoot: string;
    timestamp: number;
    totalDifficulty: string;
    transactions: string[];
    transactionsRoot: string;
    uncles: string[];
}

class Network extends ApiGroup {
    /**
     * Get the latest block's data for a network.
     *
     * @param {string | number} network
     */
    latestBlock(network: string | number) {
        return this.request<ApiMinerBlock>({
            url: `/contracts/network/${network}/block/latest`,
        }).then((response) => response.data.data);
    }

    /**
     * Get data for a block.
     *
     * @param {string | number} network
     * @param {number} number
     */
    getBlock(network: string | number, number: number) {
        return this.request<ApiMinerBlock>({
            url: `/contracts/network/${network}/block/${number}`,
        }).then((response) => response.data.data);
    }
}

export default Network;
