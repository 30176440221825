import {AxiosInstance, AxiosRequestConfig} from "axios";


class ApiGroup {
    constructor(public client: AxiosInstance) {
    }

    request<T>(config: AxiosRequestConfig) {
        return this.client.request<{data: T}>(config)
    }
}

export default ApiGroup;
