import { addressEqual, chainEqual, getChain, toChainId } from "@blockwell/chains";
import { Cache } from "@blockwell/cache";
import ApiMiner from "./client/ApiMiner";
import { Mutex } from "async-mutex";
import { AddressChainQuery, ContractData, ContractQuery } from "@blockwell/apiminer";
import { WalletData } from "./client/Contracts";

export function isWallet(data: ContractData | WalletData): data is WalletData {
    return data && "wallet" in data && data.wallet;
}

export class CachedContractsApi {
    private mutex = new Mutex();
    private fastCache: Record<string, ContractData | WalletData> = {};

    constructor(private client: ApiMiner, private store: Cache) {}

    async getAll(queries: ContractQuery[]): Promise<(ContractData | WalletData)[]> {
        if (queries.length === 0) {
            return [];
        }
        let release = await this.mutex.acquire();
        try {
            let { instances, request } = await this.processQueries(queries);

            if (request.length > 0) {
                instances = instances.concat(await this.requestContracts(request));
            }

            let contracts: ContractData[] = [];

            for (let q of queries) {
                let query: AddressChainQuery;
                if (typeof q === "string") {
                    let split = q.split(/[.\/]/g);
                    if (split.length === 2) {
                        query = {
                            address: split[1],
                            chainId: toChainId(split[0]),
                        };
                    } else {
                        contracts.push(instances.find((it) => it.id === q));
                        continue;
                    }
                } else {
                    query = q;
                }
                contracts.push(
                    instances.find(
                        (it) =>
                            addressEqual(it.address, query.address) &&
                            chainEqual(it, query.chainId)
                    )
                );
            }

            return contracts;
        } finally {
            release();
        }
    }

    async getOne(query: ContractQuery) {
        if (typeof query === "string") {
            let result = this.fastCache[query];
            if (result) {
                return result;
            }
        }

        let [parsed] = await this.parseQueries([query]);

        if (typeof parsed === "string") {
            let fast = this.fastCache[parsed];
            if (fast) {
                return fast;
            }

            let contract = (await this.store.getItem<ContractData>(parsed))?.data;
            if (contract) {
                this.fastCache[contract.id] = contract;
                if (typeof query === "string") {
                    this.fastCache[query] = contract;
                }
                return contract;
            }
        }

        let result = await this.getAll([query]).then((it) => it[0]);

        if (result) {
            this.fastCache[result.id] = result;
            if (typeof query === "string") {
                this.fastCache[query] = result;
            }
        }

        return result;
    }

    async getContract(query: ContractQuery) {
        let result = await this.getOne(query);
        if (!result) {
            return null;
        }
        if (isWallet(result)) {
            throw new Error("Result from contract query was a wallet: " + query);
        }
        return result;
    }

    async getContractAbi(query: ContractQuery) {
        let contract = await this.getContract(query);
        if (contract?.abi) {
            return contract.abi;
        }
        return null;
    }

    async findByAddress(address: string): Promise<(ContractData | WalletData)[]> {
        let release = await this.mutex.acquire();
        try {
            let cached = await this.store.getItem<{ids: string[], wallet?: true}>(address.toLowerCase(), false);

            // Only cache address queries for 30 seconds, since new contracts can be
            // deployed which invalidates the result - unless it's a wallet, in which case it can't be a contract.
            if (!cached || (cached.t.getTime() < Date.now() - 30000 && !cached.data.wallet)) {
                let found = await this.client.contracts.find(address);
                let wallet = false;
                for (let it of found) {
                    await this.store.setItem(it.id, it);
                    if ("network" in it) {
                        let key = getChain(it.network).chainId + "/" + it.address.toLowerCase();
                        await this.store.setItem(key, it.id);
                    }
                    if (isWallet(it)) {
                        wallet = true;
                    }
                }

                await this.store.setItem(
                    address.toLowerCase(),
                    {ids: found.map((it) => it.id), wallet}
                );
                return found;
            }

            return this.getAll(cached.data.ids);
        } finally {
            release();
        }
    }

    private async parseQueries(queries: ContractQuery[]) {
        let parsed: ContractQuery[] = [];

        for (let q of queries) {
            let id: string;
            if (typeof q === "string") {
                if (q.includes("/")) {
                    id = q.replace("/", ".");
                } else {
                    id = q;
                }
                id = id.toLowerCase();
                if (id.includes(".")) {
                    let cachedId = await this.store.getItem<string>(id);

                    if (cachedId) {
                        id = cachedId.data;
                    }
                }
            } else {
                let key = toChainId(q.chainId) + "." + q.address.toLowerCase();
                let cachedId = await this.store.getItem<string>(key);

                if (cachedId) {
                    id = cachedId.data;
                }
            }

            if (id) {
                parsed.push(id);
            } else {
                parsed.push(q);
            }
        }

        return parsed;
    }

    private async processQueries(queries: ContractQuery[]) {
        let instances: ContractData[] = [];
        let request: ContractQuery[] = [];
        let parsed = await this.parseQueries(queries);

        for (let q of parsed) {
            let contract: ContractData;
            if (typeof q === "string") {
                contract = (await this.store.getItem<ContractData>(q))?.data;
            }
            if (contract) {
                instances.push(contract);
            } else {
                request.push(q);
            }
        }

        return { instances, request };
    }

    private async requestContracts(request: ContractQuery[]) {
        let res = await this.client.contracts.query(request);

        let found = res.filter((it) => !!it);

        for (let it of found) {
            await this.store.setItem(it.id, it);
            let key = getChain(it.network).chainId + "." + it.address.toLowerCase();
            await this.store.setItem(key, it.id);
        }

        return found;
    }
}
