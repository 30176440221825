import { Listener, TransactionRequest, WebSocketProvider } from "@ethersproject/providers";
import WS from "ws";
import { delay } from "@blockwell/util";
import { Event } from "@ethersproject/providers/lib/base-provider";
import { Deferrable } from "@ethersproject/properties";
import { BigNumber } from "@ethersproject/bignumber";
import { hexValue } from "@ethersproject/bytes";
import { RpcProvider } from "./RpcProvider";

const WEBSOCKET_PING_INTERVAL = 120000;
const WEBSOCKET_PONG_TIMEOUT = 10000;
const WEBSOCKET_RECONNECT_DELAY = 100;

/**
 * The relevant bits from the DOM WebSocket interface.
 */
type WebSocket = {
    send(data: string | ArrayBufferLike | Blob | ArrayBufferView): void;
    addEventListener(type: string, listener: (arg: any) => void): void;
    close(code?: number, reason?: string): void;
}

const WebSocketProviderClass = (): new () => WebSocketProvider => class {} as never;

type MultiSub = {
    tag: string;
    address: string[];
    topics?: string[];
    listener: Listener;
    params?: {address: string[]; topics?: string[]};
}

type MultiSubFunc = {
    tag: string;
    listener: Listener;
    params?: {address: string[]; topics?: string[]};
}

export class ReconnectingWebSocketProvider extends WebSocketProviderClass() {
    private provider?: WebSocketProvider;
    private events: WebSocketProvider["_events"] = [];
    private requests: WebSocketProvider["_requests"] = {};
    private multiSubs: MultiSub[] = [];
    private multiSubFuncs: Record<string, MultiSubFunc> = {};

    private handler = {
        get(target: ReconnectingWebSocketProvider, prop: string, receiver: unknown) {
            if (prop === "multiLogs" || prop === "stopMultiLogs" || prop === "estimateGas") {
                return target[prop].bind(target);
            }

            const value = target.provider && Reflect.get(target.provider, prop, receiver);

            return value instanceof Function ? value.bind(target.provider) : value;
        },
    };

    constructor(private _url: string, private _chain: number, private _pingInterval = WEBSOCKET_PING_INTERVAL) {
        super();
        this.create();

        return new Proxy(this, this.handler);
    }

    async estimateGas(transaction: Deferrable<TransactionRequest>): Promise<BigNumber> {
        return RpcProvider.estimateGas(this.provider, await this.provider.estimateGas(transaction), transaction);
    }

    async multiLogs(address: string[], topics: string[] | null, listener: Listener) {
        let hasTopics = topics?.length > 0;

        let params: {address: string[]; topics?: string[]} = {
            address
        };
        if (hasTopics) {
            params.topics = topics;
        }

        let sub: MultiSub = {
            tag: "multi:" + address.join(",") + (hasTopics ? (":" + topics.join(",")) : ""),
            address,
            topics: hasTopics ? topics : undefined,
            listener,
            params
        };

        this.multiSubs.push(sub);

        let func = this.multiSubFuncs[sub.tag]

        if (!func) {
            func = {
                tag: sub.tag,
                params: sub.params,
                listener: (...data) => {
                    for (let it of this.multiSubs) {
                        if (it.tag === func.tag) {
                            it.listener(...data);
                        }
                    }
                }
            }
            this.multiSubFuncs[sub.tag] = func;

            await this.provider._subscribe(func.tag, ["logs", func.params], func.listener);
        }
    }

    stopMultiLogs(listener: Listener) {
        let index = this.multiSubs.findIndex(it => it.listener === listener);
        if (index > -1) {
            let sub = this.multiSubs[index];

            this.multiSubs.splice(index, 1);

            if (!this.multiSubs.find(it => it.tag === sub.tag)) {
                let func = this.multiSubFuncs[sub.tag];
                delete this.multiSubFuncs[sub.tag];
                const subId = this.provider._subIds[sub.tag];
                if (subId) {
                    delete this.provider._subIds[sub.tag];
                    return subId.then((subId) => {
                        if (!this.provider._subs[subId]) {
                            return;
                        }
                        delete this.provider._subs[subId];
                        return this.provider.send("eth_unsubscribe", [subId]);
                    });
                }
            }
        }
    }

    private create() {
        if (this.provider) {
            this.events = [...this.events, ...this.provider._events];
            this.requests = { ...this.requests, ...this.provider._requests };
        }

        let provider = new WebSocketProvider(this._url, this._chain);
        let pingInterval: NodeJS.Timer | undefined;
        let pongTimeout: NodeJS.Timeout | undefined;

        let ws: WebSocket | WS = provider._websocket;

        const openListener = () => {
            pingInterval = setInterval(() => {
                Promise.race([
                    provider.getBlockNumber(),
                    delay(WEBSOCKET_PONG_TIMEOUT).then(() => false),
                ]).then((it) => {
                    if (it === false) {
                        ws.close();
                    } else {
                        provider.emit("debug", {
                            action: "keepalive",
                            provider,
                        });
                    }
                });
            }, this._pingInterval);

            let event: Event;
            while ((event = this.events.pop())) {
                provider._events.push(event);
                provider._startEvent(event);
            }

            for (const key in this.requests) {
                provider._requests[key] = this.requests[key];
                ws.send(this.requests[key].payload);
                delete this.requests[key];
            }

            for (let sub of Object.values(this.multiSubFuncs)) {
                provider._subscribe(sub.tag, ["logs", sub.params], sub.listener);
            }

            provider.emit("debug", {
                action: "open",
                provider,
            });
        };

        if ("on" in ws) {
            ws.addEventListener("open", openListener);

            ws.on("close", (code: number) => {
                provider._wsReady = false;

                if (pingInterval) clearInterval(pingInterval);
                if (pongTimeout) clearTimeout(pongTimeout);

                if (code !== 1000) {
                    setTimeout(() => this.create(), WEBSOCKET_RECONNECT_DELAY);

                    provider.emit("debug", {
                        action: "reconnect",
                        provider,
                        code
                    });
                } else {
                    provider.emit("debug", {
                        action: "close",
                        provider,
                        code
                    });
                }
            });

            ws.on("error", err => {
                provider.emit("debug", {
                    action: "error",
                    error: err
                });
                setTimeout(() => this.create(), WEBSOCKET_RECONNECT_DELAY);
            });
        } else {
            ws.addEventListener("open", openListener);

            ws.addEventListener("close", (event) => {
                provider._wsReady = false;

                if (pingInterval) clearInterval(pingInterval);
                if (pongTimeout) clearTimeout(pongTimeout);

                if (event.code !== 1000) {
                    setTimeout(() => this.create(), WEBSOCKET_RECONNECT_DELAY);
                    provider.emit("debug", {
                        action: "reconnect",
                        provider,
                        code: event.code
                    });
                } else {
                    provider.emit("debug", {
                        action: "close",
                        provider,
                        code: event.code
                    });
                }
            });
        }

        this.provider = provider;
    }
}
