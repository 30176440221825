import { getAddress } from "@ethersproject/address";
import { ChainClient } from "./ChainClient";
import { standardProviderFactory } from "./ProviderFactory";
import { Cache, MemoryCache } from "@blockwell/cache";
import { Metrics } from "./Metrics";

export { getAddress };

export const ChainMetrics = new Metrics();
export const Chain = new ChainClient(standardProviderFactory, new MemoryCache(60 * 60 * 1000), ChainMetrics);

//@ts-ignore
if (typeof window !== "undefined") {
    import("@blockwell/cache/lib/LocalForageCache").then(({ LocalForageCache }) => {
        Chain.replaceCache(new LocalForageCache("chain", 60 * 60 * 24 * 30 * 1000));
    });
}
