import { DumbappExtraProcessor } from "./DumbappExtraProcessor";
import { ExecutorParameters } from "../executor";
import { DumbappSubmission } from "../submission";
import { ethResolveStringParameter } from "@blockwell/eth-types";
import { delay } from "@blockwell/util";
import { findEvents, getTokenData } from "./util";
import { bwTypeMap } from "@blockwell/apiminer-client";
import { DeployedExtra } from "../schema";

export class DeployedExtraProcessor implements DumbappExtraProcessor {
    key = "deployed";

    async process(parameters: ExecutorParameters, submission: DumbappSubmission) {
        let deployed: DeployedExtra[] = [];
        for (let { event, step } of findEvents(submission, /Deployed/)) {
            let address = ethResolveStringParameter(event.params, "contractAddress");
            if (address) {
                try {
                    // Try 3 times in case there's delay in propagation
                    for (let i = 0; i < 3; i++) {
                        let contract = await getTokenData(
                            parameters.contracts,
                            step.network,
                            address
                        );

                        if (contract?.bwtype) {
                            let type = bwTypeMap[contract.bwtype];
                            if (type) {
                                let dep: DeployedExtra = {
                                    ...contract,
                                };
                                if (type.happ) {
                                    dep.happ = `/${type.happ}?net=${dep.network}&contract=${dep.address}`;
                                }

                                deployed.push(dep);
                            }
                            break;
                        } else {
                            await delay(3000);
                        }
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        }
        return deployed;
    }
}
