export async function delay(milliseconds: number) {
    await new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve();
        }, milliseconds);
    });
}

export function isStrings(array: any): array is string[] {
    if (!Array.isArray(array)) {
        return false;
    }
    if (array?.length === 0) {
        return true;
    }
    return typeof array[0] === "string";
}

export function isBooleans(array: any): array is boolean[] {
    if (!Array.isArray(array)) {
        return false;
    }
    if (array?.length === 0) {
        return true;
    }
    return typeof array[0] === "boolean";
}

type NonFunctionPropertyNames<T> = {
    [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];
export type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;
