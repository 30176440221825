import Emittery from "emittery";
import { Dumbapp } from "../Dumbapp";
import { SubmissionValueProvider } from "./SubmissionValueProvider";
import { ArgumentValueProvider } from "../state";
import { DumbappController, ResolveParameters } from "../controller";
import { DumbappSubmission } from "./DumbappSubmission";
import { CachedContractsLoader } from "../controller/CachedContractsLoader";
import { CachedContractsApi } from "@blockwell/apiminer-client";
import { clone } from "remeda";

export class SubmissionController
    extends Emittery<{ change: ArgumentValueProvider; resolve: ResolveParameters }>
    implements DumbappController
{
    values: SubmissionValueProvider;
    dumbapp: Dumbapp = null;

    private contractsLoader = new CachedContractsLoader();
    private lastResolveResult: ResolveParameters;

    constructor(public submission: DumbappSubmission) {
        super();
        this.values = new SubmissionValueProvider(submission);
        this.dumbapp = submission?.dumbapp;
    }

    get lastResolve(): ResolveParameters {
        return this.lastResolveResult;
    }

    setApi(api: CachedContractsApi) {
        this.contractsLoader.setApi(api);
    }

    setSubmission(submission: DumbappSubmission) {
        if (submission) {
            this.dumbapp = submission.dumbapp;
            this.submission = submission;
            this.values.setSubmission(submission);
            this.trigger();
        }
    }

    placeholderText() {
        return "None";
    }

    trigger() {
        this.emit("change", this.values);
        this.doResolve();
    }

    submissionStep(index: number) {
        return this.submission?.data?.steps?.[index];
    }

    protected doResolve() {
        this.contractsLoader
            .handler(
                {
                    dumbapp: this.dumbapp,
                },
                this.submission.data.args
            )
            .then((result) => {
                if (result.success === true) {
                    this.lastResolveResult = {
                        success: true,
                        arguments: this.submission.data.args.map((it, index) => {
                            return {
                                ...it,
                                contract: result.data[index],
                            };
                        }),
                        values: this.values,
                        state: null
                    };
                    this.emit("resolve", this.lastResolveResult);
                } else {
                    this.emit("resolve", result);
                }
            });
    }
}
