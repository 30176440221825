import { AxiosError } from "axios";
import { DumbappSubmissionData } from "../submission";
import {
    ExecutionError,
    ExecutionResult,
} from "../state";

export abstract class ExecutorBase {
    /**
     * Create a DumbappResult for a success.
     */
    successResult(data: DumbappSubmissionData): ExecutionResult<DumbappSubmissionData> {
        return {
            success: true,
            data,
        };
    }

    /**
     * Create a DumbappResult for a failure.
     */
    errorResult(
        code: string,
        message: string,
        data?: Record<string, any>,
        submission?: DumbappSubmissionData
    ): ExecutionError {
        return {
            success: false,
            code,
            message,
            errorData: data,
            submission,
        };
    }

    /**
     * Create a DumbappResult for an Error object.
     */
    resultForError(err: AxiosError<any>): ExecutionError {
        let code = err.response?.data?.error?.code || err.code || "unknown_error";
        let message = err.response?.data?.error?.message || err.message;
        return {
            success: false,
            code,
            message,
            errorData: err,
        };
    }
}
