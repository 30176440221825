import BigNumber from "bignumber.js";
import * as chrono from "chrono-node";
import dayjs from "dayjs";
import { Typing } from "@blockwell/eth-types";
import { ArgumentValueProvider } from "./ArgumentValueProvider";
import {
    DumbappArgument,
    DumbappJsonSourceParameters,
    DumbappSource,
    DumbappStep,
    DumbappValue,
} from "../schema";
import {
    DataStringFunctions,
    DataStringValues,
    DumbappValueFunction,
    isDataStringFunctions,
} from "../DumbappValueFunction";
import { isDumbappSource, isDumbappValue, solidityTypeToArgumentType } from "../util";

export async function resolveValueFunction(val: DumbappValueFunction) {
    let valueResult = val.value();
    let awaited: DumbappValue | DataStringFunctions;

    if (valueResult instanceof Promise) {
        awaited = await valueResult;
    } else {
        awaited = valueResult;
    }
    if (isDataStringFunctions(awaited)) {
        let values: DataStringValues = {};
        for (let [key, it] of Object.entries(awaited)) {
            values[key] = (await resolveValueFunction(it)) as DumbappValue;
        }
        return values;
    }
    return awaited;
}

export async function resolveSourcable(
    provider: ArgumentValueProvider,
    value: (DumbappValue | DumbappSource)[],
    step?: DumbappStep,
    typing?: Typing,
    decimals?: DumbappSource
) {
    let val = value.find((it) => it !== undefined && it !== null);

    if (isDumbappSource(val)) {
        if (!val) {
            return null;
        }
        let result = await provider.getSourceValue(
            val,
            {
                typing,
                type: solidityTypeToArgumentType(typing.name),
                decimals
            },
            step
        );
        if (result) {
            return await resolveValueFunction(result);
        } else {
            return null;
        }
    } else {
        return val;
    }
}

export async function resolveSourcableValue(
    provider: ArgumentValueProvider,
    value: (DumbappValue | DumbappSource)[],
    step?: DumbappStep,
    typing?: Typing,
    decimals?: DumbappSource
) {
    let res = await resolveSourcable(provider, value, step, typing, decimals);
    if (res === null) {
        return null;
    }

    if (isDumbappValue(res)) {
        return res;
    }

    if (res !== undefined) {
        console.warn("resolveSourcableValue did not resolve to DumbappValue", res);
    }
    return null;
}

async function doResolveValue(
    provider: ArgumentValueProvider,
    typing: Typing,
    source?: DumbappSource,
    value?: DumbappValue,
    step?: DumbappStep
): Promise<DumbappValue | DataStringValues> {
    if (source) {
        let result = await provider.getSourceValue(
            source,
            {
                typing,
                type: solidityTypeToArgumentType(typing.name),
            },
            step
        );
        if (result) {
            return await resolveValueFunction(result);
        } else {
            return null;
        }
    } else if (value) {
        return value;
    }
    return null;
}

async function resolveDumbappValue(
    provider: ArgumentValueProvider,
    typing: Typing,
    source: DumbappSource,
    value?: DumbappValue,
    step?: DumbappStep
): Promise<DumbappValue> {
    let res = await doResolveValue(provider, typing, source, value, step);

    if (res === null) {
        return null;
    }

    if (isDumbappValue(res)) {
        return res;
    }

    console.warn("resolveDumbappValue did not resolve to DumbappValue", res);
    return null;
}

export async function resolveStringValue(
    provider: ArgumentValueProvider,
    source?: DumbappSource,
    value?: DumbappValue,
    step?: DumbappStep,
    typing?: Typing
): Promise<string> {
    let res = await resolveDumbappValue(provider, typing || Typing.string, source, value, step);

    if (Array.isArray(res)) {
        console.warn("resolveStringValue resolved into an array", res);
        return null;
    }

    return res?.toString();
}

export async function resolveStringSourcable(
    provider: ArgumentValueProvider,
    value: (DumbappValue | DumbappSource)[],
    step?: DumbappStep,
    typing?: Typing,
    decimals?: DumbappSource
) {
    let res = await resolveSourcableValue(provider, value, step, typing || Typing.string, decimals);

    if (Array.isArray(res)) {
        console.warn("resolveStringValue resolved into an array", res);
        return null;
    }

    return res?.toString();
}

export function resolveAddressSourcable(
    provider: ArgumentValueProvider,
    value: (DumbappValue | DumbappSource)[],
    step?: DumbappStep
) {
    return resolveStringSourcable(provider, value, step, Typing.address);
}

export async function resolveBigNumberishValue(
    provider: ArgumentValueProvider,
    source: DumbappSource,
    value?: DumbappValue,
    step?: DumbappStep
): Promise<string> {
    let res = await resolveStringValue(provider, source, value, step, Typing.any);

    if (res === null || res === undefined || res === "") {
        return null;
    }
    let big = new BigNumber(res.toString());

    if (!big.isNaN()) {
        return big.toString(10);
    }

    console.warn("resolveBigNumberishValue did not resolve to BigNumberish", res);
    return null;
}

export async function resolveBigNumberishSourcable(
    provider: ArgumentValueProvider,
    value: (DumbappValue | DumbappSource)[],
    step?: DumbappStep,
    typing?: Typing,
    decimals?: DumbappSource
) {
    let res = await resolveStringSourcable(provider, value, step, typing || Typing.any, decimals);

    if (res === null || res === undefined || res === "") {
        return null;
    }
    let big = new BigNumber(res.toString());

    if (!big.isNaN()) {
        return big.toString(10);
    }

    console.warn("resolveBigNumberishSourcable did not resolve to BigNumberish", res);
    return null;
}

export async function resolveBooleanSourcable(
    provider: ArgumentValueProvider,
    value: (DumbappValue | DumbappSource)[],
    step?: DumbappStep) {
    let res = await resolveSourcableValue(provider, value, step, Typing.bool);

    if (Array.isArray(res)) {
        return null;
    }

    if (typeof res === "boolean") {
        return res;
    }

    if (typeof res === "string") {
        if (res === "true") {
            return true;
        }
        if (res === "false") {
            return false;
        }
    }
    return null;
}

export async function convertToValue(
    provider: ArgumentValueProvider,
    input: DumbappValue | DataStringFunctions,
    argument: DumbappArgument,
    step: DumbappStep
): Promise<DumbappValue> {
    let type = argument.type;

    if (type === "tuple") {
        let array = Array.isArray(input) ? input : [input];
        let converted = [];
        let i = 0;
        for (let it of array) {
            let typing = Typing.from(argument.typing.components[i]);
            converted.push(
                await convertToValue(
                    provider,
                    it,
                    {
                        type: solidityTypeToArgumentType(typing.name),
                        typing,
                    },
                    step
                )
            );
            ++i;
        }
        return converted;
    }

    if (Array.isArray(input)) {
        let converted = [];
        for (let it of input) {
            converted.push(await convertToValue(provider, it, argument, step));
        }
        return converted;
    }

    if (input instanceof DataStringFunctions) {
        if (argument.value?.type === "json") {
            let parameters: DumbappJsonSourceParameters = argument.value.parameters;
            let data: Record<string, any> = {};
            for (let [name, val] of Object.entries(input)) {
                let arg = parameters.json.find((it) => it.name === name);
                data[name] = await convertToValue(provider, await val.value(), arg, step);
            }

            return JSON.stringify(data);
        } else {
            throw new Error("DataStringValue received but source was not type json");
        }
    }

    if (type === "string" || type === "markdown" || type === "bytes") {
        if (input === null || input === undefined) {
            return "";
        }
        if (typeof input === "string") {
            return input;
        }
        return input.toString();
    }

    if (argument.value?.type === "json") {
        console.warn(
            "convertToValue: argument source was type json, but value wasn't a DataStringValue"
        );
        return JSON.stringify(input);
    }

    if (type === "bool") {
        if (typeof input === "string") {
            switch (input.toLowerCase()) {
                case "0":
                case "false":
                case "no":
                    return false;
                default:
                    return true;
            }
        }
        if (typeof input === "number") {
            return input > 0;
        }
        return input;
    }
    if (typeof input === "boolean") {
        return input;
    }

    if (type === "time") {
        if (typeof input !== "string" && typeof input !== "number") {
            throw new Error("Failed to parse time: " + input);
        }
        let parsed = parseTime(input);
        if (parsed === false) {
            throw new Error("Failed to parse time: " + input);
        } else if (argument.typeOptions?.timestamp) {
            parsed += Math.floor(Date.now() / 1000);
        }
        return parsed.toString();
    }

    if (
        type === "address" ||
        type === "payment-proposal" ||
        type === "proposal" ||
        type === "suggestion"
    ) {
        return input;
    }

    if (input === null || input === undefined || input === "") {
        return null;
    }

    let decimals = await resolveBigNumberishSourcable(provider, [argument.decimals], step);
    if (decimals && (typeof input === "string" || typeof input === "number")) {
        return new BigNumber(input).times(`1e${decimals}`).toFixed();
    }

    return input;
}

export async function convertFromValue(
    provider: ArgumentValueProvider,
    value: DumbappValue,
    argument: DumbappArgument,
    step: DumbappStep
): Promise<DumbappValue | DataStringFunctions> {
    if (Array.isArray(value)) {
        let converted: DumbappValue[] = [];
        for (let it of value) {
            let val = await convertFromValue(provider, it, argument, step);
            if (val instanceof DataStringFunctions) {
                throw new Error(
                    "Got DataStringValue in an array convertFromValue which should not happen"
                );
            }
            converted.push(val);
        }
        return converted;
    }

    if (typeof value === "boolean") {
        return value;
    }

    if (argument.value?.type === "json") {
        if (typeof value === "string") {
            try {
                let input: Record<string, DumbappValue> = JSON.parse(value);
                let data = new DataStringFunctions();
                let parameters: DumbappJsonSourceParameters = argument.value.parameters;
                for (let [name, val] of Object.entries(input)) {
                    let arg = parameters.json.find((it) => it.name === name);
                    let conv = await convertFromValue(provider, val, arg, step);
                    if (conv instanceof DataStringFunctions) {
                        console.warn("Got DataStringValues inside nested JSON type: " + val);
                    } else {
                        data[name] = {
                            value() {
                                return conv;
                            },
                        };
                    }
                }
                return data;
            } catch (err) {
                console.error("Failed to parse JSON for json-type argument");
            }
        } else {
            console.error("Unexpected type for JSON type argument: " + value);
            return new DataStringFunctions();
        }
    }
    let decimals = await resolveBigNumberishSourcable(provider, [argument.decimals], step);

    if (decimals && (typeof value === "string" || typeof value === "number")) {
        return new BigNumber(value).div(`1e${decimals}`).toString(10);
    }
    return value;
}

export function parseTime(input: string | number, reference = dayjs()) {
    if (!input) {
        return 0;
    }

    if (typeof input === "number") {
        return input;
    }
    if (/^\d+$/.test(input)) {
        return parseInt(input);
    }
    const custom = chrono.casual.clone();
    custom.refiners.push({
        refine: (context, results) => {
            for (let result of results) {
                if (!result.start.isCertain("hour")) {
                    result.start.assign("hour", reference.hour());
                }
                if (!result.start.isCertain("minute")) {
                    result.start.assign("minute", reference.minute());
                }
                if (!result.start.isCertain("second")) {
                    result.start.assign("second", reference.second());
                }
            }
            return results;
        },
    });

    let parsed = custom.parse(input, reference.toDate(), {
        forwardDate: true,
    });

    if (!parsed?.[0]?.start) {
        return false;
    }

    let start = parsed[0].start;

    return Math.ceil((start.date().valueOf() - reference.valueOf()) / 1000);
}
