import { delay } from "@blockwell/util";
import BigNumber from "bignumber.js";
import Emittery from "emittery";
import { Mutex } from "async-mutex";
import { addressEqual, chainEqual, EthNetwork, getChain } from "@blockwell/chains";
import { MetamaskProvider } from "./metamask";
import { ExecutionHandler, PreExecuteHandler, PrepareWalletHandler } from "../../state";
import { Dumbapp } from "../../Dumbapp";
import { WalletConnectionEvent, WalletState } from "../Wallet";
import { WindowLike } from "../window";
import { WalletContext } from "../WalletContext";

export class MetamaskContext implements WalletContext {
    type = "metamask";
    state: WalletState;
    chainId: number | null = null;
    account: string | null = null;

    public readonly events: Emittery<{ connection: WalletConnectionEvent }> = new Emittery();

    private listenersAdded = false;
    private connecting = false;
    private mutex = new Mutex();

    constructor(public readonly ethereum?: MetamaskProvider) {
        if (!ethereum) {
            this.state = "not-installed";
        } else {
            this.state = "ready";
        }
    }

    public readonly prepare: PrepareWalletHandler = async (state) => {
        if (!state.simulation && (!this.ethereum || !this.account)) {
            return {
                success: false,
                code: "no_wallet",
                message: "MetaMask not installed or connected.",
            };
        }

        let id: string;
        if (state.simulation) {
            id = "";
        } else {
            id = Dumbapp.uuid(state.dumbapp.shortcode, state.created, this.account);
        }

        return {
            success: true,
            data: {
                ...state,
                id,
                wallet: {
                    type: "metamask",
                    account: this.account,
                },
            },
        };
    };

    public readonly preExecute: PreExecuteHandler = async (state, args) => {
        let chainId = args[0].chainId;
        if (!state.simulation && !chainEqual(chainId, this.chainId)) {
            let chain = getChain(chainId);
            try {
                let changedChain = await this.requestNetwork(chain);

                if (!chainEqual(changedChain, chainId)) {
                    return {
                        success: false,
                        code: "network_change",
                        message: `MetaMask is connected to the wrong network, change to ${chain.networkName} and try again.`,
                        errorData: {
                            chainId: args[0].chainId,
                        },
                    };
                }
            } catch (err) {
                console.error(err);
                return {
                    success: false,
                    code: "network_change",
                    message: `Failed to change networks in MetaMask, change to ${chain.networkName} and try again.`,
                };
            }
        }

        return {
            success: true,
            data: null,
        };
    };

    /**
     * Connect to MetaMask, sending a request to the user if necessary.
     */
    async connect() {
        if (!this.ethereum) {
            console.error("Attempted MetaMask connect without MetaMask");
            this.updateAccount(undefined);
            this.updateChain(undefined);
            this.newState("not-installed");
        }
        let release = await this.mutex.acquire();
        this.connecting = true;
        try {
            let accs = await this.ethereum.request({ method: "eth_accounts" });
            if (!accs || accs.length === 0) {
                this.newState("loading-accounts");
                let accounts: string[] = await this.ethereum.request({
                    method: "eth_requestAccounts",
                });
                this.updateAccount(accounts);
                if (accounts && accounts.length > 0) {
                    this.updateChain(await this.ethereum.request({ method: "eth_chainId" }));
                    this.newState("accounts-loaded");
                } else {
                    this.newState("no-accounts");
                }
            } else {
                this.newState("accounts-loaded");
            }
        } catch (err) {
            console.error(err);
            this.newState("accounts-rejected");
        } finally {
            this.connecting = false;
            release();
        }
        this.addListeners();
    }

    /**
     * Check if a MetaMask connection is possible without prompting the user.
     */
    async checkConnection() {
        if (!this.ethereum) {
            this.newState("not-installed");
            return false;
        }
        let release = await this.mutex.acquire();
        try {
            let accs = await this.ethereum.request({ method: "eth_accounts" });
            this.addListeners();
            if (accs?.length > 0) {
                let account = accs[0];
                if (!this.account || !addressEqual(account, this.account)) {
                    this.account = accs[0];
                    this.state = "accounts-loaded";
                    this.chainChanged(await this.ethereum.request({ method: "eth_chainId" }));
                } else {
                    this.newState("accounts-loaded");
                }
                return true;
            }
            this.newState("ready");
            return false;
        } finally {
            release();
        }
    }

    async requestNetwork(network: EthNetwork) {
        if (this.chainId === network.chainId) {
            return this.chainId;
        }
        let release = await this.mutex.acquire();
        try {
            if (this.chainId === network.chainId) {
                // A separate call already changed it while waiting for mutex
                return this.chainId;
            }

            let promise = new Promise<number>((resolve) => {
                const chainChange = (chain: string) => {
                    let chainId = new BigNumber(chain).toNumber();
                    this.ethereum.removeListener("chainChanged", chainChange);
                    resolve(chainId);
                };
                this.ethereum.on("chainChanged", chainChange);
            });

            // 42 and lower chain ID are default chains in MetaMask and require a different call
            if (network.chainId > 42) {
                await this.addCustomChain(network);
            } else {
                await this.switchDefaultChain(network);
            }

            // addCustomChain doesn't throw or return any useful information if the user
            // rejects it, so we need this work-around.
            return await Promise.any([
                delay(1000).then(() => {
                    return 0;
                }),
                promise,
            ]);
        } finally {
            release();
        }
    }

    addCustomChain(network: EthNetwork) {
        let currencyName = network.coinName || network.networkName + " ETH";
        let currencySymbol = network.symbol || "ETH";
        return this.ethereum
            .request({
                method: "wallet_addEthereumChain",
                params: [
                    {
                        chainId: network.hexChainId(),
                        chainName: network.networkName,
                        nativeCurrency: {
                            name: currencyName,
                            symbol: currencySymbol,
                            decimals: 18,
                        },
                        rpcUrls: [network.getNodeUrl()],
                        blockExplorerUrls: [network.explorer],
                        iconUrls: [],
                    },
                ],
            })
            .then((err) => {
                if (err) {
                    console.error("wallet_addEthereumChain error", err);
                    throw err;
                }
            });
    }

    switchDefaultChain(network: EthNetwork) {
        return this.ethereum
            .request({
                method: "wallet_switchEthereumChain",
                params: [
                    {
                        chainId: network.hexChainId(),
                    },
                ],
            })
            .then((err) => {
                if (err) {
                    console.error("wallet_switchEthereumChain error", err);
                    throw err;
                }
            });
    }

    protected newState(state?: WalletState) {
        if (state) {
            this.state = state;
        }
        // If we lose the account, we need to also clear the chain ID
        if (!this.account && this.chainId) {
            this.chainId = undefined;
        }
        let data = {
            type: "metamask",
            state: this.state,
            account: this.account,
            chainId: this.chainId,
        };
        this.events.emit("connection", data);
    }

    protected addListeners() {
        if (!this.listenersAdded) {
            this.listenersAdded = true;
            this.ethereum.on("accountsChanged", this.accountsChanged);
            this.ethereum.on("chainChanged", this.chainChanged);
        }
    }

    protected removeListeners() {
        this.ethereum.removeListener("accountsChanged", this.accountsChanged);
        this.ethereum.removeListener("chainChanged", this.chainChanged);
        this.listenersAdded = false;
    }

    protected accountsChanged = (accounts: string[]) => {
        if (this.connecting) {
            return;
        }
        this.updateAccount(accounts);
        if (!this.account) {
            this.newState("ready");
        } else {
            this.newState();
        }
    };

    /**
     * Update account without triggering a window event.
     */
    protected updateAccount(accounts: string[]) {
        let previousAccount = this.account;
        if (accounts?.length > 0) {
            this.account = accounts[0];
        } else {
            this.account = null;
        }

        return previousAccount !== this.account;
    }

    protected chainChanged = (chainId: string) => {
        this.updateChain(chainId);
        this.newState();
    };

    /**
     * Update chainId without triggering a window event.
     */
    protected updateChain(chainId: string) {
        let previousChainId = this.chainId;
        if (chainId === undefined) {
            this.chainId = undefined;
        } else {
            this.chainId = new BigNumber(chainId).toNumber();
        }

        return previousChainId !== this.chainId;
    }
}

export async function getMetamaskContext(window: WindowLike): Promise<MetamaskContext> {
    let context: MetamaskContext = window.dumbappNamespace.getContext("metamask");

    if (context) {
        return context;
    }

    let ethereum = await getEthereum();

    // Check again if another call has already created it
    context = window.dumbappNamespace.getContext("metamask");
    if (context) {
        return context;
    }

    context = new MetamaskContext(ethereum);
    window.dumbappNamespace.addContext(context);
    return context;
}

async function getEthereum(): Promise<MetamaskProvider> {
    let ethereum = await loadEthereum();

    if (ethereum) {
        ethereum.autoRefreshOnNetworkChange = false;
    }

    return ethereum;
}

async function getMetamaskAccount() {
    try {
        let ethereum = await getEthereum();

        if (ethereum) {
            let accounts = await ethereum.request({ method: "eth_accounts" });

            if (accounts.length > 0) {
                return accounts[0];
            }
        }
    } catch (err) {
        console.error(err);
    }

    return null;
}

function getEthereumWindow(): {
    ethereum?: MetamaskProvider;

    addEventListener(
        eventName: string,
        listener: (...args: any[]) => void,
        opts?: {
            once: boolean;
        }
    ): any;
} {
    // @ts-ignore
    return window;
}

async function loadEthereum(): Promise<MetamaskProvider> {
    let window = getEthereumWindow();
    if (window.ethereum) {
        return window.ethereum;
    }

    return Promise.any([
        delay(500).then(() => null),
        new Promise((resolve) => {
            window.addEventListener(
                "ethereum#initialized",
                (ethereum) => {
                    resolve(ethereum);
                },
                { once: true }
            );
        }),
    ]);
}
