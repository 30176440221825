import {
    DumbappArguments,
    ExecutionPrepareState,
    ExecutionResolution,
    ExecutionResultState,
    ExecutionState,
    ExecutionStepArguments,
} from "./ExecutionState";
import { DumbappInputValues } from "../schema";
import { DumbappSubmission } from "../submission";
import { Mutex } from "async-mutex";
import { ContractData } from "@blockwell/apiminer-client";
import {
    phases,
    ExecutionErrorResult,
    ExecutionPhases,
    ExecutionResult,
    ExecutionResultHandler,
    PhaseNames,
    ExecutionResolveResult,
} from "./ExecutionPhases";

interface Handlers<TypeMap, Key extends keyof TypeMap>
    extends Map<Key, { name: string; handle: TypeMap[Key] }[]> {
    get: <Prop extends keyof TypeMap>(
        key: Prop
    ) => { name: string; handle: TypeMap[Prop] }[] | undefined;
}

/**
 * Mapping type for storing handlers.
 */
type ExecutionHandlers = Handlers<ExecutionPhases, keyof ExecutionPhases>;

/**
 * Handles all steps for preparing and submitting a dumbapp.
 *
 * Execution is divided into multiple phases (see the phases constant for details),
 * with each phase contributing to the necessary data for submitting the dumbapp.
 *
 * All the data is collected into an ExecutionState object that's passed along as
 * context of the current execution.
 *
 * There are two modes for running the phases, regular and simulated:
 *
 * 1. In regular mode, any error from a phase handler will immediately halt execution
 *    and make the whole execution resolve in that error.
 * 2. In simulated mode, most errors are ignored. Instead, all possible data is collected
 *    and then provided as context. This data is then used to display a partial preview of
 *    the transaction.
 *
 * Some phases require that they have exactly one handler, and execution will halt
 * if they have either no handlers, or more than one. This is to ensure no double
 * execution can happen.
 */
export class ExecutionController {
    private handlers: ExecutionHandlers = new Map();
    private mutex = new Mutex();
    private requestId = 0;

    constructor() {}

    /**
     * Add a handler to an execution phase.
     *
     * When cleaning up, removeHandler should be called.
     *
     * @param source The source of the handler, either a Vue component or a string name.
     *               This is used to identify and remove the handlers later.
     * @param phase Phase to add the handler to.
     * @param handle The handling function.
     */
    addHandler<Ref extends keyof ExecutionPhases>(
        source: { $options: { name: string }; uid: string } | string,
        phase: Ref,
        handle: ExecutionPhases[Ref]
    ) {
        let list = this.handlers.get(phase);
        if (!list) {
            list = [];
            this.handlers.set(phase, list);
        }

        let name: string;
        if (typeof source === "string") {
            name = source;
        } else {
            name = source.$options.name + "-" + source.uid;
        }

        list.push({
            name,
            handle,
        });
    }

    /**
     * Convenience function for addHandler with the result phase.
     *
     * @param source See addHandler.
     * @param handle The handling function.
     */
    onResult(
        source: { $options: { name: string }; uid: string } | string,
        handle: ExecutionResultHandler
    ) {
        this.addHandler(source, "result", handle);
    }

    /**
     * Remove a handler from execution.
     *
     * @param source Vue component instance or unique name for the handler.
     * @param phase Phase to remove from, or if omitted removes from all phases.
     */
    removeHandler<Ref extends keyof ExecutionPhases>(
        source: { $options: { name: string }; uid: string } | string,
        phase?: Ref
    ) {
        let name: string;
        if (typeof source === "string") {
            name = source;
        } else {
            name = source.$options.name + "-" + source.uid;
        }

        if (phase) {
            let list = this.handlers.get(phase);
            if (list) {
                this.handlers.set(
                    phase,
                    list.filter((it) => it.name !== name)
                );
            }
        } else {
            for (let [key, list] of this.handlers.entries()) {
                this.handlers.set(
                    key,
                    list.filter((it) => it.name !== name)
                );
            }
        }
    }

    /**
     * Execute the dumbapp with all the current handlers.
     */
    async execute(): Promise<ExecutionResult<ExecutionResultState>> {
        let release = await this.mutex.acquire();
        let id = this.requestId++;
        try {
            let result = await this.runPhasesAndExecute(id);

            await this.result(result);

            return result;
        } finally {
            release();
        }
    }

    /**
     * Simulate dumbapp execution, but without actually submitting.
     *
     * This runs phases up to and including input, and omits resolve, contracts, preExecute, execute and result.
     */
    async simulate() {
        let id = this.requestId++;
        let res = await this.internalRunPhases(true, id);
        if (res.success === false) {
            return null;
        }
        return res.data;
    }

    /**
     * Resolve the dumbapp arguments based on the given state.
     *
     * The state is typically retrieved using the `simulate` function, but can be constructed
     * using any means.
     *
     * @param inState State for resolving arguments.
     */
    async resolve(inState: ExecutionState): Promise<ExecutionResult<ExecutionResolveResult>> {
        let state = inState;
        let id = this.requestId++;
        let argsRes = await this.runResolve(state, id);
        if (argsRes.success !== true) {
            return argsRes;
        }

        let preRes = await this.runPreExecute(id, state, argsRes.data);
        if (preRes.success === true) {
            state = this.applyMerge(id, state, preRes.data, "preExecute", "resolve");
        } else {
            return preRes;
        }

        return {
            success: true,
            data: {
                state,
                arguments: argsRes.data,
            },
        };
    }

    protected async runPhasesAndExecute(
        id: number
    ): Promise<ExecutionResult<ExecutionResultState>> {
        let res = await this.internalRunPhases(false, id);

        if (res.success === true) {
            let state = res.data;
            let argsRes = await this.runResolve(state, id);
            if (argsRes.success !== true) {
                return argsRes;
            }

            let preRes = await this.runPreExecute(id, state, argsRes.data);
            if (preRes.success === true) {
                state = this.applyMerge(id, state, preRes.data, "preExecute", "execute");
            } else {
                return preRes;
            }

            let execRes = await this.runExecute(state, argsRes.data);
            if (execRes.success === true) {
                return {
                    success: true,
                    data: {
                        ...res.data,
                        args: argsRes.data,
                        submission: execRes.data,
                    },
                };
            } else {
                return execRes;
            }
        }
        return res;
    }

    protected async internalRunPhases(
        simulation: boolean,
        id: number
    ): Promise<ExecutionResult<ExecutionState>> {
        let prepared = this.prepareRequest(simulation, id);
        this.debug(id, "Prepare request result", prepared);
        if (prepared.success === false) {
            return prepared;
        }

        let wallet = await this.prepareWallet(prepared.data);
        this.debug(id, "Prepare wallet result", wallet);
        if (wallet.success === false) {
            return wallet;
        }
        let state: ExecutionState = wallet.data;

        let res = await this.runPhase("preInput", state, id);
        if (res.success === true) {
            state = this.applyMerge(id, state, res.data, "preInput", "all");
        } else {
            return res;
        }

        let input = await this.runInput(state, id);
        if (input.success === true) {
            state.data = input.data;
        } else {
            return input;
        }

        res = await this.runPhase("postInput", state, id);
        if (res.success === true) {
            state = this.applyMerge(id, state, res.data, "postInput", "all");
        } else {
            return res;
        }

        return {
            success: true,
            data: state,
        };
    }

    dumpFlow() {
        return Object.keys(phases).map((phase: PhaseNames) => {
            return {
                phase: phase,
                handlers: this.handlers.get(phase)?.map((it) => it.name),
            };
        });
    }

    protected prepareRequest(
        simulation: boolean,
        id: number
    ): ExecutionResult<ExecutionPrepareState> {
        const handlers = this.handlers.get("prepareRequest");

        let merged: ExecutionPrepareState = null;
        try {
            for (let handler of handlers) {
                let result = handler.handle(simulation);
                if (result.success === true) {
                    merged = this.applyMerge(
                        id,
                        merged,
                        result.data,
                        "prepareRequest",
                        handler.name
                    );
                } else {
                    return {
                        ...result,
                        state: merged,
                    };
                }
            }
        } catch (err) {
            return {
                success: false,
                code: "error",
                message: "prepareRequest: " + err.message,
                state: merged,
            };
        }
        return { success: true, data: merged };
    }

    protected async prepareWallet(
        state: ExecutionPrepareState
    ): Promise<ExecutionResult<ExecutionState>> {
        const handlers = this.handlers.get("prepareWallet");

        if ((!handlers || handlers.length === 0) && state.simulation) {
            console.warn("No wallet handler in simulation");
            return {
                success: false,
                code: "no_wallet",
                state,
            };
        } else if (handlers?.length !== 1) {
            throw new Error(
                "prepareWallet must have exactly 1 handler, but found " + handlers?.length
            );
        }

        try {
            let result = await handlers[0].handle(state);
            if (result.success === true) {
                if (!state.simulation && !result.data.id) {
                    return {
                        success: false,
                        code: "no_id",
                        message: "ExecutionController prepareWallet did not generate an ID",
                        state,
                    };
                } else {
                    return result;
                }
            }
            return {
                ...result,
                state,
            };
        } catch (err) {
            return {
                success: false,
                code: "error",
                message: "prepareWallet: " + err.message,
                state,
            };
        }
    }

    protected async runInput(
        state: ExecutionState,
        id: number
    ): Promise<ExecutionResult<DumbappInputValues[]>> {
        const handlers = this.handlers.get("input");

        let layers = state.data;
        try {
            for (let handler of handlers) {
                let result = await handler.handle(state);
                this.debug(id, `Input layer ${handler.name}`, result);
                if (result.success === true) {
                    if (Array.isArray(result.data)) {
                        layers.push(
                            ...result.data.filter(
                                (it) => it?.data && Object.keys(it.data).length > 0
                            )
                        );
                    } else {
                        layers.push(result.data);
                    }
                } else {
                    return {
                        ...result,
                        state,
                    };
                }
            }
        } catch (err) {
            return {
                success: false,
                code: "error",
                message: "input: " + err.message,
                state,
            };
        }
        return { success: true, data: layers };
    }

    protected async runPhase<K extends "preInput" | "postInput", T extends ExecutionPhases[K]>(
        phase: K,
        state: ExecutionState,
        id: number
    ): Promise<ExecutionResult<Partial<ExecutionState>>> {
        const handlers = this.handlers.get(phase);

        return this.merge(phase, state, handlers, id);
    }

    protected async runResolve(
        state: ExecutionState,
        id: number
    ): Promise<ExecutionResult<DumbappArguments>> {
        const handlers = this.handlers.get("resolve");

        let resolutions: ExecutionResolution[] = [];

        if (handlers?.length > 0) {
            try {
                for (let handler of handlers) {
                    let result = await handler.handle(state);
                    if (result.success === true) {
                        resolutions = resolutions.concat(result.data);
                    } else {
                        return {
                            ...result,
                            state,
                        };
                    }
                }
            } catch (err) {
                return {
                    success: false,
                    code: "error",
                    message: "resolve: " + err.message,
                    state,
                    errorData: {
                        stack: err.stack,
                    },
                };
            }
        }

        this.debug(id, "resolutions", resolutions);
        let args: DumbappArguments = [];

        let i = 0;
        for (let step of state.dumbapp.steps) {
            let stepArgs: ExecutionStepArguments = {
                args: step.arguments.map(() => undefined),
                address: undefined,
                chainId: undefined,
            };

            for (let res of resolutions) {
                if (res.step === i) {
                    switch (res.type) {
                        case "argument":
                            stepArgs.args[res.number] = res.value;
                            break;
                        case "value":
                            stepArgs.value = res.value;
                            break;
                        case "address":
                            stepArgs.address = res.value;
                            break;
                        case "network":
                            stepArgs.chainId = res.value;
                            break;
                    }
                }
            }

            if (
                !stepArgs.address ||
                !stepArgs.chainId ||
                stepArgs.args.findIndex((it) => it === undefined || it === null) > -1
            ) {
                if (state.simulation) {
                    stepArgs.missing = true;
                } else {
                    return {
                        success: false,
                        code: "unresolved_arguments",
                        message: `Step ${i} had unresolved arguments`,
                        state,
                    };
                }
            }

            args.push(stepArgs);
            ++i;
        }

        this.debug(id, "merged resolutions into args", args);

        let contracts = await this.runContracts(state, args);

        if (contracts.success) {
            let res = [...contracts.data];
            for (let arg of args) {
                arg.contract = res.shift();
            }
        }

        this.debug(id, "merged contracts into args", args);

        return {
            success: true,
            data: args,
        };
    }

    protected async runContracts(
        state: ExecutionState,
        args: DumbappArguments
    ): Promise<ExecutionResult<ContractData[]>> {
        const handlers = this.handlers.get("loadContracts");

        let contractsList: ContractData[][] = [];
        let length = 0;

        if (handlers?.length > 0) {
            try {
                for (let handler of handlers) {
                    let result = await handler.handle(state, args);
                    if (result.success === true) {
                        contractsList.push(result.data);
                        if (result.data.length > length) {
                            length = result.data.length;
                        }
                    } else {
                        return {
                            ...result,
                            state,
                        };
                    }
                }
            } catch (err) {
                return {
                    success: false,
                    code: "error",
                    message: "resolve: " + err.message,
                    state,
                };
            }
        }

        let contracts: ContractData[] = [];
        for (let i = 0; i < length; i++) {
            let contract: ContractData = undefined;
            for (let list of contractsList) {
                if (list[i]) {
                    contract = list[i];
                }
            }
            contracts[i] = contract;
        }

        return {
            success: true,
            data: contracts,
        };
    }

    protected async runPreExecute(
        id: number,
        state: ExecutionState,
        args: DumbappArguments
    ): Promise<ExecutionResult<Partial<ExecutionState>>> {
        const handlers = this.handlers.get("preExecute");
        let merged: Partial<ExecutionState> = {};
        try {
            for (let handler of handlers) {
                let result = await handler.handle(state, args);
                if (result.success === true) {
                    merged = this.applyMerge(id, merged, result.data, "preExecute", handler.name);
                } else if (state.simulation) {
                    // If it failed, but it's a simulation, just mark it in the state rather than failing fast
                    merged = this.applyMerge(
                        id,
                        merged,
                        { approve: { type: "undetermined" } },
                        "preExecute",
                        handler.name
                    );
                } else {
                    return {
                        ...result,
                        state,
                    };
                }
            }
        } catch (err) {
            if (state.simulation) {
                // If it failed, but it's a simulation, just mark it in the state rather than failing fast
                merged = this.applyMerge(
                    id,
                    merged,
                    { approve: { type: "undetermined" } },
                    "preExecute",
                    "catch"
                );
            } else {
                return {
                    success: false,
                    code: "error",
                    message: "preExecute: " + err.message,
                    state,
                };
            }
        }

        if (!state.simulation && merged.approve) {
            return {
                success: false,
                code: "approval",
                message: "Approval is required before submission.",
                state: this.applyMerge(id, state, merged, "preExecute", "approval error"),
            };
        }

        return {
            success: true,
            data: merged,
        };
    }

    protected async runExecute(
        state: ExecutionState,
        args: DumbappArguments
    ): Promise<ExecutionResult<DumbappSubmission>> {
        const handlers = this.handlers.get("execute");

        if (handlers?.length !== 1) {
            throw new Error("execute must have exactly 1 handler, but found " + handlers?.length);
        }

        try {
            let result = await handlers[0].handle(state, args);
            if (result.success === true) {
                return {
                    success: true,
                    data: new DumbappSubmission(state.dumbapp, result.data),
                };
            }
            return {
                ...result,
                state,
            };
        } catch (err) {
            return {
                success: false,
                code: "error",
                message: "execute: " + err.message,
                state,
            };
        }
    }

    protected async merge<K extends "preInput" | "postInput", T, H extends ExecutionPhases[K]>(
        phase: K,
        source: ExecutionState,
        handlers: { name: string; handle: H }[],
        id: number
    ): Promise<ExecutionResult<Partial<ExecutionState>>> {
        let merged: Partial<ExecutionState> = {};
        if (handlers?.length > 0) {
            try {
                for (let handler of handlers) {
                    let result = await handler.handle(this.applyMerge(id, source, merged));
                    if (result.success === true) {
                        merged = this.applyMerge(id, merged, result.data, phase, handler.name);
                    } else {
                        return {
                            ...result,
                            state: merged,
                        };
                    }
                }
            } catch (err) {
                return {
                    success: false,
                    code: "error",
                    message: phase + ": " + err.message,
                    state: merged,
                };
            }
        }
        return { success: true, data: merged };
    }

    protected async result(result: ExecutionResult<ExecutionResultState>) {
        let handlers = this.handlers.get("result");

        if (handlers?.length > 0) {
            for (let handler of handlers) {
                try {
                    await handler.handle(result);
                } catch (err) {
                    console.error(err);
                }
            }
        }
    }

    protected applyMerge<
        Ret extends ExecutionPrepareState | ExecutionState | Partial<ExecutionState>
    >(id: number, x: Ret, y: Partial<Ret>, phase?: string, name?: string): Ret {
        if (phase) {
            this.debug(id, `merging in ${phase} from ${name}`, x, y);
        }

        let merge: Ret = Object.assign(
            {},
            ...[x || {}, y || {}].map((it) =>
                Object.fromEntries(Object.entries(it).filter(([key, value]) => value !== undefined))
            )
        ) as any;

        if (x?.data || y?.data) {
            merge.data = [...(x?.data || []), ...(y?.data || [])];
        }

        if (x?.extras || y?.extras) {
            merge.extras = {...(x?.extras || {}), ...(y?.extras || {})};
        }

        return merge;
    }

    protected debug(id: number, ...args: any[]) {
        //console.log(id, ...args);
    }
}
