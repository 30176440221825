import ApiGroup from './ApiGroup';

export interface ApiMinerUser {
    id: string;
    name?: string;
    groups?: string[];
    externalId: string;
    externalData: any;
}

class Users extends ApiGroup {

    /**
     * Creates a new API Miner user.
     */
    create(id?: string, name?: string) {
        let data: any = {auth: true, account: true};

        if (id) {
            data.externalId = id;
        }
        if (name) {
            data.name = name;
        }

        return this.request<ApiMinerUser>({
            method: 'post',
            url: '/users',
            data
        }).then(response => response.data);
    }

    /**
     * Get a specific Account by its ID.
     */
    get(id: string) {
        return this.request<ApiMinerUser>({
            url: `/accounts/${id}`
        }).then(response => response.data.data);
    }
}

export default Users;
