import { CachedContractsApi, ContractData } from "@blockwell/apiminer-client";
import { Dumbapp } from "./Dumbapp";
import {
    DumbappArgument, DumbappArgumentType, DumbappConfig,
    DumbappContractStep,
    DumbappDynamic,
    DumbappEtherStep, DumbappRequiresApproval, DumbappRequiresAsset, DumbappSource,
    DumbappStep,
    DumbappStepBase, DumbappStepsSourceParameters, DumbappValue
} from "./schema";
import { BaseFragment, TypeInfo, Typing } from "@blockwell/eth-types";
import { getFeatures, getFieldType } from "./util";
import { capitalCase } from "change-case";
import { createDynamicSource, createLiteralSource, createStepsSource } from "./source-util";
import { ChainReference, getChain } from "@blockwell/chains";
import { JsonFragment, JsonFragmentType } from "@ethersproject/abi";

export class DumbappBuilder {

    steps: StepBuilder[] = [];

    private _dumbapp: Partial<DumbappConfig> = {};
    private _dynamic: DynamicBuilder[] = [];
    private _requiresAsset: RequiresAssetBuilder;
    private _requiresApproval: RequiresApprovalBuilder;

    constructor(private contracts: CachedContractsApi) {
        this.steps = [];
    }

    /**
     * Build a dumbapp object that can be saved to the database.
     */
    build(): DumbappConfig {
        let dumbapp = Object.assign({}, this._dumbapp);
        let steps = this.steps.map(it => it.build());

        if (this._requiresAsset) {
            dumbapp.requiresAsset = this._requiresAsset.build();
        }

        if (this._requiresApproval) {
            dumbapp.requiresApproval = this._requiresApproval.build();
        }

        let dynamic: Record<string, DumbappDynamic> = {};

        for (let dynBuilder of this._dynamic) {
            let dyn = dynBuilder.build();
            if (dynamic[dyn.name]) {
                throw new Error(`Dynamic name conflict '${dyn.name}', can't create dumbapp.`);
            }
            dynamic[dyn.name] = dyn;
        }

        for (let step of this.steps) {
            for (let arg of step.arguments) {
                for (let dyn of arg.buildDynamic()) {
                    if (dynamic[dyn.name]) {
                        throw new Error(`Dynamic name conflict '${dyn.name}', can't create dumbapp.`);
                    }
                    dynamic[dyn.name] = dyn;
                }
            }
        }

        dumbapp.dynamic = Object.values(dynamic);

        return {
            ...dumbapp,
            steps,
            dynamic: Object.values(dynamic)
        };
    }

    /**
     * Add a new step to the dumbapp and returns its builder.
     *
     * @return {StepBuilder}
     */
    addStep(): StepBuilder {
        let builder = new StepBuilder(this.contracts);
        this.steps.push(builder);
        return builder;
    }

    /**
     * Add an asset requirement to the dumbapp and returns its builder.
     *
     * @return {RequiresAssetBuilder}
     */
    requireAsset(): RequiresAssetBuilder {
        this._requiresAsset = new RequiresAssetBuilder();
        return this._requiresAsset;
    }

    /**
     * Add an approval to the dumbapp.
     */
    requiresApproval(): RequiresApprovalBuilder {
        this._requiresApproval = new RequiresApprovalBuilder();
        return this._requiresApproval;
    }

    /**
     * Set the dumbapp title.
     */
    title(value: string): DumbappBuilder {
        this._dumbapp.title = value;
        return this;
    }

    /**
     * Set the dumbapp description.
     */
    description(value: string): DumbappBuilder {
        this._dumbapp.description = value;
        return this;
    }

    /**
     * Set the dumbapp's unique reference.
     *
     * Only one dumbapp with this reference can exist.
     */
    uniqueReference(value: string): DumbappBuilder {
        this._dumbapp.uniqueReference = value;
        return this;
    }

    /**
     * Add a dynamic field to the dumbapp.
     *
     * This dynamic field will not be tied to a specific step or argument.
     */
    dynamic(solType: string, name: string) {
        let typing = Typing.parse(solType).toTypeInfo();
        let type = getFieldType(name, typing);

        let dynBuilder = new DynamicBuilder(typing, type, name, capitalCase(name));
        this._dynamic.push(dynBuilder);

        return dynBuilder;
    }

    /**
     * Set the wallet strategy for the dumbapp.
     *
     * This specifies what types of wallets can be used, and which should be given priority.
     *
     * @param type
     * @param wallets
     * @return {DumbappBuilder}
     */
    walletStrategy(type: 'auto' | 'required' | 'priority', wallets?: string[]): DumbappBuilder {
        this._dumbapp.walletStrategy = {
            type
        };
        if (wallets) {
            this._dumbapp.walletStrategy.wallets = wallets;
        }
        return this;
    }
}

export class StepBuilder {
    contractData?: ContractData;
    arguments: ArgumentBuilder[] = [];
    private _step: Partial<DumbappStepBase & DumbappEtherStep & DumbappContractStep>;
    private func: BaseFragment;
    private _addressSource: SourceBuilder;
    private _networkSource: SourceBuilder;
    private _valueSource: SourceBuilder;

    constructor(private contracts: CachedContractsApi) {
        this._step = {};
    }

    build() {
        let step = Object.assign({}, this._step);

        if (this.contractData) {
            step.address = createLiteralSource(this.contractData.address);
            step.network = createLiteralSource(this.contractData.network);
            step.contractName = this.contractData.name;
        }

        if (this.func) {
            step.method = this.func.name;
            step.abi = this.func;
        }

        if (this._addressSource) {
            step.address = this._addressSource.build();
        }
        if (this._networkSource) {
            step.network = this._networkSource.build();
        }

        if (this.arguments.length > 0) {
            step.arguments = this.arguments.map(it => it.build());
        }

        if (this._valueSource) {
            step.value = this._valueSource.build();
        }

        return step as DumbappStep;
    }

    /**
     * Set the contract for the step.
     *
     * This method is asynchronous because the contract's details will be loaded
     * immediately.
     *
     * @param contractId
     * @return {Promise<void>}
     */
    async contract(contractId: string) {
        const contract = await this.contracts.getContract(contractId);
        if (!contract) {
            throw new Error("Could not create code, contract not found");
        }

        contract.features = getFeatures(contract);

        this.contractData = contract;
    }

    /**
     * Set the method to call for the step.
     *
     * This requires that a contract has already been set, so that the ABI is available.
     */
    method(method: string): StepBuilder {
        this.func = this.contractData.abi.find(it => it.type === "function" && it.name === method);

        if (!this.func) {
            throw new Error(`Could not create code, function '${method}' not found`);
        }

        return this;
    }

    /**
     * Set a method using an existing ABI.
     */
    methodAbi(abi: BaseFragment): StepBuilder {
        this.func = abi;
        return this;
    }

    /**
     * Make the step transfer Ether on the given network.
     *
     * @param network
     * @return {StepBuilder}
     */
    ether(network: ChainReference): StepBuilder {
        this.arguments = [];
        this.contractData = null;
        this.func = null;
        this._step.ether = true;
        this._step.network = createLiteralSource(getChain(network).alias);
        return this;
    }

    /**
     * Add an argument to the method call for this step.
     *
     * @return {ArgumentBuilder}
     */
    addArgument(): ArgumentBuilder {
        let builder = new ArgumentBuilder(this.contractData, this.func, this.arguments.length);
        this.arguments.push(builder);
        return builder;
    }

    /**
     * Specify an address for sending Ether to.
     *
     * This should not be set manually for contract steps.
     */
    address(value: string): StepBuilder {
        this._step.address = createLiteralSource(value);
        return this;
    }

    /**
     * Build a dynamic source for the Ether value for this step.
     */
    valueSource(): SourceBuilder {
        this._valueSource = new SourceBuilder();
        delete this._step.value;
        return this._valueSource;
    }

    /**
     * Build a dynamic source for the address in this step.
     */
    addressSource(): SourceBuilder {
        this._addressSource = new SourceBuilder();
        return this._addressSource;
    }

    /**
     * Build a dynamic source for the network in this step.
     */
    networkSource(): SourceBuilder {
        this._networkSource = new SourceBuilder();
        return this._networkSource;
    }
}

export class ArgumentBuilder {
    private _source: SourceBuilder;
    private _decimalsSource: SourceBuilder;
    private _symbolSource: SourceBuilder;
    private _dynamic: Record<string, DynamicBuilder> = {};

    private _arg: Partial<DumbappArgument> = {};

    private definition: JsonFragmentType;
    private func: JsonFragment;
    private argumentNumber: number;


    constructor(
        private contractData?: ContractData,
        func?: JsonFragment,
        argumentNumber?: number
    ) {

        if (func && argumentNumber !== undefined) {
            this.definition = func.inputs[argumentNumber];
            this.func = func;
            this.argumentNumber = argumentNumber;

            this._arg.typing = Typing.parse(this.definition).toTypeInfo();
            this._arg.type = getFieldType(this.definition.name, this._arg.typing, func, argumentNumber);
            this._arg.label = capitalCase(this.definition.name);
            this._arg.name = this.definition.name;

            if (this.contractData?.features?.includes('erc20')) {
                if ((func.name === "transfer" || func.name === "approve")
                    && func.inputs.length === 2
                    && argumentNumber === 1
                ) {
                    this.autoDecimals();
                    this.autoSymbol();
                }
            }
        }
    }

    build() {
        let arg = Object.assign({}, this._arg);

        if (this._source) {
            arg.value = this._source.build();
        }
        if (this._decimalsSource) {
            arg.decimals = this._decimalsSource.build();
        }
        if (this._symbolSource) {
            arg.symbol = this._symbolSource.build();
        }

        return arg as DumbappArgument;
    }

    /**
     * Set the argument's name.
     *
     * The name is normally set automatically from the ABI, so typically this does
     * not need to be called.
     */
    name(value: string): ArgumentBuilder {
        this._arg.name = value;
        return this;
    }

    buildDynamic() {
        return Object.values(this._dynamic)
            .map(it => it.build(this.contractData));
    }

    /**
     * Set the argument's label.
     */
    label(value: string): ArgumentBuilder {
        this._arg.label = value;
        return this;
    }

    /**
     * Set a static number of decimals for this argument.
     */
    decimals(value: number): ArgumentBuilder {
        this._arg.decimals = createLiteralSource(value);
        this._decimalsSource = null;
        return this;
    }

    /**
     * Set a static symbol for this argument.
     */
    symbol(value: string): ArgumentBuilder {
        this._arg.symbol = createLiteralSource(value);
        this._symbolSource = null;
        return this;
    }

    /**
     * Give the argument a preset value.
     */
    value(value: string): ArgumentBuilder {
        this._source = null;
        this._arg.value = createLiteralSource(value);
        return this;
    }

    /**
     * Set the type of the argument.
     *
     * The type is normally set automatically from the ABI, so typically this does
     * not need to be called.
     */
    type(value: DumbappArgumentType): ArgumentBuilder {
        this._arg.type = value;
        delete this._arg.typeOptions;
        return this;
    }

    /**
     * Make this argument represent time.
     *
     * Time arguments normally represent a time span in seconds. If you pass true
     * as the optional argument, it will instead represent a Unix timestamp.
     *
     * @param timestamp If true, the argument is a Unix timestamp instead of number of seconds
     */
    time(timestamp: number | boolean = false): ArgumentBuilder {
        this._arg.type = "time";
        if (timestamp) {
            this._arg.typeOptions = {timestamp};
        } else {
            delete this._arg.typeOptions;
        }
        return this;
    }

    typeOptions(value: DumbappArgument["typeOptions"]): ArgumentBuilder {
        this._arg.typeOptions = value;
        return this;
    }

    source(): SourceBuilder {
        this._source = new SourceBuilder();
        return this._source;
    }

    decimalsSource(addressDynamic?: string): SourceBuilder {
        this._decimalsSource = new SourceBuilder();
        delete this._arg.decimals;

        if (addressDynamic) {
            this._decimalsSource.call("decimals").addressSource().dynamic(addressDynamic);
        }

        return this._decimalsSource;
    }

    symbolSource(addressDynamic?: string): SourceBuilder {
        this._symbolSource = new SourceBuilder();
        delete this._arg.symbol;
        if (addressDynamic) {
            this._symbolSource.call("symbol").addressSource().dynamic(addressDynamic);
        }

        return this._symbolSource;
    }

    autoDecimals(): ArgumentBuilder {
        this._decimalsSource = new SourceBuilder().call("decimals");
        return this;
    }

    autoSymbol(): ArgumentBuilder {
        this._symbolSource = new SourceBuilder().call("symbol");
        return this;
    }

    dynamic(): DynamicBuilder {
        let dynamic = new DynamicBuilder(
            this._arg.typing,
            this._arg.type,
            this._arg.name,
            this._arg.label
        );

        this._dynamic[this._arg.name] = dynamic;

        this.source().dynamic(this._arg.name);

        return dynamic;
    }
}

export class SourceBuilder {
    private _source: Partial<DumbappSource> = {parameters: {}}
    private _feeBase: SourceBuilder;
    private _amountIn: ArgumentBuilder;
    private _uniswapPath: ArgumentBuilder[];
    private _addressSource: SourceBuilder;

    constructor() {
    }

    build() {
        let source = Object.assign({}, this._source);

        if (this._addressSource) {
            source.parameters.address = this._addressSource.build();
        }

        if (this._source.type === "uniswap") {
            if (this._source.parameters.name === "amountOutMin") {
                source.parameters = {
                    ...source.parameters,
                    amountIn: this._amountIn.build(),
                    path: this._uniswapPath.map(it => it.build())
                };
            } else if (this._source.parameters.name === "path") {
                source.parameters = {
                    ...source.parameters,
                    path: this._uniswapPath.map(it => it.build())
                };
            }
        }
        if (this._source.type === "fee") {
            source.parameters.base = this._feeBase.build();
        }

        return source as DumbappSource;
    }

    literal(value: DumbappValue): SourceBuilder {
        this._source = createLiteralSource(value);
        return this;
    }

    dynamic(name: string): SourceBuilder {
        this._source = createDynamicSource(name);
        return this;
    }

    sender(): SourceBuilder {
        this._source = {
            type: "other",
            parameters: {name: "sender"}
        };
        return this;
    }

    call(abi: string | JsonFragment): SourceBuilder {
        this._source = {
            type: "call",
            parameters: {abi}
        }
        return this;
    }

    fee(multiplier: string, ignoreDecimals?: boolean): SourceBuilder {
        this._source = {
            type: "fee",
            parameters: {
                multiplier: createLiteralSource(multiplier),
                ignoreDecimals
            }
        };
        this._feeBase = new SourceBuilder();
        return this._feeBase;
    }

    uniswap(
        name: "fee-in" | "fee-out" | "amountOutMin" | "path" | "amountIn"
    ): SourceBuilder {
        this._source = {
            type: "uniswap",
            parameters:{name}
        }
        return this;
    }

    uniswapAmountIn(): ArgumentBuilder {
        this._amountIn = new ArgumentBuilder();
        return this._amountIn;
    }

    uniswapPath(builders: ArgumentBuilder[]) {
        this._uniswapPath = builders;
        return this;
    }

    steps(name: DumbappStepsSourceParameters["name"], stepNumber: number) {
        this._source = createStepsSource(stepNumber, name);
    }

    address(value: string): SourceBuilder {
        this._addressSource = null;
        this._source.parameters.address = createLiteralSource(value);
        return this;
    }

    addressSource(): SourceBuilder {
        this._addressSource = new SourceBuilder();
        delete this._source.parameters.address;
        return this._addressSource;
    }
}

export class RequiresAssetBuilder {
    private map = new SourcableMap<DumbappRequiresAsset>();
    private _requiresAsset: Partial<DumbappRequiresAsset> = {};

    constructor() {
    }

    build(): DumbappRequiresAsset {
        let built = this.map.build();
        let value = built.value;
        if (!value) {
            throw new Error("Value is required in RequiresAsset.");
        }

        return {
            ...this._requiresAsset,
            ...built,
            value,
            address: this._requiresAsset.address,
            network: this._requiresAsset.network,
            currencyCode: this._requiresAsset.currencyCode,
        };
    }

    asset(address: string, network: ChainReference): RequiresAssetBuilder {
        let asset = address;
        if (asset.toLowerCase() === "eth") {
            asset = "0x0000000000000000000000000000000000000000";
        }

        this._requiresAsset.address = asset;
        this._requiresAsset.network = getChain(network).alias;
        return this;
    }

    value(value: string): RequiresAssetBuilder {
        this.map.literal("value", value);
        return this;
    }
    valueSource(): SourceBuilder {
        return this.map.source("value");
    }
    decimals(decimals: string): RequiresAssetBuilder {
        this.map.literal("decimals", decimals);
        return this;
    }
    decimalsSource(): SourceBuilder {
        return this.map.source("decimals");
    }

    gas(value: string): RequiresAssetBuilder {
        this._requiresAsset.gas = value;
        return this;
    }
}

export class DynamicBuilder {
    private _dynamic: DumbappDynamic;

    constructor(typing: TypeInfo, type: string, name: string, label: string) {
        this._dynamic = {
            typing, type, name, label
        };
    }

    build(contract?: ContractData) {
        let dynamic = Object.assign({}, this._dynamic);

        if (contract && ['payment-proposal', 'suggestion', 'proposal'].includes(this._dynamic.type)) {
            dynamic.contractId = contract.id;
            dynamic.address = contract.address;
            dynamic.network = contract.network;
        }

        return dynamic;
    }

    name(value: string): DynamicBuilder {
        this._dynamic.name = value;
        return this;
    }

    label(value: string): DynamicBuilder {
        this._dynamic.label = value;
        return this;
    }

    help(value: string): DynamicBuilder {
        this._dynamic.help = value;
        return this;
    }

    type(value: string): DynamicBuilder {
        this._dynamic.type = value;
        return this;
    }
}

export class RequiresApprovalBuilder {
    private map = new SourcableMap<DumbappRequiresApproval>();

    build(): DumbappRequiresApproval {
        let built = this.map.build();
        let token = built.token;
        if (!token) {
            throw new Error("Unable to build approval, token is required.");
        }

        return {
            ...built,
            token
        }
    }

    token(address: string): RequiresApprovalBuilder {
        this.map.literal("token", address);
        return this;
    }
    tokenSource() {
        return this.map.source("token");
    }
    spender(address: string): RequiresApprovalBuilder {
        this.map.literal("spender", address);
        return this;
    }
    spenderSource() {
        return this.map.source("spender");
    }
    value(address: string): RequiresApprovalBuilder {
        this.map.literal("value", address);
        return this;
    }
    valueSource() {
        return this.map.source("value");
    }
    whenBelow(address: string): RequiresApprovalBuilder {
        this.map.literal("whenBelow", address);
        return this;
    }
    whenBelowSource() {
        return this.map.source("whenBelow");
    }
}

type SourcableMappingBuild<T> = {
    [P in keyof T as T[P] extends DumbappSource ? P : never]?: DumbappSource;
};

interface Sourcables<TypeMap>
    extends Map<keyof SourcableMappingBuild<TypeMap>, SourceBuilder | DumbappSource> {
    get: <Prop extends keyof SourcableMappingBuild<TypeMap>>(
        key: Prop
    ) => SourceBuilder | DumbappSource | undefined;
}

class SourcableMap<in out T> {
    private mapping: Sourcables<T> = new Map();

    literal(key: keyof SourcableMappingBuild<T>, value: DumbappValue) {
        this.mapping.set(key, createLiteralSource(value));
    }

    source(key: keyof SourcableMappingBuild<T>): SourceBuilder {
        let builder = new SourceBuilder();
        this.mapping.set(key, builder);
        return builder;
    }

    build() {
        let entries = Array.from(this.mapping.entries());
        return Object.fromEntries(entries.map(([key, val]) => {
            if (val instanceof SourceBuilder) {
                return [key, val.build()]
            } else {
                return [key, val]
            }
        })) as SourcableMappingBuild<T>;
    }
}
