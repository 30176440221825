export class DynamicSourceProvider {
    constructor() {
        this.name = "dynamic";
    }
    getValue(provider, source, arg) {
        let value = this._value(provider, source, arg);
        return {
            value() {
                return value;
            },
        };
    }
    _value(provider, source, arg) {
        return provider.getInput(source.name, arg.typing);
    }
}
