export class DataStringValues {
}
export class DataStringFunctions {
}
export function isDataStringFunctions(it) {
    if (!it) {
        return false;
    }
    let val = Object.values(it)?.[0];
    return val && typeof val === "object" && "value" in val && typeof val.value === "function";
}
