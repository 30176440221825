import { ExecutionStateValueProvider } from "./ExecutionStateValueProvider";
import { DumbappValue } from "../schema";
import { DataStringFunctions } from "../DumbappValueFunction";
import { ExecutionResolution } from "./ExecutionState";
import {
    convertToValue,
    resolveAddressSourcable,
    resolveBigNumberishSourcable,
    resolveBigNumberishValue, resolveStringSourcable,
    resolveStringValue
} from "./ArgumentResolver";
import { Typing } from "@blockwell/eth-types";
import { isEtherStep } from "../util";
import { getChain } from "@blockwell/chains";
import { EthAddressArgument, EthStringArgument, EthValueArgument, getLiteralSourceParameters } from "../source-util";
import { ResolveHandler } from "./ExecutionPhases";

export class ExecutionArgumentResolver {
    constructor() {}

    readonly handler: ResolveHandler = async (state) => {
        const provider = new ExecutionStateValueProvider(state);
        let args = state.dumbapp.steps.flatMap((step, stepIndex) => {
            return step.arguments.map(async (arg, argIndex) => {
                let value: DumbappValue | DataStringFunctions;
                if (arg.value) {
                    let result = await provider.getArgValue(arg, step);
                    if (result === null || result === undefined) {
                        value = "";
                    } else {
                        value = await result.value();
                    }
                } else {
                    value = "";
                }

                let resolution: ExecutionResolution = {
                    type: "argument",
                    step: stepIndex,
                    number: argIndex,
                    value: await convertToValue(provider, value, arg, step),
                };
                return resolution;
            });
        });

        // ETH values for the steps
        let values = state.dumbapp.steps.flatMap(async (step, stepIndex) => {
            let value = await resolveBigNumberishSourcable(
                provider,
                [step.value],
                step,
                Typing.uint
            );
            if (value) {
                let converted: DumbappValue = await convertToValue(
                    provider,
                    value,
                    EthValueArgument,
                    step
                );

                if (!converted) {
                    converted = "";
                }

                if (typeof converted !== "string") {
                    throw new Error(
                        `Step ${stepIndex} value resolved into an invalid value: ${converted}`
                    );
                }

                let resolution: ExecutionResolution = {
                    type: "value",
                    step: stepIndex,
                    value: converted,
                };
                return resolution;
            }
            return null;
        });

        // Addresses for steps
        let addresses = state.dumbapp.steps.flatMap(async (step, stepIndex) => {
            let address = await resolveAddressSourcable(provider, [step.address], step);
            if (address) {
                let converted: DumbappValue = await convertToValue(
                    provider,
                    address,
                    EthAddressArgument,
                    step
                );

                if (!converted) {
                    converted = "";
                }

                if (typeof converted !== "string") {
                    throw new Error(
                        `Step ${stepIndex} address resolved into an invalid value: ${converted}`
                    );
                }

                let resolution: ExecutionResolution = {
                    type: "address",
                    step: stepIndex,
                    value: converted,
                };
                return resolution;
            }
            return null;
        });

        // Networks for steps
        let networks = state.dumbapp.steps.flatMap(async (step, stepIndex) => {
            let network = await resolveStringSourcable(provider, [step.network], step);
            if (network) {
                let converted: DumbappValue = await convertToValue(
                    provider,
                    network,
                    EthStringArgument,
                    step
                );

                let chainId: number;
                if (typeof converted === "number") {
                    chainId = converted;
                }
                else if (typeof converted === "string") {
                    chainId = getChain(converted).chainId;
                } else {
                    throw new Error(
                        `Step ${stepIndex} network resolved into an invalid value: ${converted}`
                    );
                }

                let resolution: ExecutionResolution = {
                    type: "network",
                    step: stepIndex,
                    value: chainId,
                };
                return resolution;
            }
            return null;
        });

        let resolutions: (ExecutionResolution | null)[] = await Promise.all([
            ...args,
            ...values,
            ...addresses,
            ...networks
        ]);

        return {
            success: true,
            data: resolutions.filter((it) => !!it),
        };
    };
}
