import { getAddress } from "@ethersproject/address";
import { JsonFragment, JsonFragmentType } from "@ethersproject/abi";
import { BigNumber as BN } from "@ethersproject/bignumber";
import { Contract, EventFilter } from "@ethersproject/contracts";
import {
    Block,
    EventType,
    ExternalProvider,
    JsonRpcProvider,
    Listener,
    TransactionReceipt, TransactionResponse
} from "@ethersproject/providers";
import BigNumber from "bignumber.js";
import balanceAbi from "./balance-abi";
import { BaseFragment, EthLog, EthMethod, Typing } from "@blockwell/eth-types";
import { ChainReference, EthNetwork, getChain } from "@blockwell/chains";
import commonAbis from "./common-abis";
import { AbiDecoderData, Decoder, ErrorCode } from "@blockwell/eth-decoder";
import { isStrings } from "@blockwell/util";
import { ChainArgument, ChainResponse, ethParamToChainResponse } from "./ChainResponse";
import { ProviderFactory } from "./ProviderFactory";
import { addMulticall3 } from "@blockwell/ethcall/lib/multicall";
import { Cache, SyncMemoryCache } from "@blockwell/cache";
import { clone, uniqBy } from "remeda";
import { BatcherCall } from "./Batcher";
import { MutexMap } from "@blockwell/cache/lib/MutexMap";
import { CALL_ERROR } from "@blockwell/ethcall";
import { Log } from "@ethersproject/abstract-provider/src.ts";
import { Counter, Metrics } from "./Metrics";
import { Signer } from "@ethersproject/abstract-signer";
import { Overrides, PayableOverrides } from "@ethersproject/contracts/src.ts";

export const addressRegex = /^0x[a-fA-F\d]{40}$/;

export type GasPrice =
    | { maxPriorityFeePerGas: string; type: 2; maxFeePerGas: string }
    | { gasPrice: string; type: 0 };

addMulticall3(49111, {
    block: 6996256,
    address: "0xd6AD83BCAe5f8A9Add5dbA70f9D14FC9cEacc903",
});

addMulticall3(49112, {
    block: 5716812,
    address: "0xd6AD83BCAe5f8A9Add5dbA70f9D14FC9cEacc903",
});

addMulticall3(49113, {
    block: 3368864,
    address: "0xd6AD83BCAe5f8A9Add5dbA70f9D14FC9cEacc903",
});

addMulticall3(49777, {
    block: 8015782,
    address: "0xd6AD83BCAe5f8A9Add5dbA70f9D14FC9cEacc903",
});

let longCacheMethods = ["decimals", "symbol", "bwver", "bwtype", "name", "WETH"];
let shortCacheMethods = [
    "balanceOf",
    "allowance",
    "isApprovedForAll",
    "getApproved",
    "tokenOfOwnerByIndex",
];
let mediumCacheMethods = ["tokenURI", "supportsInterface"];

let i = 0;

const digitTest = /^\d/;

export interface SubscriptionListener {
    listener: Listener;
    chainId: number;
    filter: EventType;
}

export interface ChainClientExternalSender {
    ext: ExternalProvider;
    from: string;
}

export interface ChainClientSignerSender {
    net: ChainReference;
    signer?: Signer;
    from?: string;
}

export type ChainClientSender = ChainClientExternalSender | ChainClientSignerSender;

function stringifyArgs(args: ChainArgument[]) {
    let val = "";
    for (let it of args) {
        if (typeof it === "string" || typeof it === "boolean" || typeof it === "number") {
            val += ":" + it;
        } else if (Array.isArray(it)) {
            val += "[" + stringifyArgs(it) + "]";
        } else {
            val += "{" + stringifyArgs(Object.values(it)) + "}";
        }
    }
    return val;
}

function cyrb53(str: string, seed = 0) {
    let h1 = 0xdeadbeef ^ seed,
        h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
        ch = str.charCodeAt(i);
        h1 = Math.imul(h1 ^ ch, 2654435761);
        h2 = Math.imul(h2 ^ ch, 1597334677);
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

export class ChainClient extends MutexMap {
    public readonly ADDRESS_ZERO = "0x0000000000000000000000000000000000000000";

    protected cache = new SyncMemoryCache(30 * 1000);

    protected subListeners: SubscriptionListener[] = [];

    protected lastContract: Contract;
    protected lastContractChainId: number;
    protected lastContractAbiLength: number;
    protected lastContractFuncName: string;

    protected _metrics: {
        wsKeepalive: Counter<"chainId">;
        cacheMisses: Counter<"chainId">;
        rpcBatches: Counter<"chainId">;
        requests: Counter<"chainId">;
        wsOpen: Counter<"chainId">;
        multicallRequests: Counter<"chainId">;
        multiCacheMisses: Counter<"chainId">;
        multicalls: Counter<"chainId">;
        rpcBatchRequests: Counter<"chainId">;
        wsReconnect: Counter<"chainId">;
        wsClose: Counter<"chainId">;
        cacheHits: Counter<"chainId">;
        multiCacheHits: Counter<"chainId">;
    };

    get metrics() {
        return this._metrics;
    }

    set metrics(metrics) {
        this._metrics = metrics;
        this.providerFactory.metrics = metrics;
    }

    constructor(
        public readonly providerFactory: ProviderFactory,
        protected longCache: Cache,
        met?: Metrics
    ) {
        super();
        if (met) {
            this._metrics = {
                wsKeepalive: met.counter("wsKeepalive"),
                wsOpen: met.counter("wsOpen"),
                wsReconnect: met.counter("wsReconnect"),
                wsClose: met.counter("wsClose"),
                cacheHits: met.counter("cacheHits"),
                cacheMisses: met.counter("cacheMisses"),
                requests: met.counter("requests"),
                multicalls: met.counter("multicalls"),
                multicallRequests: met.counter("multicallRequests"),
                multiCacheHits: met.counter("multiCacheHits"),
                multiCacheMisses: met.counter("multiCacheMisses"),
                rpcBatches: met.counter("rpcBatches"),
                rpcBatchRequests: met.counter("rpcBatchRequests"),
            };
        } else {
            this._metrics = {} as any;
        }
        providerFactory.metrics = this._metrics;
    }

    replaceCache(cache: Cache) {
        this.longCache = cache;
    }

    protected parseValue = (
        res: any,
        abi: Array<JsonFragmentType> | ReadonlyArray<JsonFragmentType>
    ): ChainResponse => {
        if (res === null || res === undefined || abi.length === 0) {
            return res;
        }

        if (res.hasOwnProperty(CALL_ERROR)) {
            return res;
        }

        if (abi.length > 1) {
            // Annoyingly tuple responses can be both an object and an array, so to remain compatible we have to do both
            // in these cases
            let response: { [key: string]: ChainResponse } & ChainResponse[] = [] as any;
            let index = 0;

            for (let it of abi) {
                let converted = ethParamToChainResponse(
                    Decoder.convertParam(Typing.parse(it), res[index])
                );
                response.push(converted);
                if (it.name) {
                    response[it.name] = converted;
                }

                ++index;
            }
            return response;
        }

        return ethParamToChainResponse(Decoder.convertParam(Typing.parse(abi[0]), res));
    };

    protected async doReadContract(
        net: EthNetwork,
        address: string,
        abi: JsonFragment[],
        method: string,
        args: ChainArgument[]
    ) {
        const provider = await this.providerFactory.getProvider(net);
        let contract = this.lastContract;

        // It's worth caching the contract if it's the same
        if (
            !contract ||
            contract.address !== address ||
            this.lastContractChainId !== net.chainId ||
            this.lastContractAbiLength !== abi.length ||
            this.lastContractFuncName !== abi[0]?.name
        ) {
            contract = new Contract(address, abi, provider);

            // Don't cache contracts with minimal ABI, because it's likely not a full ABI
            if (abi.length > 2) {
                this.lastContract = contract;
                this.lastContractChainId = net.chainId;
                this.lastContractAbiLength = abi.length;
                this.lastContractFuncName = abi[0]?.name;
            }
        }

        const func = abi.find((it) => it.name === method && it.inputs.length === args.length);
        let res = await contract[method](...args);

        return this.parseValue(res, func.outputs);
    }

    public static cacheId(chainId: number, address: string, method: string, args: ChainArgument[]) {
        let id = chainId + address.slice(0, 20) + method;

        if (args) {
            id += stringifyArgs(args);
        }

        return cyrb53(id).toString(16);
    }

    async readContract(
        network: ChainReference,
        address: string,
        abi: JsonFragment[],
        method: string,
        args: ChainArgument[] = [],
        expiration: number = null,
        defaultValue: any = undefined
    ): Promise<ChainResponse> {
        const net = getChain(network);

        let t = i++;
        if (expiration === 0) {
            return await this.doReadContract(net, address, abi, method, args);
        } else {
            let cacheKey = ChainClient.cacheId(net.chainId, address, method, args);
            let exp = expiration;

            if (exp === null) {
                exp = this.cacheDuration(method, args);
            }

            try {
                let cached = this.cache.getItem(cacheKey)?.data;

                if (cached) {
                    this._metrics.cacheHits?.labels({ chainId: net.chainId }).inc();
                    return cached;
                }

                let release = await this.getMutex(cacheKey).acquire();
                try {
                    if (exp > 60 * 1000) {
                        cached = (await this.longCache.getItem(cacheKey))?.data;

                        if (cached) {
                            this._metrics.cacheHits?.labels({ chainId: net.chainId }).inc();
                            return cached;
                        }
                    }

                    let res = await this.doReadContract(net, address, abi, method, args);
                    this._metrics.cacheMisses?.labels({ chainId: net.chainId }).inc();

                    this.cache.setItem(cacheKey, res, exp);

                    if (exp > 60 * 1000) {
                        await this.longCache.setItem(cacheKey, res, exp);
                    }

                    return res;
                } finally {
                    release();
                }
            } catch (err) {
                if (defaultValue !== undefined) {
                    // still cache the result for a shorter duration
                    this.cache.setItem(cacheKey, defaultValue, 5000);
                    console.warn(
                        `Failed to read contract method ${method}, using default value:`,
                        defaultValue
                    );
                    return defaultValue;
                } else {
                    // still cache the result for a shorter duration
                    this.cache.setItem(cacheKey, null, 5000);
                    throw err;
                }
            }
        }
    }

    protected cacheDuration(method: string, args: ChainArgument[]) {
        if (args.length === 0 && longCacheMethods.includes(method)) {
            return 60 * 60 * 24 * 30 * 1000;
        } else if (shortCacheMethods.includes(method)) {
            return 15 * 1000;
        } else if (mediumCacheMethods.includes(method)) {
            return 60 * 60 * 1000;
        } else {
            return 30 * 1000;
        }
    }

    readString(
        network: ChainReference,
        address: string,
        abi: JsonFragment[],
        method: string,
        args: ChainArgument[] = [],
        expiration: number = null,
        defaultValue: any = undefined
    ): Promise<string> {
        return this.readContract(
            network,
            address,
            abi,
            method,
            args,
            expiration,
            defaultValue
        ).then((it) => {
            if (typeof it === "string") {
                return it;
            }
            return null;
        });
    }

    readArray(
        network: ChainReference,
        address: string,
        abi: JsonFragment[],
        method: string,
        args: ChainArgument[] = [],
        expiration: number = null,
        defaultValue: any = undefined
    ) {
        return this.readContract(
            network,
            address,
            abi,
            method,
            args,
            expiration,
            defaultValue
        ).then((it) => {
            if (!Array.isArray(it)) {
                return null;
            }
            return it;
        });
    }

    readStringArray(
        network: ChainReference,
        address: string,
        abi: JsonFragment[],
        method: string,
        args: ChainArgument[] = [],
        expiration: number = null,
        defaultValue: any = undefined
    ) {
        return this.readArray(network, address, abi, method, args, expiration, defaultValue).then(
            (it) => {
                if (isStrings(it)) {
                    return it;
                }
                return null;
            }
        );
    }

    curryRead(
        network: string | number,
        address: string,
        abi: JsonFragment[]
    ): (
        method: string,
        args: [],
        expiration?: number,
        defaultValue?: any
    ) => Promise<ChainResponse> {
        return (method: string, args: [] = [], expiration?: number, defaultValue?: any) => {
            return this.readContract(network, address, abi, method, args, expiration, defaultValue);
        };
    }

    async getBalance(network: ChainReference, account: string) {
        const net = getChain(network);
        let cacheKey = [net.chainId, account].join("-");
        return await this.cache.withCache(
            cacheKey,
            async () => {
                const provider = await this.providerFactory.getProvider(net);
                let res = await provider.getBalance(account);
                return res.toString();
            },
            true,
            15000
        );
    }

    async getTokenBalance(
        network: ChainReference,
        address: string,
        account: string
    ): Promise<string> {
        return this.readString(network, address, balanceAbi, "balanceOf", [account]);
    }

    protected async getSender(sender: ChainClientSender) {
        let provider: JsonRpcProvider;
        let signer: Signer;
        let from: string;

        if ("ext" in sender) {
            provider = await this.providerFactory.getProvider(sender.ext);
            signer = provider.getSigner(getAddress(sender.from));
            from = sender.from;
        } else {
            provider = await this.providerFactory.getProvider(getChain(sender.net));
            signer = sender.signer;
            from = sender.from;

            if (signer) {
                signer = signer.connect(provider);
                from = await signer.getAddress();
            }
        }
        const network = await provider.getNetwork();

        return { provider, signer, network, from };
    }

    /**
     * Interact with a smart contract using a transaction, ie. "send".
     *
     * @return The transaction hash
     */
    async writeContract(
        sender: ChainClientSender,
        address: string,
        abi: JsonFragment[],
        method: string,
        args: ChainArgument[] = [],
        value: string = null,
        options?: { gasLimit?: string; gasPrice?: GasPrice; nonce?: number }
    ): Promise<string> {
        const { signer, network } = await this.getSender(sender);

        if (!signer) {
            throw new Error("A signer is required for writing to contracts");
        }
        const contract = new Contract(address, abi, signer);

        let overrides: PayableOverrides = {};
        if (options?.gasLimit) {
            overrides.gasLimit = options.gasLimit;
        } else {
            let gasLimit: BN;
            try {
                gasLimit = await this.estimateWriteGas(sender, address, abi, method, args, value);
                overrides.gasLimit = gasLimit;
            } catch (err) {
                console.error("Error estimating gas:", err.message);
            }
        }

        if (value) {
            overrides.value = BN.from(value);
        }

        if (options?.gasPrice) {
            if (options.gasPrice.type === 0) {
                overrides.gasPrice = options.gasPrice.gasPrice;
            } else {
                overrides.maxFeePerGas = options.gasPrice.maxFeePerGas;
                overrides.maxPriorityFeePerGas = options.gasPrice.maxPriorityFeePerGas;
            }
        } else {
            if (network.chainId >= 49000 && network.chainId < 50000) {
                overrides.gasPrice = 0;
            }
        }

        if (options?.nonce) {
            overrides.nonce = options.nonce;
        }

        let res = await contract[method](...args, overrides);

        return res.hash;
    }

    async estimateWriteGas(
        sender: ChainClientSender,
        address: string,
        abi: JsonFragment[],
        method: string,
        args: ChainArgument[] = [],
        value: string = null
    ) {
        const { provider, from } = await this.getSender(sender);
        const contract = new Contract(address, abi, provider);

        let overrides: Record<string, any> = { from };

        if (value) {
            overrides.value = BN.from(value);
        }

        return await contract.estimateGas[method](...args, overrides);
    }

    async transaction(
        sender: ChainClientSender,
        to: string,
        value: string,
        options?: { gasLimit?: string; gasPrice?: GasPrice; nonce?: number }
    ) {
        const { signer, network } = await this.getSender(sender);

        let overrides: PayableOverrides = {};
        if (options?.gasLimit) {
            overrides.gasLimit = options.gasLimit;
        }

        if (options?.gasPrice) {
            if (options.gasPrice.type === 0) {
                overrides.gasPrice = options.gasPrice.gasPrice;
            } else {
                overrides.maxFeePerGas = options.gasPrice.maxFeePerGas;
                overrides.maxPriorityFeePerGas = options.gasPrice.maxPriorityFeePerGas;
            }
        } else {
            if (network.chainId >= 49000 && network.chainId < 50000) {
                overrides.gasPrice = 0;
            }
        }

        if (options?.nonce) {
            overrides.nonce = options.nonce;
        }

        let response = await signer.sendTransaction({
            to,
            value: BN.from(value),
            ...overrides,
        });

        return response.hash;
    }

    async estimateTransactionGas(sender: ChainClientSender, to: string, value: string) {
        const { provider, from } = await this.getSender(sender);
        return await provider.estimateGas({
            from,
            to,
            value: BN.from(value),
        });
    }

    async receipt(
        providerSource: EthNetwork | ExternalProvider,
        transactionHash: string,
        wait = false
    ) {
        const provider = await this.providerFactory.getProvider(providerSource);

        if (wait) {
            return provider.waitForTransaction(transactionHash);
        }

        return provider.getTransactionReceipt(transactionHash);
    }

    async getTransaction(network: ChainReference, transactionHash: string) {
        const chain = getChain(network);
        const cacheKey = ChainClient.cacheId(chain.chainId, "", transactionHash, []);

        let cached: {
            tx: TransactionResponse;
            receipt:  TransactionReceipt
        } = this.cache.getItem(cacheKey)?.data;

        if (cached) {
            return cached;
        }

        let release = await this.getMutex(cacheKey).acquire();
        try {
            const provider = await this.providerFactory.getProvider(chain);
            let [tx, receipt] = await Promise.all([
                provider.getTransaction(transactionHash),
                provider.getTransactionReceipt(transactionHash),
            ]);
            const res = {tx, receipt};

            this.cache.setItem(cacheKey, res, 5000);

            return res;
        } finally {
            release();
        }
    }

    async getBlock(network: ChainReference, blockNumber?: number) {
        const chain = getChain(network);
        const cacheKey = ChainClient.cacheId(chain.chainId, "block", "" + blockNumber, []);

        let cached: Block = this.cache.getItem(cacheKey)?.data;

        if (cached) {
            return cached;
        }

        let release = await this.getMutex(cacheKey).acquire();
        try {
            const provider = await this.providerFactory.getProvider(chain);
            const res = provider.getBlock(blockNumber);

            this.cache.setItem(cacheKey, res, 5000);

            return res;
        } finally {
            release();
        }
    }

    async events(
        network: ChainReference,
        address: string,
        abi: JsonFragment[],
        event: string,
        filters?: string[],
        fromBlock?: number | string,
        toBlock?: number | string
    ) {
        const chain = getChain(network);
        const provider = await this.providerFactory.getProvider(chain);
        const contract = new Contract(address, abi, provider);

        let filter: EventFilter;

        if (filters) {
            filter = contract.filters[event](...filters);
        } else {
            filter = event as EventFilter;
        }

        // Rinkeby is very slow to read events now, so limit it
        if (!fromBlock && chain.chainId === 4) {
            // 40k blocks is roughly a week
            fromBlock = (await provider.getBlockNumber()) - 40000;
        }

        return contract.queryFilter(filter, fromBlock, toBlock);
    }

    async decodeLogs(receipt: TransactionReceipt, abi: JsonFragment[]): Promise<EthLog[]> {
        const decoder = new Decoder(new AbiDecoderData([...abi, ...commonAbis]));
        let decoded = await decoder.logs(receipt.logs);
        return receipt.logs.map((it, index) => {
            return {
                ...it,
                ...(decoded[index] || {}),
            };
        });
    }

    async decodeError(data: string, abi?: JsonFragment[]): Promise<ErrorCode | EthMethod> {
        const decoder = new Decoder(new AbiDecoderData(abi));
        let res = await decoder.error(data);

        if (Array.isArray(res)) {
            return res[0];
        }
        return res;
    }

    async gasPrice(network: ChainReference, priority?: string) {
        const net = getChain(network);
        let cacheKey = ["gasprice", net.networkId].join("-");

        return await this.cache.withCache<GasPrice>(
            cacheKey,
            async () => {
                if (net.eip1559) {
                    const provider = await this.providerFactory.getProvider(net);
                    let block = await provider.getBlock("latest");
                    let baseFee = new BigNumber(block.baseFeePerGas.toString());
                    if (!baseFee.isNaN()) {
                        let priorityFee: string = priority || net.priorityFee || "3000000000";
                        return {
                            type: 2,
                            maxPriorityFeePerGas: priorityFee,
                            maxFeePerGas: baseFee.times(1.25).plus(priorityFee).toFixed(0),
                        };
                    } else {
                        console.warn("EIP-1559 network's baseFeePerGas was not a number", {
                            network,
                            baseFeePerGas: block.baseFeePerGas,
                        });
                    }
                } else if (net.gasPrice) {
                    return {
                        type: 0,
                        gasPrice: net.gasPrice,
                    };
                }
                return {
                    type: 0,
                    gasPrice: "1000000000",
                };
            },
            true,
            5000
        );
    }

    /**
     * Execute a batch of multicalls. If a string is passed as a call, that will be used as an address to query for
     * ETH balance.
     */
    async multicall(network: ChainReference, calls: (BatcherCall | string)[]) {
        const chain = getChain(network);
        let { ethcall } = await this.providerFactory.getEthcallProvider(chain);

        let cached = calls.map((it) => {
            if (typeof it === "string") {
                if (it === "block") {
                    if (ethcall.multicall3.address) {
                        return {
                            call: {
                                contract: {
                                    address: ethcall.multicall3.address,
                                },
                                inputs: [],
                                outputs: [
                                    {
                                        internalType: "uint256",
                                        name: "blockNumber",
                                        type: "uint256",
                                    },
                                ],
                                params: [],
                                name: "getBlockNumber",
                            },
                        };
                    } else {
                        return {
                            cached: -1,
                        };
                    }
                }
                let cacheKey = [chain.chainId, it.toLowerCase()].join("-");
                return {
                    call: ethcall.getEthBalance(it),
                    cacheKey,
                    duration: 15000,
                    cached: this.cache.getItem<string>(cacheKey, false, 15000)?.data,
                };
            }
            let cacheKey = ChainClient.cacheId(
                chain.chainId,
                it.contract.address.toLowerCase(),
                it.name,
                it.params
            );
            let duration: number;
            let cached: ChainResponse;

            if (it.cache === 1) {
                duration = this.cacheDuration(it.name, it.params);
            } else {
                duration = it.cache || this.cacheDuration(it.name, it.params);
                cached = this.cache.getItem<ChainResponse>(cacheKey, false, duration)?.data;
            }

            return {
                call: it,
                cacheKey,
                duration,
                cached,
            };
        });

        let asyncCalls = cached.filter((it) => {
            return !it.cached;
        });

        let cacheHits = cached.length - asyncCalls.length;
        let cacheMisses = asyncCalls.length;

        let res: Record<string, ChainResponse> = {};

        let uniques = uniqBy(asyncCalls, (it) => it.cacheKey);
        if (uniques.length > 0) {
            let release = await this.getMutex("multicall-" + chain.chainId).acquire();
            try {
                let remoteCalls: typeof uniques = [];
                for (let it of uniques) {
                    if (it.duration > 60 * 1000) {
                        let value = (await this.longCache.getItem(it.cacheKey))?.data;
                        if (value) {
                            cacheHits += 1;
                            cacheMisses -= 1;
                            res[it.cacheKey] = value;
                        } else {
                            remoteCalls.push(it);
                        }
                    } else {
                        remoteCalls.push(it);
                    }
                }

                if (remoteCalls.length > 0) {
                    this._metrics.multicalls
                        ?.labels({ chainId: chain.chainId })
                        ?.inc(remoteCalls.length);
                    this._metrics.multicallRequests?.labels({ chainId: chain.chainId })?.inc();
                    let tried = await ethcall.tryAll(remoteCalls.map((it) => it.call));
                    let callRes = tried.map(
                        (res, index) => {
                            let call = remoteCalls[index].call;
                            return this.parseValue(res, call.outputs);
                        }
                    );

                    await Promise.all(
                        remoteCalls.map(async (it, index) => {
                            let val = callRes[index];
                            res[it.cacheKey] = val;
                            if (val?.hasOwnProperty(CALL_ERROR)) {
                                this.cache.setItem(it.cacheKey, val, 5000);
                            } else if (it.duration) {
                                this.cache.setItem(it.cacheKey, val, it.duration);
                                if (it.duration > 60 * 1000) {
                                    await this.longCache.setItem(it.cacheKey, val, it.duration);
                                }
                            }
                        })
                    );
                }
            } finally {
                release();
            }
        }

        let result: ChainResponse[] = [];

        for (let it of cached) {
            if (it.cached) {
                result.push(it.cached);
            } else {
                result.push(res[it.cacheKey]);
            }
        }

        this._metrics.multiCacheHits?.labels({ chainId: chain.chainId })?.inc(cacheHits);
        this._metrics.multiCacheMisses?.labels({ chainId: chain.chainId })?.inc(cacheMisses);

        return result;
    }

    /**
     * Subscribe to event logs for a smart contract.
     *
     * Ethers.js takes care of combining subscriptions for the same contract.
     */
    onLogs(
        network: ChainReference,
        filter: { address: string; topics?: string[] },
        listener: (log: Log) => void
    ) {
        const chain = getChain(network);
        let provider = this.providerFactory.websocketForChain(chain);
        if (provider) {
            provider.on(filter, listener);
        } else {
            // Use ethers.js polling instead
            let provider = this.providerFactory.providerForChain(chain);
            provider.pollingInterval = 10000;
            provider.on(filter, listener);
        }

        this.subListeners.push({ chainId: chain.chainId, filter, listener });
    }

    off(listener: Listener) {
        let index = this.subListeners.findIndex((it) => it.listener === listener);

        if (index > -1) {
            let it = this.subListeners[index];
            const chain = getChain(it.chainId);
            const provider =
                this.providerFactory.websocketForChain(chain) ||
                this.providerFactory.providerForChain(chain);
            provider.off(it.filter, listener);
            this.subListeners.splice(index, 1);

            if (!this.subListeners.find((it) => it.chainId === chain.chainId)) {
                this.providerFactory.close(chain);
            }
        }
    }

    multiLogs(
        network: ChainReference,
        address: string[],
        topics: string[] | null,
        listener: Listener
    ) {
        const chain = getChain(network);
        let provider = this.providerFactory.websocketForChain(chain);
        if (provider) {
            provider.multiLogs(address, topics, listener);
        } else {
            throw new Error("multiLogs is only supported with websocket providers");
        }
    }

    stopMultiLogs(network: ChainReference, listener: Listener) {
        const chain = getChain(network);
        let provider = this.providerFactory.websocketForChain(chain);
        if (provider) {
            provider.stopMultiLogs(listener);
        } else {
            throw new Error("multiLogs is only supported with websocket providers");
        }
    }
}

export function parseAddress(value: string) {
    if (addressRegex.test(value)) {
        return value;
    }
    if (/^\d+$/.test(value)) {
        return "0x" + new BigNumber(value).toString(16).toLowerCase().padStart(40, "0");
    }
    return null;
}
