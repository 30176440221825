import { BaseFragment, ExtendedJsonFragment } from "@blockwell/eth-types";
import { JsonFragment } from "@ethersproject/abi";

export type LoaderFunction = (hash: string) => BaseFragment[] | Promise<BaseFragment[]>;

export interface DecoderData {
    addAbi(abi: BaseFragment[]): Promise<void> | void;

    addElement(element: ExtendedJsonFragment): Promise<void> | void;

    func(hash: string): Promise<BaseFragment[]> | BaseFragment[];

    event(topic0: string): Promise<BaseFragment[]> | BaseFragment[];

    error(hash: string): Promise<BaseFragment[]> | BaseFragment[];
}

export function jsonFragmentToBaseFragment(frag: JsonFragment): BaseFragment {
    // @ts-ignore
    return frag;
}

/**
 * Checks whether two ABI elements are equal.
 *
 * This compares type, name and parameter types. It ignores other things that are
 * missing from incomplete data, such as from 4byte directory.
 */
export function fragmentEquals(a: BaseFragment, b: BaseFragment) {
    if (a.type !== b.type) {
        return false;
    }
    if (a.name !== b.name) {
        return false;
    }
    if (a.inputs?.length !== b.inputs?.length) {
        return false;
    }
    if (a.inputs) {
        for (let i = 0; i < a.inputs.length; i++) {
            if (a.inputs[i].type !== b.inputs[i].type) {
                return false;
            }
            if (a.inputs[i].indexed !== b.inputs[i].indexed) {
                return false;
            }
        }
    }
    return true;
}
