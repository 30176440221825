import { DynamicSourceProvider } from "./DynamicSourceProvider";
import { StepsSourceProvider } from "./StepsSourceProvider";
import { CallSourceProvider } from "./CallSourceProvider";
import { FeeSourceProvider } from "./FeeSourceProvider";
import { JsonSourceProvider } from "./JsonSourceProvider";
import { UniswapSourceProvider } from "./UniswapSourceProvider";
import { OtherSourceProvider } from "./OtherSourceProvider";
import { LiteralSourceProvider } from "./LiteralSourceProvider";
export class SourceProviders {
    constructor() {
        this.sourceTypes = {};
    }
    addSourceType(type) {
        this.sourceTypes[type.name] = type;
        return this;
    }
    valueForSource(provider, source, arg, step) {
        let type = this.sourceTypes[source.type];
        if (type) {
            try {
                return type.getValue(provider, source.parameters, arg, step);
            }
            catch (err) {
                console.error(`Error getting value for argument source: ${source.type}`, err);
                return {
                    annotation() {
                        return '<span class="error">There was an error reading the value, please try again later. Contact us if this issue persists.</span>';
                    },
                    value() {
                        return null;
                    },
                };
            }
        }
        console.error(`Unrecognized type in valueForSource: ${source.type}`);
        return null;
    }
}
const defaultSourceProviders = new SourceProviders()
    .addSourceType(new LiteralSourceProvider())
    .addSourceType(new DynamicSourceProvider())
    .addSourceType(new StepsSourceProvider())
    .addSourceType(new CallSourceProvider())
    .addSourceType(new FeeSourceProvider())
    .addSourceType(new JsonSourceProvider())
    .addSourceType(new UniswapSourceProvider())
    .addSourceType(new OtherSourceProvider());
export default defaultSourceProviders;
