import { isContractStep } from "../util";
export class CachedContractsLoader {
    constructor() {
        this.api = null;
        this.handler = async (state, args) => {
            if (!this.api) {
                return {
                    success: false,
                    code: "uninitialized",
                    message: "No contracts API provided, can't load all data.",
                };
            }
            let contracts = [];
            let queries = args.map((it, index) => {
                let step = state.dumbapp.steps[index];
                if (isContractStep(step) && it.chainId && it.address) {
                    return it;
                }
                else {
                    return null;
                }
            });
            let result = await this.api.getAll(queries.filter((it) => !!it));
            for (let it of queries) {
                if (it) {
                    let contract = result.shift();
                    if (contract && "network" in contract) {
                        contracts.push(contract);
                        continue;
                    }
                }
                contracts.push(null);
            }
            return {
                success: true,
                data: contracts,
            };
        };
    }
    setApi(api) {
        this.api = api;
    }
}
