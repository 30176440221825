import ApiGroup from './ApiGroup';
import { Signature, SignMessageRequest } from "@blockwell/apiminer";

export interface ApiMinerAccount {
    id: string;
    owner?: string;
    address: string;
    created: string;
    defaultAccount: boolean;
}

export default class Accounts extends ApiGroup {

    /**
     * List Accounts belonging to the current user.
     */
    list() {
        return this.request<ApiMinerAccount[]>({
            url: '/accounts'
        }).then(response => response.data.data);
    }

    /**
     * Generates a new Ethereum account.
     *
     * @param owner (Optional) Account owner's user ID
     * @param makeDefault (Optional) Make the account the user's default account
     */
    create(owner?: string, makeDefault = false) {
        let data: any = {
            'default': makeDefault
        };

        if (owner) {
            data.owner = owner;
        }

        return this.request<ApiMinerAccount>({
            method: 'post',
            url: '/accounts',
            data
        }).then(response => response.data.data);
    }

    /**
     * Get a specific Account by its ID.
     */
    get(id: string) {
        return this.request<ApiMinerAccount>({
            url: `/accounts/${id}`
        }).then(response => response.data.data);
    }

    /**
     * Get the default account for the authenticated user.
     */
    getDefault() {
        return this.request<ApiMinerAccount>({
            url: `/accounts/default`
        }).then(response => response.data.data);
    }

    balance(network: string | number = 1, address?: string) {
        let url;

        if (!address) {
            url = `/accounts/default/balance/${network}`;
        } else {
            url = `/accounts/${address}/balance/${network}`;
        }

        return this.request<string>({
            url
        }).then(response => response.data.data);
    }

    signMessage(message: string, accountId?: string) {
        let url;

        if (!accountId) {
            url = `/accounts/default/sign`;
        } else {
            url = `/accounts/${accountId}/sign`;
        }

        let data: SignMessageRequest = {
            message
        };

        return this.request<Signature>({
            method: "POST",
            url,
            data
        }).then(response => response.data.data);
    }

    signData(hexData: string, accountId?: string) {
        let url;

        if (!accountId) {
            url = `/accounts/default/sign`;
        } else {
            url = `/accounts/${accountId}/sign`;
        }

        let data: SignMessageRequest = {
            data: hexData
        };

        return this.request<Signature>({
            method: "POST",
            url,
            data
        }).then(response => response.data.data);
    }
}
