import { DumbappExtraProcessor } from "./DumbappExtraProcessor";
import { ExecutorParameters } from "../executor";
import { DumbappSubmission } from "../submission";
import { getChain } from "@blockwell/chains";
import BigNumber from "bignumber.js";
import { TransferExtraBase, TransferExtraErc20, TransferExtraErc721 } from "../schema";

export type TransferExtra = TransferExtraErc20 | TransferExtraErc721;

export class TransferExtraProcessor implements DumbappExtraProcessor {
    key = "transfers";

    async process(parameters: ExecutorParameters, submission: DumbappSubmission): Promise<any[]> {
        let transfers: TransferExtra[] = [];

        for (let step of submission.data.steps) {
            if (step.events) {
                for (let event of step.events) {
                    if (
                        event?.topics?.[0] ===
                        "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef"
                    ) {
                        let transfer: TransferExtraBase = {
                            address: event.address,
                            chainId: getChain(step.network).chainId,
                            from: "0x" + event.topics[1].slice(-40),
                            to: "0x" + event.topics[2].slice(-40),
                        };

                        let contract = await parameters.contracts.getContract(transfer);

                        if (contract) {
                            transfer.name = contract.name;
                            transfer.symbol = contract.parameters?.symbol;
                        }

                        if (event.topics.length === 4) {
                            transfers.push({
                                ...transfer,
                                type: "erc721",
                                tokenId: new BigNumber(event.topics[3]).toString(10),
                            });
                        } else {
                            let value = new BigNumber(event.data);
                            transfers.push({
                                ...transfer,
                                type: "erc20",
                                value: value.toString(10),
                                amount: value.div(`1e${contract.parameters?.decimals || 18}`).toString(10)
                            });
                        }
                    }
                }
            }
        }

        return transfers;
    }
}
