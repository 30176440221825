type ValuesRecord = Record<string, {value: number, labels: LabelValues<any>}>;

export class Metrics {
    public values: ValuesRecord = {};

    protected metrics: Record<string, Counter> = {};

    counter<T extends string = string>(name: string): Counter<T> {
        if (!this.metrics[name]) {
            this.metrics[name] = new Counter<T>(this.values, name);
        }
        return this.metrics[name];
    }
}

type LabelValues<T extends string> = Partial<Record<T, string | number>>;

export interface CounterInternal {
    /**
     * Increment with value
     * @param value The value to increment with
     */
    inc(value?: number): void;
}

export type MetricsInternalMap<T extends Record<string, Counter>, K = keyof T> = {
    [Prop in keyof T]: CounterInternal;
}

export function presetLabels<T extends Record<string, Counter<L>>, L extends string>(labels: LabelValues<L>, metrics: T): MetricsInternalMap<T> {
    return Object.fromEntries(Object.entries(metrics).map(([key, val]) => {
        return [key, val.labels(labels)];
    })) as MetricsInternalMap<T>;
}

/**
 * A counter is a cumulative metric that represents a single numerical value that only ever goes up
 */
export class Counter<T extends string = string> {

    constructor(protected values: ValuesRecord, public name: string) {
    }

    inc(value?: number) {
        this.increase("", value || 1);
    }

    labels(labels: LabelValues<T>): CounterInternal {
        let serialized = Object.entries(labels).map(entry => entry.join(":")).join(",");
        return {
            inc: (value?: number) => {
                this.increase(serialized, value || 1, labels);
            },
        };
    }

    protected increase(serializedLabel: string, value: number, labels?: LabelValues<T>) {
        let key = this.name + "!" + serializedLabel;
        let entry = this.values[key];
        if (!entry) {
            entry = {value, labels};
            this.values[key] = entry;
        }

        entry.value += value;
    }
}
