import { ExtrasProvider } from "./ExtrasProvider";
import { DeployedExtraProcessor } from "./DeployedExtra";
import { LottoExtraProcessor } from "./LottoExtra";
import { TransferExtraProcessor } from "./TransferExtra";
import { UniswapExtraProcessor } from "./UniswapExtra";
import { ExecutorParameters } from "../executor";
import { DumbappSubmission } from "../submission";
import { DumbappExtras } from "../schema";

export * from "./DeployedExtra";
export * from "./DumbappExtraProcessor";
export * from "./ExtrasProvider";
export * from "./LottoExtra";
export * from "./TransferExtra";
export * from "./UniswapExtra";
export * from "./util";

export const defaultExtrasProvider = new ExtrasProvider();

defaultExtrasProvider
    .addExtra(new DeployedExtraProcessor())
    .addExtra(new LottoExtraProcessor())
    .addExtra(new TransferExtraProcessor())
    .addExtra(new UniswapExtraProcessor());

export async function addExtras(parameters: ExecutorParameters, submission: DumbappSubmission) {
    let ret: DumbappExtras = {};
    let existing = submission.data.extras || {};

    for (let extra of defaultExtrasProvider.get()) {
        if (existing[extra.key]) {
            continue;
        }
        let value = await extra.process(parameters, submission);
        if (value && value.length > 0) {
            ret[extra.key] = value;
        }
    }
    return ret;
}
