import { Mutex } from "async-mutex";
import { MutexMap } from "./MutexMap";

export interface TimedEntry<T> {
    data: T;
    t: Date;

    /**
     * Force expire this cache entry after this amount of time, even if the
     * duration for the cache hasn't passed.
     */
    exp?: number;
}

export interface Cache<DefaultType = any> {
    getItem<T = DefaultType>(key: string, updateTime?: boolean, overrideDuration?: number): Promise<TimedEntry<T>>;
    setItem<T = DefaultType>(key: string, data: T, forceExpire?: number): Promise<void>;
    removeItem(key: string): Promise<void>;
    clean(age: number): Promise<void>;
    withCache<T>(key: string, loader: () => Promise<T>, mutexKey?: string | boolean, overrideDuration?: number): Promise<T>;
}

export abstract class BaseCache<DefaultType = any> extends MutexMap implements Cache<DefaultType> {

    protected constructor(public readonly cacheDuration: number) {
        super();
    }

    async getItem<T = DefaultType>(key: string, updateTime?: boolean, overrideDuration?: number): Promise<TimedEntry<T>> {
        let item: TimedEntry<T> = await this.read(key);

        let duration: number;
        if (item?.exp) {
            duration = item.exp;
        } else if (overrideDuration) {
            duration = overrideDuration;
        } else {
            duration = this.cacheDuration;
        }

        if (duration > 0 && item && Date.now() - item.t.getTime() > duration) {
            await this.removeItem(key);
            return null;
        }

        if (updateTime && item) {
            let entry: TimedEntry<T> = {
                data: item.data,
                t: new Date(),
            };
            if (item.exp) {
                entry.exp = item.exp;
            }
            await this.write(key, entry);
        }

        return item;
    }
    async setItem<T = DefaultType>(key: string, data: T, forceExpire?: number)  {
        let entry: TimedEntry<T> = {
            data,
            t: new Date(),
        };
        if (forceExpire) {
            entry.exp = forceExpire;
        }
        await this.write(key, entry);
    }

    abstract removeItem(key: string): Promise<void>;

    protected abstract read<T = DefaultType>(key: string): Promise<TimedEntry<T>>;
    protected abstract write<T = DefaultType>(key: string, entry: TimedEntry<T>): Promise<void>;

    async clean(age: number) {};

    /**
     * @param key Cache key for the data.
     * @param loader Function that loads the data.
     * @param mutexKey If provided, a mutex is used, keyed by this value. If `true`, the first argument `key` is used as the mutex key.
     * @param overrideDuration If provided, overrides the default cache duration
     */
    async withCache<T>(key: string, loader: () => Promise<T>, mutexKey?: string | boolean, overrideDuration?: number): Promise<T> {
        let useMutexKey: string;
        if (mutexKey) {
            if (mutexKey === true) {
                useMutexKey = key;
            } else {
                useMutexKey = mutexKey;
            }
        }
        let release = await (useMutexKey ? this.getMutex(useMutexKey) : null)?.acquire();

        try {
            let item = await this.getItem<T>(key, false, overrideDuration);
            if (!item) {
                let data = await loader();
                await this.setItem<T>(key, data);
                return data;
            }

            return item.data;
        } finally {
            if (release) {
                release();
                this.clearMutex(useMutexKey);
            }
        }
    }
}
