import { DecoderData, fragmentEquals, jsonFragmentToBaseFragment } from "./DecoderData";
import { ExtendedJsonFragment } from "@blockwell/eth-types";
import { ErrorFragment, EventFragment, FunctionFragment, JsonFragment } from "@ethersproject/abi";
import { CustomInterface } from "./CustomCoder";

export class AbiDecoderData implements DecoderData {

    private interface = new CustomInterface([]);
    public methods: Record<string, ExtendedJsonFragment[]> = {};
    public events: Record<string, ExtendedJsonFragment[]> = {};
    public errors: Record<string, ExtendedJsonFragment[]> = {
        // Standard revert string encoding
        "08c379a0": [{
            type: "error",
            name: "Error",
            inputs: [{type: "string", name: "message"}]
        }]
    };

    constructor(abi?: JsonFragment[]) {
        if (abi) {
            this.addAbi(abi);
        }
    }

    addAbi(abi: JsonFragment[]) {
        // Iterate new abi to generate hashes
        for (let frag of abi) {
            this.addElement(jsonFragmentToBaseFragment(frag));
        }
    }

    addElement(element: ExtendedJsonFragment) {
        if (element.name) {
            let list: ExtendedJsonFragment[];

            if (element.type === "event") {
                let frag = EventFragment.from(element);
                element.signature = frag.format();
                element.hash = this.interface.getEventTopic(frag);
                list = this.events[element.hash.slice(2)];
                if (!list) {
                    list = [];
                    this.events[element.hash.slice(2)] = list;
                }
            } else if (element.type === "error") {
                let frag = ErrorFragment.from(element);
                element.signature = frag.format();
                element.hash = this.interface.getSighash(frag);
                list = this.errors[element.hash.slice(2, 10)];
                if (!list) {
                    list = [];
                    this.errors[element.hash.slice(2, 10)] = list;
                }
            } else {
                let frag = FunctionFragment.from(element);
                element.signature = frag.format();
                element.hash = this.interface.getSighash(frag);
                list = this.methods[element.hash.slice(2, 10)];
                if (!list) {
                    list = [];
                    this.methods[element.hash.slice(2, 10)] = list;
                }
            }

            if (!list.find(it => fragmentEquals(it, element))) {
                list.push(element);
            }
        }
    }

    event(topic0: string) {
        return this.events[topic0];
    }

    func(hash: string) {
        return this.methods[hash];
    }

    error(hash: string) {
        return this.errors[hash];
    }

}
