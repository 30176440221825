/**
 * Provides values for dumbapp UI and execution based on ExecutionState.
 */
import { ExecutionState } from "./ExecutionState";
import defaultSourceProviders from "../source/SourceProviders";
import { Dumbapp } from "../Dumbapp";
import { DumbappValueFunction } from "../DumbappValueFunction";
import { Typing } from "@blockwell/eth-types";
import { DumbappArgument, DumbappSource, DumbappStep, DumbappValue } from "../schema";
import { formDataToValue, queryDataToValue } from "../data";
import { ArgumentValueProvider } from "./ArgumentValueProvider";

export class ExecutionStateValueProvider implements ArgumentValueProvider {
    constructor(
        protected currentState?: ExecutionState,
        protected providers = defaultSourceProviders
    ) {}

    set state(state: ExecutionState) {
        this.currentState = state;
    }

    get state(): Readonly<ExecutionState> {
        return this.currentState;
    }

    get dumbapp(): Dumbapp {
        return this.currentState?.dumbapp;
    }

    getAccount(): string {
        return this.currentState?.wallet?.account;
    }

    getArgValue(arg: DumbappArgument, step?: DumbappStep, argNum?: number): DumbappValueFunction {
        return this.getSourceValue(arg.value, arg, step);
    }

    getInput(name: string, typing?: Typing): DumbappValue {
        if (this.currentState) {
            for (let values of this.currentState.data) {
                if (values.data[name] !== undefined) {
                    switch (values.type) {
                        case "query":
                            return queryDataToValue(values.data[name], typing);
                        case "form":
                            return formDataToValue(values.data[name], typing);
                        default:
                            return values.data[name];
                    }
                }
            }
        }

        return undefined;
    }

    getSourceValue(
        source: DumbappSource,
        arg: DumbappArgument,
        step?: DumbappStep
    ): DumbappValueFunction {
        return this.providers.valueForSource(this, source, arg, step);
    }

    combineInputs() {
        let data: Record<string, DumbappValue> = {};

        if (this.currentState) {
            for (let values of this.currentState.data) {
                switch (values.type) {
                    case "query":
                        for (let [key, value] of Object.entries(values.data)) {
                            if (value !== undefined) {
                                data[key] = queryDataToValue(value);
                            }
                        }
                        break;
                    case "form":
                        for (let [key, value] of Object.entries(values.data)) {
                            if (value !== undefined) {
                                data[key] = formDataToValue(value);
                            }
                        }
                        break;
                    default:
                        for (let [key, value] of Object.entries(values.data)) {
                            if (value !== undefined) {
                                data[key] = value;
                            }
                        }
                        break;
                }
            }
        }
        return data;
    }
}
