import {
    DumbappConfig,
    DumbappData,
    DumbappDynamic, DumbappRequiresApproval,
    DumbappRequiresAsset,
    DumbappStep,
    DumbappWalletStrategy
} from "./schema";
import { v5 as uuidv5 } from "uuid";
import { clone } from "remeda";
import { isContractStep } from "./util";

export const submissionIdNamespace = "6415cdde-2700-46e5-b2ae-b133d2a4b9a6";

export type DumbappResponse = DumbappConfig & DumbappData;

export class Dumbapp implements DumbappConfig, DumbappData {
    static uuidData(shortcode: string, created: string, from: string): string {
        return shortcode + "@" + created + "@" + from;
    }

    static uuid(shortcode: string, created: string, from: string): string {
        // from is like that just in case an undefined or null gets passed
        return uuidv5(this.uuidData(shortcode, created, from), submissionIdNamespace).toString();
    }

    static uuidFromData(uuidData: string): string {
        return uuidv5(uuidData, submissionIdNamespace).toString();
    }

    confirmationMessage?: string;
    copiedFrom?: string;
    description?: string;
    dynamic: DumbappDynamic[];
    image: string;
    meta: { created?: string };
    qrId: number;
    requiresAsset?: DumbappRequiresAsset;
    shortcode: string;
    steps: DumbappStep[];
    title?: string;
    uniqueReference?: string;
    url: string;
    walletStrategy?: DumbappWalletStrategy;
    requiresApproval?: DumbappRequiresApproval;

    constructor(data: DumbappConfig & Partial<DumbappData>) {
        Object.assign(this, clone(data));

        if (this.steps?.length) {
            for (let i = 0; i < this.steps.length; i++) {
                this.steps[i].index = i;
                if (!this.steps[i].arguments) {
                    // Ensure it's an empty array if no arguments
                    this.steps[i].arguments = [];
                }
            }
        }

        // Remove invalid wallet strategy (if it's just an empty object)
        if (data.walletStrategy && !data.walletStrategy.type) {
            this.walletStrategy = null;
        }
    }

    getTitle(): string {
        if (this.title) {
            return this.title;
        }
        if (this.steps && this.steps.length > 0) {
            let step = this.steps[this.steps.length - 1];
            if (isContractStep(step)) {
                return step.method;
            }
        }
        return "";
    }
}
