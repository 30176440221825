import localforage from "localforage";
import { BaseCache, TimedEntry } from "./Cache";

export class LocalForageCache<DefaultType = any> extends BaseCache<DefaultType> {
    private store: LocalForage;

    constructor(storeName: string, cacheDuration: number = 0) {
        super(cacheDuration);
        this.store = localforage.createInstance({ name: storeName });
    }

    protected read<T>(key: string): Promise<TimedEntry<T>> {
        return this.store.getItem(key);
    }

    protected async write<T>(key: string, entry: TimedEntry<T>) {
        await this.store.setItem(key, entry);
    }

    async removeItem(key: string) {
        await this.store.removeItem(key);
    }

    async clean(age: number) {
        let keys = await this.store.keys();

        for (let key of keys) {
            let item = await this.store.getItem<TimedEntry<any>>(key);
            if (Date.now() - item.t.getTime() > age) {
                await this.store.removeItem(key);
            }
        }
    }
}
