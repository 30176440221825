import BigNumber from "bignumber.js";
import { DumbappDataProvider } from "./DumbappDataProvider";
import { DumbappArgument, DumbappFeeSourceParameters, DumbappStep } from "../schema";
import { ArgumentValueProvider, resolveBigNumberishSourcable } from "../state";
import { DumbappValueFunction } from "../DumbappValueFunction";
import { resolveBigNumberishValue } from "../state";

export class FeeSourceProvider implements DumbappDataProvider<DumbappFeeSourceParameters> {
    name = "fee";

    getValue(
        provider: ArgumentValueProvider,
        source: DumbappFeeSourceParameters,
        arg: DumbappArgument,
        step?: DumbappStep
    ): DumbappValueFunction {
        return {
            async value() {
                let base = await resolveBigNumberishSourcable(provider, [source.base], step);
                let multiplier = await resolveBigNumberishSourcable(
                    provider,
                    [source.multiplier, 1],
                    step
                );

                if (base) {
                    let decimals: string;

                    if (!source.ignoreDecimals) {
                        decimals = await resolveBigNumberishSourcable(
                            provider,
                            [arg?.decimals],
                            step
                        );
                    }

                    if (multiplier === null) {
                        multiplier = "1";
                    }

                    let res = new BigNumber(base).times(multiplier);

                    if (decimals) {
                        res = res.div(`1e${decimals}`);
                    }

                    return res.toString(10);
                }
                return "";
            },
        };
    }
}
