import {
    DumbappExtras,
    DumbappInputValues,
    DumbappStepArguments,
    DumbappSubmissionDataValues,
    DumbappValue
} from "../schema";
import { omit } from "remeda";
import { DumbappSubmissionStep } from "./DumbappSubmissionStep";
import { SubmissionStepCreator, SubmissionStepFactory } from "./SubmissionStepFactory";
import { formDataToValue } from "../data";

const defaultStepFactory = new SubmissionStepFactory();

export function addDefaultStepCreator(executor: string, creator: SubmissionStepCreator) {
    defaultStepFactory.addType(executor, creator);
}

export class DumbappSubmissionData implements DumbappSubmissionDataValues {
    args: DumbappStepArguments[];
    created: string;
    executor: string;
    extras?: DumbappExtras;
    from?: string;
    id: string;
    inputs: DumbappInputValues[];
    meta?: { created?: string };
    shortcode: string;
    input?: { [p: string]: DumbappValue };
    query?: { [p: string]: string };
    steps: DumbappSubmissionStep[];
    approval?: boolean;

    constructor(data: DumbappSubmissionDataValues) {
        Object.assign(this, omit(data, ["steps"]));

        this.steps = data.steps.map((it, index) =>
            defaultStepFactory.create(data.executor, it, index)
        );
    }

    completed() {
        return !this.steps.find((it) => !it.completed());
    }

    completedTime() {
        let step = this.steps[this.steps.length - 1];
        if (step.status === "completed") {
            return step.ended;
        }
        return null;
    }

    pending() {
        return !!this.steps.find((it) => it.pending());
    }

    pendingStep() {
        return this.steps.find(it => it.pending())
    }

    error() {
        let tx = this.steps.find((it) => !!it.error);
        if (tx) {
            return tx.error;
        }
        return null;
    }

    errorIndex() {
        return this.steps.findIndex((it) => !!it.error);
    }

    pendingConfirmation() {
        return !!this.steps.find((it) => it.pendingConfirmation());
    }

    status() {
        let pending = this.steps.find(it => it.pending());
        if (pending) {
            return pending.status;
        }
        let last = this.steps[this.steps.length - 1];
        return last?.status || "unknown";
    }

    getInput(name: string) {
        for (let input of this.inputs) {
            if (input.data[name]) {
                switch (input.type) {
                    case "form":
                        return formDataToValue(input.data[name]);
                    default:
                        return input.data[name];
                }
            }
        }
        return null;
    }

    toString(): string {
        return this.status();
    }
}
