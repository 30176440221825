import { EthNetwork } from "@blockwell/chains";
import { ExternalProvider, JsonRpcProvider } from "@ethersproject/providers";
import { StaticJsonRpcBatchProvider } from "./StaticJsonRpcBatchProvider";
import { EthcallProvider } from "@blockwell/ethcall";
import { ReconnectingWebSocketProvider } from "./websocket";
import { Counter, presetLabels } from "./Metrics";
import { RpcProvider } from "./RpcProvider";
import { ExtProvider } from "./ExtProvider";

export abstract class ProviderFactory {
    private providerCache: Record<
        number,
        {
            provider: JsonRpcProvider;
            ethcall: EthcallProvider;
        }
    > = {};
    private wsCache: Record<
        number,
        {
            provider: ReconnectingWebSocketProvider;
            ethcall?: EthcallProvider;
        }
    > = {};

    public metrics: {
        wsKeepalive?: Counter<"chainId">;
        wsOpen?: Counter<"chainId">;
        wsReconnect?: Counter<"chainId">;
        wsClose?: Counter<"chainId">;
        requests?: Counter<"chainId">;
        rpcBatches?: Counter<"chainId">;
        rpcBatchRequests?: Counter<"chainId">;
    } = {};

    async getProvider(input: EthNetwork | ExternalProvider) {
        if ("decimals" in input) {
            return (await this.getEthcallProvider(input)).provider;
        }
        return new ExtProvider(input);
    }

    async getEthcallProvider(input: EthNetwork) {
        let ws = this.wsCache[input.chainId];
        // Use websocket if it's available
        if (ws) {
            if (!ws.ethcall) {
                ws.ethcall = new EthcallProvider();
                ws.ethcall.initChain(ws.provider, input.chainId);
            }
            return ws;
        }

        let cached = this.providerCache[input.chainId];
        if (!cached) {
            let provider = this.providerForChain(input);
            let ethcall: EthcallProvider;
            if (provider) {
                ethcall = new EthcallProvider();
                await ethcall.init(provider);

                // If another thread already added it
                if (this.providerCache[input.chainId]) {
                    return this.providerCache[input.chainId];
                }
            }
            cached = {
                provider,
                ethcall,
            };
            this.providerCache[input.chainId] = cached;
        }

        return cached;
    }

    websocketForChain(input: EthNetwork): ReconnectingWebSocketProvider {
        let cached = this.wsCache[input.chainId];
        if (cached === undefined) {
            let url = input.getWsUrl();

            if (url) {
                let provider = new ReconnectingWebSocketProvider(url, input.chainId);

                let metrics = presetLabels({ chainId: input.chainId }, this.metrics);

                provider.on("debug", (event: { action: string; provider: JsonRpcProvider }) => {
                    switch (event.action) {
                        case "open":
                            metrics.wsOpen?.inc();
                            break;
                        case "close":
                            metrics.wsClose?.inc();
                            break;
                        case "keepalive":
                            metrics.wsKeepalive?.inc();
                            break;
                        case "reconnect":
                            metrics.wsReconnect?.inc();
                            break;
                        case "request":
                            metrics.requests?.inc();
                            break;
                    }
                });
                cached = { provider };
            } else {
                cached = null;
            }

            this.wsCache[input.chainId] = cached;
        }
        return cached?.provider;
    }

    close(input: EthNetwork) {
        let cached = this.wsCache[input.chainId];
        if (cached) {
            delete this.wsCache[input.chainId];
            // Allow some time for code that already has a reference to this provider
            // to make its requests.
            setTimeout(() => {
                cached.provider?.websocket?.close(1000);

                // Delay removing listeners so we can record metrics for close
                setTimeout(() => {
                    cached.provider?.removeAllListeners();
                }, 2000);
            }, 2000);
        } else {
            let cached = this.providerCache[input.chainId];
            if (cached?.provider) {
                cached.provider.polling = false;
                cached.provider?.removeAllListeners();
            }
        }
    }

    closeAll() {
        for (let val of Object.values(this.wsCache)) {
            val.provider?.websocket?.close(1000);
            val.provider?.removeAllListeners();
        }
        this.wsCache = {};
        for (let val of Object.values(this.providerCache)) {
            if (val?.provider) {
                val.provider.polling = false;
                val.provider?.removeAllListeners();
            }
        }
        this.providerCache = {};
    }

    abstract providerForChain(input: EthNetwork): JsonRpcProvider;
}

export class StandardProviderFactory extends ProviderFactory {
    providerForChain(input: EthNetwork): JsonRpcProvider {
        let url = input.getNodeUrl();
        if (url) {
            let provider = new RpcProvider(input.getNodeUrl(), input.chainId);

            let metrics = presetLabels({ chainId: input.chainId }, this.metrics);

            provider.on("debug", (event: { action: string }) => {
                switch (event.action) {
                    case "request":
                        metrics.requests?.inc();
                        break;
                }
            });

            return provider;
        }
        return null;
    }
}

export class BatchingProviderFactory extends ProviderFactory {
    providerForChain(input: EthNetwork): JsonRpcProvider {
        let url = input.getNodeUrl();
        if (url) {
            let provider = new StaticJsonRpcBatchProvider(input.getNodeUrl(), input.chainId);

            let metrics = presetLabels({ chainId: input.chainId }, this.metrics);

            provider.on("debug", (event: { action: string; request?: any[] }) => {
                switch (event.action) {
                    case "requestBatch":
                        metrics.rpcBatches?.inc();
                        metrics.rpcBatchRequests?.inc(event.request?.length);
                        metrics.requests?.inc(event.request?.length);
                        break;
                }
            });

            return provider;
        }
        return null;
    }
}

export const standardProviderFactory = new StandardProviderFactory();
export const batchingProviderFactory = new BatchingProviderFactory();
