export interface BwTypeData {
    name: string;
    happ?: string;
    features: string[];
}

const bwTypeMap: Record<string | number, BwTypeData> = {
    "1": { name: "PrimeToken", features: ["erc20", "suggestions"], happ: "suggestions" },
    "2": { name: "PrideToken", features: ["erc20"] },
    "3": { name: "FoodCoin", features: ["erc20"] },
    "4": { name: "EgoCoin", features: ["erc20"], happ: "suggestions" },
    "5": { name: "EgoCoin", features: ["erc20"], happ: "suggestions" },
    "6": { name: "TrainerToken", features: ["erc20"] },
    "7": { name: "Daico", features: ["daico"], happ: "daico" },
    "8": { name: "ItemDrops", features: ["lotto"], happ: "lotto" },
    "9": { name: "ItemToken", features: ["erc20"] },
    "10": {
        name: "CommunityToken",
        features: ["erc20", "suggestions", "proposals"],
        happ: "suggestions",
    },
    "11": { name: "PaymentRelay", features: [] },
    "12": { name: "GhostToken", features: ["erc20"] },
    "13": { name: "ForumToken", features: ["erc721", "suggestions", "proposals"], happ: "nft" },
    "14": { name: "BaseBook", features: ["book"], happ: "book" },
    "15": { name: "VotingBook", features: [], happ: "book" },
    "16": { name: "Refunds", features: ["refunds"], happ: "refunds" },
    "17": { name: "SmartLicense", features: ["smart-license"], happ: "smart" },
    "18": { name: "FireToken", features: ["erc20"] },
    "19": { name: "CoreToken", features: ["erc20", "suggestions", "core"], happ: "core" },
    "20": { name: "TasksExtension", features: [], happ: "core" },
    "21": { name: "Prices", features: [] },
    "22": { name: "TasksLibrary", features: [] },
    "23": { name: "FreelanceExtension", features: [], happ: "core" },
    "24": { name: "FreelanceLibrary", features: [] },
    "25": { name: "Hourglass", features: ["erc20", "hourglass"], happ: "hourglass" },
    "26": { name: "NfToken", features: ["erc721"], happ: "nft" },
    "27": { name: "PartialNft", features: ["erc721"], happ: "nft" },
    "28": { name: "FuelToken", features: ["erc20", "stake"] },
    "29": { name: "Swapper", features: ["swapper"] },
    "30": { name: "SwapRelay", features: [] },
    "31": { name: "NftItemPool", features: ["item-pool"] },
    "32": { name: "SwapRelayV1", features: [] },
    "33": { name: "SmartRelay", features: ["smart-relay"] },
    "34": { name: "SwapNftRelay", features: ["nft-swap"] },
    "35": { name: "GameNft", features: ["erc721"] },
    "36": { name: "NftMarket", features: ["market"] },
    "37": { name: "SignedSwapNftRelay", features: [] },
    "50": { name: "PrimeDeployer", features: ["deployer"] },
    "51": { name: "DaicoDeployer", features: ["deployer"] },
    "52": { name: "PrimeGiver", features: [] },
    "53": { name: "ForumDeployer", features: ["deployer"] },
    "54": { name: "CommunityDeployer", features: ["deployer"] },
    "55": { name: "ItemDropsDeployer", features: ["deployer"] },
    "56": { name: "BookDeployer", features: ["deployer"] },
    "57": { name: "SmartLicenseDeployer", features: ["deployer"] },
    "58": { name: "HourglassDeployer", features: ["deployer"] },
    "59": { name: "NftDeployer", features: ["deployer"] },
    "100": { name: "ProxyToken", features: ["erc20"] },
    "101": { name: "ProxyTokenDeployer", features: ["deployer"] },
    "102": { name: "ProxyDeployer", features: ["deployer"] },
    "103": { name: "ProxySwapper", features: [] },
    "104": { name: "CrosschainToken", features: ["erc20"] },
    "105": { name: "CrosschainDeployer", features: ["deployer"] },
    "106": { name: "RefundsDeployer", features: ["deployer"] },
    "107": { name: "SwapperDeployer", features: ["deployer"] },
};
export { bwTypeMap };
