import {
    ArgumentValueProvider,
    ExecutionState,
    ExecutionStateValueProvider,
    PreExecuteHandler,
    resolveAddressSourcable,
    resolveBigNumberishSourcable,
    resolveBooleanSourcable,
} from "../state";
import {
    DumbappApprovalRequest,
    DumbappRequiresApproval,
    DumbappStep,
    Erc20ApprovalParameters,
    Erc721AllApprovalParameters,
    Erc721ApprovalParameters,
} from "../schema";
import { addressEqual, toChainId } from "@blockwell/chains";
import { createCallSource, createLiteralSource, createStepsSource } from "../source-util";
import { CachedContractsApi, ContractData } from "@blockwell/apiminer-client";
import BigNumber from "bignumber.js";
import { Typing } from "@blockwell/eth-types";

type Handler<T extends DumbappApprovalRequest> = {
    type: T["type"];
    checkApproval: (
        values: ArgumentValueProvider,
        requires: DumbappRequiresApproval,
        step: DumbappStep,
        token: ContractData,
        account: string,
        spender: string
    ) => Promise<T>;
};

const erc20Handler: Handler<Erc20ApprovalParameters> = {
    type: "erc20",
    async checkApproval(
        values: ArgumentValueProvider,
        requires: DumbappRequiresApproval,
        step: DumbappStep,
        token: ContractData,
        account: string,
        spender: string
    ): Promise<Erc20ApprovalParameters> {
        let decimalsSource = createLiteralSource(token.parameters.decimals);
        const [allowance, whenBelow, value] = await Promise.all([
            resolveBigNumberishSourcable(
                values,
                [
                    createCallSource("allowance", createLiteralSource(token.address), [
                        account,
                        spender,
                    ]),
                ],
                step, Typing.uint, decimalsSource
            ).then((res) => new BigNumber(res)),
            resolveBigNumberishSourcable(values, [requires.whenBelow], step, Typing.uint, decimalsSource),
            resolveBigNumberishSourcable(values, [requires.value], step, Typing.uint, decimalsSource)
        ]);

        console.log("allowance", allowance.toString(10));

        if (whenBelow) {
            if (allowance.gte(whenBelow)) {
                return null;
            }
        } else if (allowance.gte(value)) {
            return null;
        }

        return {
            type: "erc20",
            chainId: toChainId(token.network),
            contractAddress: token.address,
            spender,
            value,
            account
        };
    },
};

const erc721AllHandler: Handler<Erc721AllApprovalParameters> = {
    type: "erc721-all",
    async checkApproval(
        values: ArgumentValueProvider,
        requires: DumbappRequiresApproval,
        step: DumbappStep,
        token: ContractData,
        account: string,
        spender: string
    ): Promise<Erc721AllApprovalParameters> {
        const approved = await resolveBooleanSourcable(
            values,
            [
                createCallSource("isApprovedForAll", createLiteralSource(token.address), [
                    account,
                    spender,
                ]),
            ],
            step
        );

        console.log("result for isApprovedForAll", token.address, account, spender, approved);

        if (approved === true) {
            return null;
        }

        return {
            type: "erc721-all",
            chainId: toChainId(token.network),
            contractAddress: token.address,
            spender,
            account
        };
    },
};

const erc721Handler: Handler<Erc721ApprovalParameters> = {
    type: "erc721",
    async checkApproval(
        values: ArgumentValueProvider,
        requires: DumbappRequiresApproval,
        step: DumbappStep,
        token: ContractData,
        account: string,
        spender: string
    ): Promise<Erc721ApprovalParameters> {
        const tokenId = await resolveBigNumberishSourcable(values, [requires.value], step);
        const operator = await resolveAddressSourcable(
            values,
            [
                createCallSource("getApproved", createLiteralSource(token.address), [
                    tokenId
                ]),
            ],
            step
        );

        if (addressEqual(operator, spender)) {
            return null;
        }

        return {
            type: "erc721",
            chainId: toChainId(token.network),
            contractAddress: token.address,
            spender,
            tokenId,
            account
        };
    },
};

const stepSource = createStepsSource(1, "address");

export class ApprovalHandler {
    private api: CachedContractsApi = null;

    setApi(api: CachedContractsApi) {
        this.api = api;
    }

    readonly handler: PreExecuteHandler = async (state, args) => {
        const dumbapp = state.dumbapp;
        const requires = dumbapp.requiresApproval;

        if (requires) {
            const contract = args[0].contract;

            if (!contract) {
                return {
                    success: false,
                    code: "no_contract",
                    message:
                        "Could not find a contract for checking approvals, some input is probably missing.",
                };
            }

            const chainId = args[0].chainId;
            const values = new ExecutionStateValueProvider(state);
            const step = dumbapp.steps[0];

            const [token, spender] = await Promise.all([
                resolveAddressSourcable(values, [requires.token], dumbapp.steps[0]).then(
                    (address) =>
                        this.api.getContract({
                            chainId,
                            address,
                        })
                ),
                resolveAddressSourcable(
                    values,
                    [requires.spender, stepSource],
                    step
                )
            ]);

            if (!token) {
                return {
                    success: false,
                    code: "no_approval_token",
                    message: "Could not find a token to check approval against.",
                };
            }

            if (!spender) {
                return {
                    success: false,
                    code: "no_approval_spender",
                    message: "Could not find a spender contract for approving.",
                };
            }

            let handler: Handler<DumbappApprovalRequest>;

            if (token.features.includes("erc20")) {
                console.log("using erc20 handler");
                handler = erc20Handler;
            } else if (token.features.includes("erc721")) {
                if (requires.value) {
                    console.log("using erc721 handler");
                    handler = erc721Handler;
                } else {
                    console.log("using erc721-all handler");
                    handler = erc721AllHandler;
                }
            }

            if (!handler) {
                return {
                    success: false,
                    code: "no_approval_handler",
                    message:
                        "No handler found for approving tokens, there may be a problem with the app configuration.",
                };
            }

            let approve = await handler.checkApproval(values, requires, step, token, state.wallet.account, spender);
            let data: Partial<ExecutionState> = {
                approve,
                approvalContract: token
            }

            return {
                success: true,
                data
            }
        }

        return {
            success: true,
            data: {},
        };
    };
}
