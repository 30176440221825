import { SourceProviders } from "../source";
import { Dumbapp } from "../Dumbapp";
import { DumbappValueFunction } from "../DumbappValueFunction";
import { DumbappArgument, DumbappSource, DumbappStep, DumbappValue } from "../schema";
import { convertFromValue } from "../state";
import { TypeInfo } from "@blockwell/eth-types";
import { formDataToValue, queryDataToValue } from "../data";
import { ArgumentValueProvider } from "../state";
import { DumbappSubmission } from "./DumbappSubmission";
import { StepsSourceProvider } from "../source";
import { CallSourceProvider } from "../source";
import { DynamicSourceProvider } from "../source";
import { LiteralSourceProvider } from "../source/LiteralSourceProvider";

const sourceProviders = new SourceProviders()
    .addSourceType(new LiteralSourceProvider())
    .addSourceType(new DynamicSourceProvider())
    .addSourceType(new CallSourceProvider())
    .addSourceType(new StepsSourceProvider());

export class SubmissionValueProvider implements ArgumentValueProvider {
    constructor(private submission?: DumbappSubmission, private providers = sourceProviders) {}

    setSubmission(submission: DumbappSubmission) {
        this.submission = submission;
    }

    get dumbapp(): Dumbapp {
        return this.submission?.dumbapp;
    }

    getAccount(): string {
        return this.submission?.data?.from;
    }

    getArgValue(arg: DumbappArgument, step?: DumbappStep, argNum?: number): DumbappValueFunction {
        if (this.submission) {
            const submittedArgs = this.submission.data.args[step.index];
            let val: DumbappValue;
            if (submittedArgs) {
                if (argNum !== null && argNum !== undefined) {
                    val = submittedArgs.args[argNum];
                } else if (arg.value) {
                    return this.getSourceValue(arg.value, arg, step);
                }

                if (val !== undefined) {
                    let provider: ArgumentValueProvider = this;
                    return {
                        async value() {
                            let converted = await convertFromValue(provider, val, arg, step);
                            return converted;
                        },
                    };
                }
            } else if (this.submission.dumbapp && arg.value) {
                return this.getSourceValue(arg.value, arg, step);
            }
        }

        return {
            value() {
                return "";
            },
        };
    }

    getInput(name: string, typing?: TypeInfo): DumbappValue {
        if (this.submission) {
            for (let values of this.submission.data.inputs) {
                if (values.data[name] !== undefined) {
                    if (values.type === "query") {
                        return queryDataToValue(values.data[name], typing);
                    } else if (values.type === "form") {
                        return formDataToValue(values.data[name], typing);
                    } else {
                        return values.data[name];
                    }
                }
            }
        }

        return undefined;
    }

    getSourceValue(
        source: DumbappSource,
        arg: DumbappArgument,
        step?: DumbappStep
    ): DumbappValueFunction {
        return this.providers.valueForSource(this, source, arg, step);
    }
}
