import ApiGroup from './ApiGroup';
import { EthLog } from "@blockwell/eth-types";
import { ChainReference, toChainId } from "@blockwell/chains";

export interface ApiMinerEvent {
    address: string;
    topics: string[];
    data: string;
    transactionHash: string;
    transactionIndex: number;
    logIndex: number;
    blockNumber: number;
    returnValues: Record<string, string | string[]>;
    event?: string;
}

export interface ApiMinerWaitForAsset {
    status: "waiting" | "completed" | "failed";
    address: string;
    value: string;
    waitedCount: number;
}

export interface ApiMinerTransaction {
    id: string;
    type?: "transaction" | "contract-method" | "contract-deploy";
    contractId?: string;
    from?: string;
    to?: string;
    method?: string;
    parameters?: (string | string[])[];
    status: "new" | "nonce" | "submitted" | "completed" | "error";
    created: string;
    submitted?: string;
    ended?: string;
    error?: {
        code: string;
        message: string;
        data?: Record<string, string | number>;
    };
    transactionHash?: string;
    events?: ApiMinerEvent[];
    contractName?: string;
    contractAddress?: string;
    contractNetwork?: number;
    contractBwtype?: number;
    contractBwver?: number;
    value?: string;
    network: string;
    nonce?: number;
    gasLimit?: number;
    blockNumber?: number;
    dependency?: string;
    waitForAsset?: ApiMinerWaitForAsset;
}

export interface SendOptions {
    from?: string;
    dependency?: string;
    value?: string;
    waitForAsset?: string;
    waitForValue?: string;
}

export interface ApiMinerRawTransaction {
    transaction: {
        blockHash: string;
        blockNumber: number;
        from: string;
        gas: number;
        gasPrice: string;
        hash: string;
        input: string;
        nonce: number;
        to?: string;
        value: string;
        type: string;
        transactionIndex: number;
    },
    receipt: {
        blockHash: string;
        blockNumber: number;
        contractAddress?: string;
        cumulativeGasUsed: number;
        from: string;
        gasUsed: number;
        logs: EthLog[];
        logsBloom: string;
        status: boolean;
        to?: string;
        transactionHash: string;
        events?: ApiMinerEvent[];
        transactionIndex: number;
        type: string;
    }
}

class Transactions extends ApiGroup {

    /**
     * Creates a new API Miner transaction, typically ether transfer.
     */
    create(network: string, to: string, value: string, opt: SendOptions = {}) {
        let data = Object.assign({}, opt, {
            network,
            to,
            value
        });

        return this.request<ApiMinerTransaction>({
            method: 'post',
            url: '/transactions',
            data
        }).then(response => response.data.data);
    }

    /**
     * List Transactions belonging to the current user.
     */
    list() {
        return this.request<ApiMinerTransaction[]>({
            url: '/transactions'
        }).then(response => response.data.data);
    }

    /**
     * Get a specific Transaction by its ID.
     */
    get(id: string) {
        return this.request<ApiMinerTransaction>({
            url: `/transactions/${id}`
        }).then(response => response.data.data);
    }

    /**
     * Gets a transaction's status including its hash, polling the server if necessary.
     */
    async getHash(id: string, timeout = 20000) {
        let active = true;

        // 20 second timeout to get a transaction hash
        let t = setTimeout(() => active = false, timeout);

        while (active) {
            let tx = await this.get(id);

            if (tx.transactionHash) {
                clearTimeout(t);
                return tx;
            }
            if (tx.status === 'error') {
                clearTimeout(t);
                throw new Error(tx.error?.message || ("Unknown error occurred with transaction " + id));
            }
        }

        throw new Error("getHash timed out for transaction " + id);
    }

    getByHash(network: ChainReference, hash: string) {
        return this.request<ApiMinerRawTransaction>({
            url: `/transactions/hash/${toChainId(network)}/${hash}`
        }).then(response => response.data.data);
    }
}

export default Transactions;
