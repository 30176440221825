import BigNumber from "bignumber.js";
import { isBigNumberish } from "@ethersproject/bignumber/lib/bignumber";
import { Call, CALL_ERROR, EthcallContract } from "@blockwell/ethcall";
import { JsonFragment } from "@ethersproject/abi";
import { ChainReference, EthNetwork, getChain } from "@blockwell/chains";
import {
    ChainResponse,
    isArrayResponse,
    isPrimitiveResponse,
    isStructResponse,
} from "./ChainResponse";
import { ChainClient } from "./ChainClient";
import { ContractData } from "@blockwell/apiminer";

export interface BatcherCall extends Call {
    cache?: number;
}

type PropertyMapping = {
    bignumber: BigNumber;
    int: number;
    string: string;
    boolean: boolean;
};
type SinglePropertyType =
    | "bignumber"
    | "int"
    | "string"
    | "boolean"
    | "struct"
    | "struct[]"
    | "null";
type PropertyType = SinglePropertyType | "list" | "objectlist";
type ArgType =
    | string
    | number
    | boolean
    | ArgType[]
    | {
          [k: string]: ArgType;
      };

interface ListProperty {
    method?: string;
    type?: PropertyType;
    /**
     * Process the value and set parameters on the object passed as the second argument.
     */
    convert?: (value: ChainResponse, it: Object, args: ArgType[]) => void;
    args?: (string | true)[];
    argsList?: ArgType[][];
    value?: string | number | boolean | BigNumber | ((val?: any) => any);
    valueList?: (string | number | boolean | BigNumber)[];
}

export interface ListOptions {
    args?: (string | true)[];
    argsList?: string[][];
    start?: number;
}

type Mapping = {
    key: string;
    count?: number;
    props?: { [key: string]: ListProperty };
    type?: PropertyType;
    items?: SinglePropertyType;
    args?: ArgType[][];
    start?: number;
    itemType?: new () => any;
    sub?: number;
    callback?: BatcherCallback;
    convert?: BatcherConvert;
    softFailed?: boolean;
};

export type BatcherConvert<T = any> = (value: ChainResponse, index: number) => T;
type BatcherTypeName = keyof PropertyMapping;

type BatcherType<T> = BatcherConvert<T> | BatcherTypeName;
type BatcherReturnType<T, BT extends BatcherType<T>> = BT extends BatcherTypeName
    ? PropertyMapping[BT]
    : T;

export type BatcherCallback = (value: any) => void;

type RefinedBatcher<T, Res, M extends string, N extends string | false> = Batcher<
    Res & { [Prop in N extends false ? M : N]: T }
>;
type RefinedListBatcher<T, Res, M extends string, N extends string | false> = Batcher<
    Res & { [Prop in N extends false ? M : N]: T[] }
>;
type ParsedValue =
    | string
    | number
    | BigNumber
    | boolean
    | { [key: string]: ParsedValue }
    | ParsedValue[];

export class Batcher<Res extends Record<string, any> = {}> {
    protected mapping: Mapping[] = [];
    protected contract?: EthcallContract;
    protected batch: (BatcherCall | string)[] = [];

    public chain: EthNetwork;

    constructor(network: ChainReference, public client: ChainClient) {
        this.chain = getChain(network);
    }

    setContract(abiOrContract: EthcallContract | JsonFragment[] | ContractData, address?: string) {
        if ("functions" in abiOrContract) {
            this.contract = abiOrContract;
        } else if ("abi" in abiOrContract) {
            this.contract = new EthcallContract(abiOrContract.address, abiOrContract.abi);
        } else if (Array.isArray(abiOrContract)) {
            this.contract = new EthcallContract(address, abiOrContract);
        }
        return this;
    }

    add<M extends string, N extends string | false = false>(
        method: M,
        name?: N,
        args: ArgType[] = [],
        callback?: BatcherCallback
    ): RefinedBatcher<string, Res, M, N> {
        this._add(method, name, args, "string", callback);
        return this;
    }

    try<M extends string, N extends string | false = false>(
        method: M,
        name?: N,
        args: ArgType[] = [],
        callback?: BatcherCallback,
        cache?: number
    ): RefinedBatcher<string, Res, M, N> {
        this._add(method, name, args, "string", callback, true, cache);
        return this;
    }

    tryAny<M extends string, N extends string | false = false>(
        method: M,
        name?: N,
        args: ArgType[] = [],
        callback?: BatcherCallback,
        cache?: number
    ): RefinedBatcher<ChainResponse, Res, M, N> {
        this._add(method, name, args, "string", callback, true, cache);
        return this;
    }

    addInt<M extends string, N extends string | false = false>(
        method: M,
        name?: N,
        args: ArgType[] = [],
        callback?: BatcherCallback,
        cache?: number
    ): RefinedBatcher<number, Res, M, N> {
        this._add(method, name, args, "int", callback, false, cache);
        return this;
    }

    tryInt<M extends string, N extends string | false = false>(
        method: M,
        name?: N,
        args: ArgType[] = [],
        callback?: BatcherCallback,
        cache?: number
    ): RefinedBatcher<number, Res, M, N> {
        this._add(method, name, args, "int", callback, true, cache);
        return this;
    }

    tryBlockNumber<N extends string | false = false>(
        name?: N
    ): RefinedBatcher<number, Res, "blockNumber", N> {
        this._addToBatch("block");
        this._addMapping({
            key: name || "blockNumber",
            type: "int",
        });
        return this;
    }

    tryEthBalance<N extends string>(
        name: N,
        account: string
    ): RefinedBatcher<BigNumber, Res, "balance", N> {
        this._addToBatch(account);
        this._addMapping({
            key: name,
            type: "bignumber",
        });
        return this;
    }

    tryEthBalanceString<N extends string>(
        name: N,
        account: string
    ): RefinedBatcher<string, Res, "balance", N> {
        this._addToBatch(account);
        this._addMapping({
            key: name,
            type: "string",
        });
        return this;
    }

    addBig<M extends string, N extends string | false = false>(
        method: M,
        name?: N,
        args: ArgType[] = [],
        callback?: BatcherCallback,
        cache?: number
    ): RefinedBatcher<BigNumber, Res, M, N> {
        this._add(method, name, args, "bignumber", callback, false, cache);
        return this;
    }

    tryBig<M extends string, N extends string | false = false>(
        method: M,
        name?: N,
        args: ArgType[] = [],
        callback?: BatcherCallback,
        cache?: number
    ): RefinedBatcher<BigNumber, Res, M, N> {
        this._add(method, name, args, "bignumber", callback, true, cache);
        return this;
    }

    addBigArray<M extends string, N extends string | false = false>(
        method: M,
        name?: N,
        args: ArgType[] = [],
        callback?: BatcherCallback,
        cache?: number
    ): RefinedBatcher<BigNumber[], Res, M, N> {
        this._add(method, name, args, "bignumber", callback, false, cache);
        return this;
    }

    addBoolean<M extends string, N extends string | false = false>(
        method: M,
        name?: N,
        args: ArgType[] = [],
        callback?: BatcherCallback,
        cache?: number
    ): RefinedBatcher<boolean, Res, M, N> {
        this._add(method, name, args, "boolean", callback, false, cache);
        return this;
    }

    addStructArray<T, M extends string, N extends string | false = false>(
        method: M,
        name?: N,
        args: ArgType[] = [],
        callback?: BatcherCallback,
        cache?: number
    ): RefinedBatcher<T[], Res, M, N> {
        this._add(method, name, args, "struct[]", callback, false, cache);
        return this;
    }

    tryStructArray<T, M extends string, N extends string | false = false>(
        method: M,
        name?: N,
        args: ArgType[] = [],
        callback?: BatcherCallback,
        cache?: number
    ): RefinedBatcher<T[], Res, M, N> {
        this._add(method, name, args, "struct[]", callback, true, cache);
        return this;
    }

    addList<
        M extends string,
        C extends BatcherTypeName = "string",
        N extends string | false = false
    >(
        method: M,
        name?: N,
        count?: number,
        opt: ListOptions = {},
        convert?: C
    ): RefinedListBatcher<PropertyMapping[C], Res, M, N> {
        let start = opt.start || 0;
        let end = typeof count === "number" ? count : opt.argsList.length;
        let map: Mapping = {
            key: name || method,
            count: end,
            type: "list",
            start,
            items: convert || "string",
        };

        this.addListMapping(method, map, opt);

        return this;
    }

    addListConvert<M extends string, T, N extends string | false = false>(
        method: M,
        convert: BatcherConvert<T>,
        name?: N,
        count?: number,
        opt: ListOptions = {}
    ): RefinedListBatcher<T, Res, M, N> {
        let start = opt.start || 0;
        let end = typeof count === "number" ? count : opt.argsList.length;
        let map: Mapping = {
            key: name || method,
            count: end,
            type: "list",
            start,
            convert: convert,
        };

        this.addListMapping(method, map, opt);

        // @ts-ignore
        return this;
    }

    private addListMapping(method: string, map: Mapping, opt: ListOptions) {
        let calls = [];

        let argsPos = 0;
        for (let i = map.start; i < map.count; i++) {
            let args: ArgType[];
            if (opt.args) {
                args = [];
                for (let it of opt.args) {
                    if (it === true) {
                        args.push(i.toString());
                    } else {
                        args.push(it);
                    }
                }
            } else if (opt.argsList) {
                args = opt.argsList[argsPos++];
            } else {
                args = [i.toString()];
            }
            calls.push(this.contract[method](...args));
        }

        this._addCalls(calls, map);
    }

    addObjectList<N extends string>(
        name: N,
        count: number,
        props: { [key: string]: ListProperty },
        start = 0,
        itemType = Object
    ): RefinedListBatcher<any, Res, any, N> {
        let argsList: ArgType[][] = [];
        let map: Mapping = {
            key: name,
            count,
            props,
            type: "objectlist",
            args: argsList,
            start,
            itemType,
        };

        let calls = [];

        for (let [key, val] of Object.entries(props)) {
            if (val.method) {
                let argsPos = 0;

                for (let i = start; i < count; i++) {
                    let args: ArgType[];
                    if (val.argsList) {
                        args = val.argsList[argsPos++];
                    } else if (val.args) {
                        args = [];
                        for (let it of val.args) {
                            if (it === true) {
                                args.push(i.toString());
                            } else {
                                args.push(it);
                            }
                        }
                    } else {
                        args = [i.toString()];
                    }
                    argsList.push(args);
                    calls.push(this.contract[val.method](...args));
                }
            }
        }

        this._addCalls(calls, map);

        // @ts-ignore
        return this;
    }

    private _add(
        method: string,
        name: string | undefined | false,
        args: ArgType[] = [],
        type: PropertyType,
        callback?: BatcherCallback,
        softFail?: boolean,
        cache?: number
    ) {
        let map: Mapping = {
            type,
            callback,
            key: name || method,
        };
        if (!this.contract[method]) {
            if (softFail) {
                map.softFailed = true;
                this._addMapping(map);
            } else {
                throw new Error(`ABI does not have the method '${method}'`);
            }
        } else {
            let call: BatcherCall = this.contract[method](...args);
            if (cache) {
                call.cache = cache;
            }
            this._addCalls(call, map);
        }
    }

    private _addCalls(call: BatcherCall | BatcherCall[], map: Mapping) {
        if (Array.isArray(call)) {
            for (let it of call) {
                this._addToBatch(it);
            }
        } else {
            this._addToBatch(call);
        }
        this._addMapping(map);
    }

    private _addMapping(map: Mapping) {
        this.mapping.push(map);
    }

    private _addToBatch(call: BatcherCall | string) {
        this.batch.push(call);
    }

    calls() {
        return {
            chain: this.chain,
            batch: this.batch,
        };
    }

    finalize<T extends Res>(result: ChainResponse[], type?: new () => T): Res {
        let data: T;
        if (type) {
            data = new type();
        } else {
            data = {} as T;
        }
        if (this.mapping.length > 0) {
            return this._mapResponses(result, data);
        } else {
            return data;
        }
    }

    async execute<T extends Res>(type?: new () => T): Promise<Res> {
        let batch = this.batch;
        let res = await this.client.multicall(this.chain, batch);
        return this.finalize(res, type);
    }

    private _mapResponses<T>(responses: ChainResponse[], data: Res) {
        let res = [...responses];
        for (let map of this.mapping) {
            this._processMapping(res, data, map);
        }
        return data;
    }

    private _processMapping(res: ChainResponse[], data: Res, map: Mapping) {
        let { type, callback } = map;
        // @ts-ignore
        let key: keyof Res = map.key;

        if (data[key]) {
            throw new Error(`Batcher data already has the key ${key.toString()}`);
        }

        if (type === "objectlist") {
            let list: any[] = [];
            for (let i = map.start; i < map.count; i++) {
                let item = new map.itemType();
                item.id = i;
                list.push(item);
            }
            let argsPos = 0;
            for (let [key, val] of Object.entries(map.props)) {
                for (let j = 0; j < map.count - map.start; j++) {
                    if (val.method) {
                        let args = map.args[argsPos++];
                        if (val.convert) {
                            val.convert(res.shift(), list[j], args);
                        } else {
                            list[j][key] = this._parseType(res.shift(), val.type);
                        }
                    } else if (typeof val.value === "function") {
                        list[j][key] = val.value(list[j]);
                    } else if (val.valueList) {
                        list[j][key] = val.valueList[j];
                    } else {
                        list[j][key] = val.value;
                    }
                }
            }
            // @ts-ignore
            data[key] = list;
        } else if (type === "list") {
            let list = [];
            for (let j = map.start; j < map.count; j++) {
                let value = res.shift();
                if (map.convert) {
                    list.push(map.convert(value, j));
                } else {
                    list.push(this._parseType(value, map.items));
                }
            }
            // @ts-ignore
            data[key] = list;
        } else if (map.softFailed) {
            data[key] = null;
        } else {
            let parsed = this._parseType(res.shift(), type);
            // @ts-ignore
            data[key] = parsed;
            if (callback) {
                callback(data[key]);
            }
        }
    }

    private _parseType(val: ChainResponse, type: PropertyType): ParsedValue {
        if (val?.hasOwnProperty(CALL_ERROR)) {
            return val;
        }
        if (type === "struct") {
            if (isStructResponse(val)) {
                return this.parseStruct(val);
            }
            return null;
        }
        if (type === "struct[]") {
            if (isArrayResponse(val)) {
                return val.map((it) => {
                    if (isStructResponse(it)) {
                        return this.parseStruct(it);
                    }
                    return null;
                });
            }
            return null;
        }
        if (isArrayResponse(val)) {
            return val.map((it) => {
                return this._parseType(it, type);
            });
        }
        if (isPrimitiveResponse(val)) {
            return this._parseSingle(val, type);
        }
        if (isStructResponse(val)) {
            return this.parseStruct(val);
        }
        return null;
    }

    private parseStructValue(value: ChainResponse): ParsedValue {
        if (isPrimitiveResponse(value)) {
            return this._parseSingle(value);
        } else if (isStructResponse(value)) {
            return this.parseStruct(value);
        } else if (isArrayResponse(value)) {
            if (value.length === 0) {
                return [];
            } else {
                return value.map((it) => {
                    return this.parseStructValue(it);
                });
            }
        }
    }

    private parseStruct(val: { [p: string]: ChainResponse }) {
        let converted: Record<string, ParsedValue> = {};

        for (let [key, value] of Object.entries<ChainResponse>(val)) {
            if (!/^\d+$/.test(key)) {
                converted[key] = this.parseStructValue(value);
            }
        }

        return converted;
    }

    private _parseSingle(val: string | number | boolean, type?: PropertyType): ParsedValue {
        if (type === "string") {
            return val?.toString();
        }
        if (type === "int") {
            if (isBigNumberish(val)) {
                return new BigNumber(val).toNumber();
            } else {
                return NaN;
            }
        }
        if (type === "bignumber") {
            if (isBigNumberish(val)) {
                return new BigNumber(val);
            } else {
                return new BigNumber(NaN);
            }
        }
        if (type === "boolean") {
            return val === true || val === "true";
        }
        if (typeof val === "number" || typeof val === "boolean") {
            return val;
        }
        return val?.toString();
    }
}
