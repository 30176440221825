import { ExecutionController, ExecutionResultState, ExecutionStateHandler, InputHandler } from "./state";
import { InputController } from "./controller";
import { DumbappResult, DumbappSuccessResult } from "./executor";
import { DumbappCompleteEvent, DumbappErrorEvent, DumbappSubmitEvent, DumbappUpdateEvent, Wallet } from "./wallets";
import { Dumbapp } from "./Dumbapp";
import { DumbappSubmission, DumbappSubmissionStep } from "./submission";
import Emittery from "emittery";
import { DumbappApprovalRequest, DumbappStep, DumbappValues } from "./schema";
import { DumbappApi } from "./DumbappApi";
import { omit } from "remeda";

export type SimpleExecutorEvents = {
    'dumbapp-submit': DumbappSubmitEvent,
    'dumbapp-error': DumbappErrorEvent,
    'dumbapp-update': DumbappUpdateEvent,
    'dumbapp-complete': DumbappCompleteEvent,
    'pending-step': {step: DumbappStep, submissionStep: DumbappSubmissionStep, approval?: boolean}
};

export class SimpleExecutor {
    public readonly execution: ExecutionController;
    public readonly inputController: InputController;
    public readonly events: Emittery<SimpleExecutorEvents> = new Emittery();

    private result: DumbappSuccessResult;

    private resolve?: (result: DumbappSubmission) => void;
    private reject?: (error: Error) => void;

    constructor(
        public wallet: Wallet,
        public dumbapp: Dumbapp,
        public api: DumbappApi,
        public type?: string,
        inputHandler?: InputHandler
    ) {
        let controller = new InputController();
        controller.setApi(api.contracts);
        controller.update(dumbapp, wallet.getAccount());
        this.inputController = controller;
        this.execution = controller.execution;

        wallet.register(controller.execution);
        controller.execution.addHandler("SimpleExecutor", "preInput", this.preInputHandler);

        if (inputHandler) {
            controller.execution.addHandler("SimpleExecutor", "input", inputHandler);
        }
    }

    clean() {
        this.execution.removeHandler("SimpleExecutor");
        this.events.clearListeners();
    }

    on<K extends keyof SimpleExecutorEvents>(eventName: K, func: (data: SimpleExecutorEvents[K]) => void | Promise<void>) {
        this.events.on(eventName, func);
    }

    onAny(func: (eventName: keyof SimpleExecutorEvents, eventData: SimpleExecutorEvents[keyof SimpleExecutorEvents]) => void | Promise<void>) {
        this.events.onAny(func);
    }

    private emit<K extends keyof SimpleExecutorEvents, T extends SimpleExecutorEvents[K] = SimpleExecutorEvents[K]>(eventName: K, eventData: T) {
        this.events.emit(eventName, eventData);
    }

    private readonly preInputHandler: ExecutionStateHandler = async state => {
        this.emit("dumbapp-submit", {
            id: state.id,
            shortcode: state.dumbapp.shortcode,
            type: this.type,
            status: "new"
        })

        return {
            success: true,
            data: null
        }

    }

    async execute() {
        let res = await this.inputController.execution.execute();

        if (res.success === true) {
            return this.handleResult(res.data);
        } else {
            if (res.code === "approval") {
                let approval = await this.executeApproval(res.state.approve);

                if (approval instanceof DumbappSubmission) {
                    let res = await this.inputController.execution.execute();
                    if (res.success === true) {
                        return this.handleResult(res.data);
                    } else {
                        return res;
                    }
                }
            } else {
                return res;
            }
        }
    }

    private handleResult(data: ExecutionResultState) {
        this.result = {
            success: true,
            data: data.submission.data
        }
        setTimeout(() => {
            this.update().catch(console.error);
        }, 1000);

        return new Promise<DumbappSubmission>((resolve, reject) => {
            this.resolve = (result) => {
                this.reject = null;
                this.resolve = null;
                resolve(result);
            };
            this.reject = (error) => {
                this.reject = null;
                this.resolve = null;
                reject(error);
            };
        });
    }

    private async executeApproval(approve: DumbappApprovalRequest) {
        let shortcode: string;

        switch (approve.type) {
            case "erc721-all":
                shortcode = "all721";
                break;
            case "erc20":
                shortcode = "appr20";
                break;
            case "erc721":
                shortcode = "app721";
                break;
            case "undetermined":
                throw new Error("Token approval is required before submitting, but was unable to determine the details of the approval.");
        }

        let dumbapp: Dumbapp = await this.api.getDumbapp(shortcode);

        let executor = new SimpleExecutor(this.wallet, dumbapp, this.api, this.type, async (state) => {
            return {
                success: true,
                data: {
                    type: "values",
                    data: omit(approve, ["type"])
                }
            }
        });

        executor.onAny((eventName, eventData) => {
            this.emit(eventName, {
                ...eventData,
                approval: true
            });
        });

        let approval = await executor.execute();

        executor.clean();

        return approval;
    }

    private dispatchUpdate(timeout = 3000) {
        setTimeout(() => {
            this.update().catch(console.error);
        }, timeout);
    }

    private async update() {
        let submission = new DumbappSubmission(this.dumbapp, this.result.data);
        if (!submission.data.pending()) {
            // Not pending, no update needed
            if (this.reject) {
                this.reject(new Error("No update needed but Promise was still pending"));
            }
            return;
        }

        let res = await this.wallet.updateStatus(submission);

        let dispatchTimeout = 3000;
        if (res.success && res.data) {
            let pendingStep = res.data.pendingStep();

            if (pendingStep) {
                let step = submission.dumbapp.steps[pendingStep.index];
                this.emit("pending-step", {step, submissionStep: pendingStep});
                if (pendingStep.status === "confirm") {
                    // Speed up timeout when we're just checking for user confirmation
                    dispatchTimeout = 1000;
                }
            }
            let previousPending = submission.data.pendingStep();
            let lastIndex = previousPending.index;
            let currentIndex = pendingStep ? pendingStep.index : (res.data.steps.length - 1);

            while (lastIndex <= currentIndex) {
                let step = submission.data.steps[lastIndex];
                let update = res.data.steps[lastIndex++];
                if (step.status !== update.status || step.transactionHash !== update.transactionHash) {
                    this.emit("dumbapp-update", {
                        id: submission.id,
                        stepId: update.id,
                        stepNumber: update.index,
                        previousStatus: step.status,
                        status: update.status,
                        transactionHash: update.transactionHash,
                        shortcode: this.dumbapp.shortcode,
                        type: this.type,
                    })
                }
            }

            this.result = res;
            let lastStep = submission.data.steps[submission.data.steps.length - 1];

            if (!submission.data.completed() && res.data.completed()) {
                this.emit("dumbapp-complete", {
                    id: submission.id,
                    transactionHash: lastStep.transactionHash,
                    shortcode: this.dumbapp.shortcode,
                    type: this.type,
                    status: "completed"
                });
                if (this.resolve) {
                    this.resolve(new DumbappSubmission(this.dumbapp, res.data));
                }
                return;
            }
            if (!submission.data.error() && res.data.error()) {
                let error = res.data.error();
                let err = {
                    code: undefined as string,
                    message: undefined as string,
                }
                if (typeof error === "string") {
                    err.message = error;
                } else {
                    err.code = error.code;
                    err.message = error.message;
                }

                this.emit("dumbapp-error", {
                    id: submission.id,
                    shortcode: this.dumbapp.shortcode,
                    type: this.type,
                    status: "error"
                });
                if (this.reject) {
                    this.reject(new Error(err.message));
                }
                return;
            }
        }
        this.dispatchUpdate(dispatchTimeout);
    }
}
