import { DeferredBatcher } from "./DeferredBatcher";
import { ChainClient } from "./ChainClient";
import { ChainReference } from "@blockwell/chains";
import { debounce, partition } from "@blockwell/util";
import { DeferredBatcherProvider } from "./DeferredBatcherProvider";
import { Listener } from "@ethersproject/providers";
import { Log } from "@ethersproject/abstract-provider";
import { VariableUpdateListeners } from "./VariableUpdateListeners";

class CollectingListener<T> {
    batch: T[] = [];

    constructor(public readonly listener: (data: T[]) => void) {}

    protected process = () => {
        let current = this.batch;
        this.batch = [];
        this.listener(current);
    };

    readonly debounced = debounce(this.process, 500, { maxWait: 2000 });

    callback = (data: T) => {
        this.batch.push(data);
        this.debounced();
    };
}

export class DeferredBatcherController implements DeferredBatcherProvider {
    protected batchers: DeferredBatcher[] = [];
    protected subscriptions: CollectingListener<any>[] = [];

    public readonly variableUpdates: VariableUpdateListeners;

    constructor(public readonly client: ChainClient, protected listener: () => void) {
        this.variableUpdates = new VariableUpdateListeners(client);
    }

    batch(network: ChainReference) {
        if (!this.listener) {
            throw new Error("Controller already cleaned up, can't batch anymore.");
        }

        let batcher = new DeferredBatcher(network, this.client, this.listener);

        this.batchers.push(batcher);

        return batcher;
    }

    collect(): DeferredBatcher[] {
        let waiting: DeferredBatcher[] = [];
        let execute: DeferredBatcher[] = [];

        for (let it of this.batchers) {
            if (it.deferred) {
                execute.push(it);
            } else {
                waiting.push(it);
            }
        }

        this.batchers = waiting;
        return execute;
    }

    execute() {
        let collected = this.collect();
        if (collected.length > 0) {
            try {
                let batches = collected.map((it) => {
                    let calls = it.calls();
                    return {
                        batcher: it,
                        calls: calls.batch,
                        count: calls.batch.length,
                        chain: calls.chain,
                    };
                });

                while (batches.length > 0) {
                    let chain = batches[0].chain;
                    let [filtered, other] = partition(
                        batches,
                        (it) => it.chain.chainId === chain.chainId
                    );
                    batches = other;

                    let batch = filtered.flatMap((it) => it.calls);

                    this.client
                        .multicall(chain, batch)
                        .then((res) => {
                            for (let it of filtered) {
                                try {
                                    let data = it.batcher.finalize(res.splice(0, it.count));
                                    it.batcher.result(data);
                                } catch (err) {
                                    it.batcher.error(err);
                                }
                            }
                        })
                        .catch((err) => {
                            for (let it of filtered) {
                                it.batcher.error(err);
                            }
                        });
                }
            } catch (err) {}
        }
    }

    updateEvents() {}

    onLogs(
        network: ChainReference,
        filter: { address: string; topics?: string[] },
        listener: (log: Log[]) => void
    ) {
        let collect = new CollectingListener(listener);
        this.client.onLogs(network, filter, collect.callback);
        this.subscriptions.push(collect);
    }

    off(listener: Listener) {
        this.client.off(listener);
        let index = this.subscriptions.findIndex((it) => it.listener === listener);
        if (index > -1) {
            this.subscriptions[index].debounced.cancel();
            this.subscriptions.splice(index, 1);
        }
    }

    clean() {
        this.listener = undefined;

        for (let it of this.subscriptions) {
            it.debounced.cancel();
            this.client.off(it.listener);
        }
        this.variableUpdates.destroy();
    }
}
