import { DumbappSubmission } from "../submission";
import { CachedContractsApi, ContractData } from "@blockwell/apiminer-client";
import { ChainReference, getChain } from "@blockwell/chains";
import { TokenData } from "../schema";

export function findEvents(submission: DumbappSubmission, match: string | RegExp) {
    let test: (val: string) => boolean;
    if (match instanceof RegExp) {
        test = (val: string) => match.test(val);
    } else {
        test = (val: string) => match === val;
    }

    let events = [];
    for (let step of submission.data.steps) {
        if (step.completed() && step.events && step.events.length > 0) {
            for (let it of step.events) {
                if (!it) {
                    continue;
                }
                if (it.event && test(it.event)) {
                    events.push({
                        event: it,
                        step,
                    });
                }
            }
        }
    }

    return events;
}

export function contractToTokenData(contract: ContractData): TokenData {
    return {
        name: contract.name,
        symbol: contract.parameters.symbol,
        decimals: contract.parameters.decimals,
        network: getChain(contract.network).chainId,
        address: contract.address,
        bwtype: contract.bwtype,
        bwver: contract.bwver,
    };
}

export function getTokenData(
    contracts: CachedContractsApi,
    chain: ChainReference,
    address: string
) {
    return contracts.getContract({ chainId: chain, address }).then(contractToTokenData);
}
