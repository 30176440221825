import { BigNumber } from "@ethersproject/bignumber";
import { hexStripZeros } from "@ethersproject/bytes";

const addressRegex = /^0x[a-f0-9]{40}$/i;

export interface WithNetwork {
    network: string | number;
}

export class InfuraNode {
    constructor(public net: string, public projectId: string) {}

    getUrl() {
        return `https://${this.net || "mainnet"}.infura.io/v3/${this.projectId}`;
    }

    getWs() {
        return `wss://${this.net || "mainnet"}.infura.io/ws/v3/${this.projectId}`;
    }
}

export interface EthNetworkConfig {
    chainId: number;
    networkName: string;
    aliases: string[];
    nodeUrl?: string | InfuraNode;
    explorer?: string;
    altNodeUrl?: string;
    coinName?: string;
    symbol?: string;
    description?: string;
    eip1559?: boolean;
    priorityFee?: string;
    gasPrice?: string;
    ws?: string;
}

export class EthNetwork {
    chainId: number;
    networkName: string;
    aliases: string[];
    nodeUrl?: string | InfuraNode;
    explorer?: string;
    altNodeUrl?: string;
    coinName?: string;
    symbol?: string;
    description?: string;
    eip1559?: boolean;
    priorityFee?: string;
    gasPrice?: string;
    ws?: string;
    decimals = 18;

    constructor(config: EthNetworkConfig) {
        Object.assign(this, config);
        if (!this.symbol) {
            this.symbol = "ETH";
        }
    }

    get name() {
        return this.networkName;
    }

    get networkId() {
        return this.chainId;
    }

    get alias() {
        return this.aliases[0];
    }

    clone(): EthNetwork {
        // @ts-ignore
        return new this.constructor(this);
    }

    getNodeUrl() {
        if (typeof this.nodeUrl === "string") {
            return this.nodeUrl;
        }
        if (this.nodeUrl?.getUrl) {
            return this.nodeUrl.getUrl();
        }
        return null;
    }

    getWsUrl() {
        if (this.nodeUrl && typeof this.nodeUrl !== "string") {
            return this.nodeUrl.getWs();
        }
        return this.ws;
    }

    explorerAddress(address: string) {
        if (this.explorer) {
            if (addressRegex.test(address)) {
                return `${this.explorer}/address/${address}`;
            }
        }
    }

    explorerTx(hash: string) {
        if (this.explorer) {
            if (/^0x[a-f0-9]{64}$/i.test(hash)) {
                return `${this.explorer}/tx/${hash}`;
            }
        }
    }

    explorerToken(address: string, wallet?: string) {
        if (this.explorer) {
            if (addressRegex.test(address)) {
                let url = `${this.explorer}/token/${address}`;
                if (addressRegex.test(wallet)) {
                    url += `?a=${wallet}`;
                }
                return url;
            }
        }
    }

    hexChainId() {
        return hexStripZeros(BigNumber.from(this.chainId).toHexString());
    }
}

export class BlockscoutNetwork extends EthNetwork {
    explorerAddress(address: string) {
        if (this.explorer) {
            if (addressRegex.test(address)) {
                return `${this.explorer}/address/${address}`;
            }
        }
    }

    explorerTx(hash: string) {
        if (this.explorer) {
            if (/^0x[a-f0-9]{64}$/i.test(hash)) {
                return `${this.explorer}/tx/${hash}`;
            }
        }
    }

    explorerToken(address: string, wallet?: string) {
        if (this.explorer) {
            if (addressRegex.test(address)) {
                if (wallet && addressRegex.test(wallet)) {
                    return `${this.explorer}/address/${wallet}/tokens/${address}/token-transfers`;
                }
                return `${this.explorer}/tokens/${address}`;
            }
        }
    }
}
