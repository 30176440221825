export class DumbappSubmission {
    constructor(dumbapp, data) {
        this.dumbapp = dumbapp;
        this.data = data;
    }
    get id() {
        return this.data?.id;
    }
    get type() {
        return this.data?.executor;
    }
}
