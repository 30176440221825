import { findEvents, getTokenData } from "./util";
import { DumbappExtraProcessor } from "./DumbappExtraProcessor";
import { DumbappSubmission } from "../submission";
import { ExecutorParameters } from "../executor";
import { ethResolveStringParameter } from "@blockwell/eth-types";
import BigNumber from "bignumber.js";
import { UniswapExtra } from "../schema";

export class UniswapExtraProcessor implements DumbappExtraProcessor {
    key = "uniswap";

    async process(parameters: ExecutorParameters, submission: DumbappSubmission) {
        let swaps: UniswapExtra[] = [];

        for (let { event, step } of findEvents(submission, "Swap")) {
            let amount0In = ethResolveStringParameter(event.params, "amount0In");
            if (amount0In) {
                let transfers = step.events.filter((it) => it.event === "Transfer");

                let fromAddress = transfers[0].address;
                let toAddress = transfers[transfers.length - 1].address;

                let from = await getTokenData(parameters.contracts, step.network, fromAddress);
                let to = await getTokenData(parameters.contracts, step.network, toAddress);

                let val = new BigNumber(ethResolveStringParameter(event.params, "amount0Out"));
                let feeStep = submission.data.steps[2];

                if (feeStep) {
                    let ev = feeStep.events.find((it) => it.event === "Transfer");
                    val = val.minus(ethResolveStringParameter(ev.params, "2"));
                }

                swaps.push({
                    from,
                    to,
                    fromAmount: new BigNumber(
                        submission.data.getInput("amount") as string
                    ).toFormat(),
                    toAmount: val.div(`1e${to.decimals}`).toFormat(),
                });
            }
        }
        return swaps;
    }
}
