import { Wallet, WalletFactory } from "./Wallet";
import { ExecutorParameters } from "../executor";
import { WindowLike } from "./window";
import { Dumbapp } from "../Dumbapp";
import { DumbappWalletStrategy } from "../schema";
import { difference, intersection } from "remeda";

export class WalletProvider {
    public wallets: Record<string, WalletFactory<Wallet>> = {};

    constructor(public window: WindowLike) {
    }

    async get(type: string, parameters: ExecutorParameters): Promise<Wallet> {
        let factory = this.wallets[type];

        if (!factory) {
            throw new Error(`Factory for wallet type '${type}' not found.`);
        }

        return await factory.createWallet(parameters, this.window);
    }

    addType<T extends Wallet>(wallet: WalletFactory<T>): WalletProvider {
        this.wallets[wallet.type] = wallet;
        return this;
    }

    allowedTypes(dumbapp: Dumbapp, overrideStrategy?: DumbappWalletStrategy["type"]): string[] {
        const types = Object.values(this.wallets).map(it => it.type);
        let strategy: DumbappWalletStrategy = dumbapp?.walletStrategy || { type: "auto" };
        if (overrideStrategy) {
            strategy = { type: overrideStrategy };
        }
        switch (strategy.type) {
            case "required":
                return intersection(strategy.wallets, types)
            case "priority":
                let list = strategy.wallets;
                let others = difference(types, list);

                return list.concat(others);
            default:
                return types;
        }
    }
}
