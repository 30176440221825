import ApiGroup from "./ApiGroup";
import { BaseFragment } from "@blockwell/eth-types";
import { ApiMinerTransaction, SendOptions } from "./Transactions";
import { ChainReference, toChainId } from "@blockwell/chains";
import { ContractData, ContractQuery } from "@blockwell/apiminer";

export type ContractMethodArguments = (string | string[])[];

export interface WalletData {
    id: string;
    address: string;
    wallet: true;
}

export interface BatchCallRpcRequest {
    jsonrpc: string;
    network: string | number;
    arg?: (string | number)[];
}

export interface BatchCallContractIdRequest {
    contractId: string;
    method: string;
    arg?: ContractMethodArguments;
}

export interface BatchCallAddressAbiRequest {
    address: string;
    network: string | number;
    abi: BaseFragment;
    arg?: ContractMethodArguments;
}

export interface BatchCallAddressTypeRequest {
    address: string;
    network: string | number;
    type: string;
    arg?: ContractMethodArguments;
}

export type BatchCallRequest =
    | BatchCallRpcRequest
    | BatchCallContractIdRequest
    | BatchCallAddressAbiRequest
    | BatchCallAddressTypeRequest;

export type BatchCallSuccessResponse<T = any> = { data: T };
export type BatchCallErrorResponse = { error: string };
export type BatchCallResponse<T = any> = BatchCallSuccessResponse<T> | BatchCallErrorResponse;

export function isSuccessResponse(
    response: BatchCallResponse
): response is BatchCallSuccessResponse {
    return "data" in response;
}

export function isStringSuccessResponse(
    response: BatchCallResponse
): response is BatchCallSuccessResponse<string> {
    return "data" in response && typeof response.data === "string";
}

export interface ApiMinerRawEvent {
    address: string;
    blockHash: string;
    blockNumber: number;
    logIndex: number;
    removed: boolean;
    transactionHash: string;
    transactionIndex: number;
    id: string;
    returnValues: Record<string, string>;
    event: string;
    signature: string;
    raw: {
        data: string;
        topics: string[];
    };
}

export default class Contracts extends ApiGroup {
    /**
     * Find a Contract by network and address
     */
    find(address: string, network?: number | string) {
        let params: any = { address };

        if (network) {
            params.network = network;
        }
        return this.request<ContractData[] | WalletData[]>({
            url: "/contracts",
            params,
        }).then((response) => response.data.data);
    }

    search(text: string, network?: number | string): Promise<ContractData[]> {
        let params: Record<string, any> = {text};

        if (network) {
            params.network = network;
        }

        return this.request<ContractData[]>({
            url: "/contracts",
            params
        }).then((response) => response.data.data);
    }

    /**
     * Query for a list of contracts.
     * @param queries List of contracts to retrieve
     */
    query(queries: ContractQuery[]) {
        return this.request<ContractData[]>({
            url:
                "/contracts?id=" +
                queries
                    .map((it) => {
                        if (typeof it === "string") {
                            return it;
                        } else {
                            return toChainId(it.chainId) + "." + it.address;
                        }
                    })
                    .join(","),
        }).then((response) => response.data.data);
    }

    /**
     * Adds an existing smart contract to API Miner.
     *
     * @param  name Name of the contract
     * @param network Ethereum network the contract is on
     * @param  address Address of the contract
     * @param abiSource Either the ABI itself as an array, or a smart contract type name in API Miner
     * @param  fromBlock The first block this contract is present in
     */
    add(
        name: string,
        network: string | number,
        address: string,
        abiSource: string | BaseFragment[],
        fromBlock: number
    ) {
        let data: any = {
            name,
            network,
            address,
            fromBlock,
        };

        if (Array.isArray(abiSource)) {
            data.abi = abiSource;
        } else if (typeof abiSource === "string") {
            data.type = abiSource;
        } else {
            throw new Error("abiSource must either be an ABI array or a type string");
        }

        return this.request<ContractData>({
            method: "put",
            url: "/contracts",
            data,
        }).then((response) => response.data.data);
    }

    /**
     * Get a specific Contract by its ID.
     *
     * @param  id
     */
    get(id: string) {
        return this.request<ContractData>({
            url: `/contracts/${id}`,
        }).then((response) => response.data.data);
    }

    /**
     * Make a view-only contract call.
     *
     * @param id Contract ID
     * @param method Contract function
     * @param  arg Function arguments
     */
    call(id: string, method: string, arg: string[] = []) {
        return this.request<any>({
            url: `/contracts/${id}/call/${method}`,
            params: {
                arg: arg,
            },
        }).then((response) => response.data.data);
    }

    /**
     * Make a batch of view-only contract calls.
     *
     * @param  calls Batch of calls
     */
    batchCall(calls: BatchCallRequest[]) {
        return this.request<BatchCallResponse[]>({
            method: "post",
            url: `/contracts/call`,
            data: { calls: calls },
        }).then((response) => response.data.data);
    }

    /**
     * Make a transactional contract call.
     */
    send(id: string, method: string, arg?: ContractMethodArguments, opt: SendOptions = {}) {
        let data: SendOptions & { arg?: ContractMethodArguments } = Object.assign({}, opt);

        if (arg) {
            data.arg = arg;
        }

        return this.request<ApiMinerTransaction>({
            method: "post",
            url: `/contracts/${id}/send/${method}`,
            data: data,
        }).then((response) => response.data.data);
    }

    /**
     * Make a contract send directly to chain without a contract ID.
     *
     * @param network
     * @param  address
     * @param type Name of the contract type, eg. erc20
     * @param  method Contract function
     * @param arg Function arguments
     * @param from From account
     */
    direct(
        network: string | number,
        address: string,
        type: string,
        method: string,
        arg?: ContractMethodArguments,
        from?: string
    ) {
        let data: any = {
            type,
        };

        if (arg) {
            data.arg = arg;
        }
        if (from) {
            data.from = from;
        }

        return this.request<ApiMinerTransaction>({
            method: "post",
            url: `/contracts/direct/${network}/${address}/send/${method}`,
            data: data,
        }).then((response) => response.data.data);
    }

    /**
     * Make a direct view-only contract call.
     *
     * @param  network
     * @param  address
     * @param  type Name of the contract type, eg. erc20
     * @param  method Contract function
     * @param  arg Function arguments
     */
    directCall(
        network: string | number,
        address: string,
        type: string,
        method: string,
        arg: ContractMethodArguments = []
    ) {
        return this.request<any>({
            url: `/contracts/direct/${network}/${address}/call/${method}`,
            params: {
                type,
                arg: arg,
            },
        }).then((response) => response.data.data);
    }

    allEvents(contractId: string, fromBlock?: number, filter: any = {}) {
        let params: any = Object.assign({}, filter);
        if (fromBlock) {
            params.fromBlock = fromBlock;
        }
        return this.request<ApiMinerRawEvent[]>({
            url: `/contracts/${contractId}/events`,
            params,
        }).then((response) => response.data.data);
    }

    events(contractId: string, event: string, fromBlock?: number, filter: any = {}) {
        let params: any = Object.assign({}, filter);
        if (fromBlock) {
            params.fromBlock = fromBlock;
        }
        return this.request<ApiMinerRawEvent[]>({
            url: `/contracts/${contractId}/events/${event}`,
            params,
        }).then((response) => response.data.data);
    }
}
