import {
    DumbappArgument,
    DumbappArgumentType,
    DumbappContractStep,
    DumbappEtherStep, DumbappSource,
    DumbappStep,
    DumbappStepBase,
    DumbappValue
} from "./schema";
import {
    SolidityType,
    Typing,
    BaseFragment,
    TypeInfo,
    EthParameter,
    isTupleParameter,
    isTupleArrayParameter, isStringArrayParameter, isBooleanArrayParameter, isIndexedParameter, EthTupleParameter
} from "@blockwell/eth-types";
import { JsonFragment } from "@ethersproject/abi";
import { bwTypeMap, ContractData } from "@blockwell/apiminer-client";

export function solidityTypeToArgumentType(input: SolidityType): DumbappArgumentType {
    switch (input) {
        case "fixed":
        case "ufixed":
        case "int":
        case "uint":
        case "bool":
        case "address":
        case "bytes":
        case "string":
        case "tuple":
            return input;
        case "function":
        case "any":
            return "string";
        default:
            return "string";
    }
}

export function isDumbappSource(it: any): it is DumbappSource {
    if (it && typeof it === "object") {
        return typeof it.type === "string" && typeof it.parameters === "object";
    }
    return false;
}

export function isDumbappValue(it: any): it is DumbappValue {
    if (Array.isArray(it)) {
        for (let ele of it) {
            if (!isDumbappValue(ele)) {
                return false;
            }
        }
        return true;
    }

    let type = typeof it;
    if (it && type === 'object') {
        for (let val of Object.values(it)) {
            if (!isDumbappValue(val)) {
                return false;
            }
        }
        return true;
    }

    return type === "string" || type === "boolean" || type === "number";
}

export function ethParametersToDumbappValues(params: EthParameter[]): Record<string, DumbappValue> {
    let data: Record<string, DumbappValue> = {};

    for (let param of params) {
        if (param.name) {
            data[param.name] = ethParameterToDumbappValue(param);
        }
    }
    return data;
}

export function ethParameterToDumbappValue(param: EthParameter): DumbappValue {
    if (isTupleParameter(param)) {
        return ethParametersToDumbappValues(param.components);
    }
    if (isTupleArrayParameter(param)) {
        return param.values.map((it: EthTupleParameter) => ethParametersToDumbappValues(it.components));
    }
    if (isStringArrayParameter(param) || isBooleanArrayParameter(param)) {
        return param.values;
    }
    if (isIndexedParameter(param)) {
        return param.hash;
    }
    return param.value;
}

export function isEtherStep(it: DumbappStep): it is (DumbappStepBase & DumbappEtherStep) {
    return it && "ether" in it;
}

export function isContractStep(it: DumbappStep): it is (DumbappStepBase & DumbappContractStep) {
    return it && "method" in it;
}


export function getFieldType(name: string, typing: TypeInfo, func: JsonFragment | BaseFragment = null, i = 0): DumbappArgumentType {
    let type: DumbappArgumentType;
    if (typing.name === "uint" && (name === 'unlockTime' || name === 'lockTime' || name === 'stakeTime')) {
        type = 'time';
    } else if (typing.name === "uint" && name === 'proposalId') {
        type = 'payment-proposal';
    } else if (func?.name === "vote" && func.inputs.length === 2 && i === 0) {
        type = 'suggestion';
    } else if (func?.name === "multiVote" && func.inputs.length === 3 && i === 0) {
        type = 'suggestion';
    } else if (func?.name === "proposalVote" && func.inputs.length === 2 && i === 0) {
        type = 'proposal';
    } else if (typing.name === "function" || typing.name === "any") {
        type = "string";
    } else {
        type = typing.name;
    }
    return type;
}

export function getFeatures(contract: ContractData): string[] {
    if (contract.bwtype) {
        let bwType = bwTypeMap[contract.bwtype];
        if (bwType.features) {
            return bwType.features;
        }
    }

    let features: string[] = [];
    let funcs = contract.abi.filter(it => it.type === 'function');

    if (hasFunction(funcs, 'safeTransferFrom', 'address', 'address', 'uint256')
        && hasFunction(funcs, 'safeTransferFrom', 'address', 'address', 'uint256', 'bytes')
        && hasFunction(funcs, 'balanceOf', 'address')
        && hasFunction(funcs, 'ownerOf', 'uint256')
        && hasFunction(funcs, 'approve', 'address', 'uint256')
        && hasFunction(funcs, 'setApprovalForAll', 'address', 'bool')
    ) {
        features.push('erc721');
    } else if (hasFunction(funcs, 'transfer', 'address', 'uint256')
        && hasFunction(funcs, 'approve', 'address', 'uint256')
        && hasFunction(funcs, 'transferFrom', 'address', 'address', 'uint256')
    ) {
        features.push('erc20');
    }

    return features;
}

export function hasFunction(abi: JsonFragment[], name: string, ...params: string[]) {
    return !!abi.find((it: JsonFragment) => {
        if (it.name !== name) {
            return false;
        }
        if (it.inputs?.length !== params.length) {
            return false;
        }

        let i = 0;
        for (let input of it.inputs) {
            let paramType = Typing.parse(params[i]);
            let inputType = Typing.parse(input.type);
            if (!paramType.equals(inputType)) {
                return false;
            }
            ++i;
        }
        return true;
    });
}
