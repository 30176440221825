import {
    DumbappArguments,
    ExecutionPrepareState,
    ExecutionResolution,
    ExecutionResultState,
    ExecutionState,
} from "./ExecutionState";
import { DumbappSubmissionData } from "../submission";
import { DumbappApprovalRequest, DumbappInputValues } from "../schema";
import { ContractData } from "@blockwell/apiminer";

/**
 * The phases a dumbapp execution goes through, in order.
 *
 * They are defined in this way so that we can both use the keys in order
 * at runtime, and TypeScript can derive types from it.
 */
export const phases = Object.freeze({
    /**
     * Prepare a new request by setting a timestamp and determining the correct dumbapp.
     */
    prepareRequest: null as PrepareRequestHandler,
    /**
     * Prepare the user's wallet, and create a unique ID for the submission.
     *
     * There must be exactly one handler.
     */
    prepareWallet: null as PrepareWalletHandler,
    /**
     * Additional handlers called before user input is collected.
     */
    preInput: null as ExecutionStateHandler,
    /**
     * Collect user input.
     */
    input: null as InputHandler,
    /**
     * Additional handlers called after user input is collected.
     */
    postInput: null as ExecutionStateHandler,
    /**
     * Resolve all dumbapp arguments needed for the smart contract function calls.
     */
    resolve: null as ResolveHandler,
    /**
     * Load contract data for all steps if applicable.
     */
    loadContracts: null as LoadContractsHandler,
    /**
     * Additional handlers called after arguments are resolved, before the dumbapp is
     * executed.
     */
    preExecute: null as PreExecuteHandler,
    /**
     * Execute the dumbapp.
     *
     * There must be exactly one handler.
     */
    execute: null as ExecutionHandler,
    /**
     * Act on an execution result, either success or failure.
     */
    result: null as ExecutionResultHandler,
});

/**
 * Phase handler types.
 */
export type ExecutionPhases = typeof phases;

/**
 * Names of the different phases.
 */
export type PhaseNames = keyof ExecutionPhases;

/**
 * Prepare a new request by setting a timestamp and determining the correct dumbapp.
 */
export type PrepareRequestHandler = (
    simulation: boolean
) => ExecutionHandlerResult<ExecutionPrepareState>;

/**
 * Prepare the user's wallet, and create a unique ID for the submission.
 *
 * There must be exactly one handler.
 */
export type PrepareWalletHandler = (
    state: ExecutionPrepareState
) => Promise<ExecutionHandlerResult<ExecutionState>>;

/**
 * Collect user input.
 */
export type InputHandler = (
    state: Readonly<ExecutionState>
) => Promise<ExecutionHandlerResult<DumbappInputValues | DumbappInputValues[]>>;

/**
 * Resolve all dumbapp arguments needed for the smart contract function calls.
 */
export type ResolveHandler = (
    state: Readonly<ExecutionState>
) => Promise<ExecutionHandlerResult<ExecutionResolution[]>>;

/**
 * Load contract data for all applicable steps so execution handlers can use them.
 */
export type LoadContractsHandler = (
    state: Readonly<ExecutionState>,
    args: DumbappArguments
) => Promise<ExecutionHandlerResult<ContractData[]>>;

export type PreExecuteHandler = (
    state: Readonly<ExecutionState>,
    args: DumbappArguments
) => Promise<ExecutionHandlerResult<Partial<ExecutionState>>>;

/**
 * Additional handlers called in other phases.
 */
export type ExecutionStateHandler = (
    state: Readonly<ExecutionState>
) => Promise<ExecutionHandlerResult<Partial<ExecutionState>>>;

/**
 * Result type for the data execution phase handlers should return.
 */
export type ExecutionExecuteResult = ExecutionHandlerResult<DumbappSubmissionData>;

/**
 * Execute the dumbapp.
 *
 * There must be exactly one handler.
 */
export type ExecutionHandler = (
    state: Readonly<ExecutionState>,
    args: DumbappArguments
) => Promise<ExecutionExecuteResult>;

/**
 * Act on an execution result, either success or failure.
 */
export type ExecutionResultHandler = (
    result: ExecutionResult<ExecutionResultState>
) => Promise<void>;

/**
 * Error result from a phase handler. This is the type that
 * handlers should return when encountering errors.
 */
export interface ExecutionError {
    success: false;
    code: string;
    message?: string;
    errorData?: Record<string, any>;
    submission?: DumbappSubmissionData;
}

/**
 * Result type for what handlers should return.
 */
export type ExecutionHandlerResult<T> = ExecutionResult<T, ExecutionError>;

/**
 * An error result that the ExecutionController provides
 * as an overall result, which includes the state of the execution.
 */
export interface ExecutionErrorResult extends ExecutionError {
    state: Partial<ExecutionResultState>;
}

/**
 * Result type for the overall execution that the ExecutionController
 * ends with.
 */
export type ExecutionResult<T, Err = ExecutionErrorResult> = Err | { success: true; data: T };

export interface ExecutionResolveResult {
    state: ExecutionState;
    arguments: DumbappArguments;
}
