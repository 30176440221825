import Accounts from "./Accounts";
import Contracts from "./Contracts";
import Transactions from "./Transactions";
import Users from "./Users";
import Tokens from "./Tokens";
import Network from "./Network";
import { AxiosInstance } from "axios";

/**
 * Simple client for API Miner.
 */
class ApiMiner {
    public accounts: Accounts;
    public contracts: Contracts;
    public transactions: Transactions;
    public users: Users;
    public tokens: Tokens;
    public network: Network;

    /**
     * Constructor.
     *
     * @param {AxiosInstance} client
     * @param {String} accessToken
     */
    constructor(private client: AxiosInstance, accessToken?: string) {
        this.accounts = new Accounts(client);
        this.contracts = new Contracts(client);
        this.transactions = new Transactions(client);
        this.users = new Users(client);
        this.tokens = new Tokens(client);
        this.network = new Network(client);
        if (accessToken) {
            client.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;
        }
    }
}

export default ApiMiner;
