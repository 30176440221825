import {
    EthParameter,
    isBooleanArrayParameter,
    isBooleanParameter,
    isStringArrayParameter,
    isStringParameter,
    isTupleArrayParameter,
    isTupleParameter,
} from "@blockwell/eth-types";

export type ChainArgument =
    | string
    | number
    | boolean
    | ChainArgument[]
    | {
          [k: string]: ChainArgument;
      };



export type ChainResponse = string | number | boolean | {[key: string]: ChainResponse}| ChainResponse[];

export function isStructResponse(res: ChainResponse): res is ({[key: string]: ChainResponse}) {
    if (typeof res === "number" || typeof res === "boolean" || typeof res === "string") {
        return false;
    }
    if (Array.isArray(res)) {
        return false;
    }
    return !!res;

}

export function isArrayResponse(res: ChainResponse): res is ChainResponse[] {
    return Array.isArray(res);
}

export function isPrimitiveResponse(res: ChainResponse): res is (string | number | boolean) {
    return !Array.isArray(res) && !isStructResponse(res);
}

export function ethParamToChainResponse(param: EthParameter): ChainResponse {
    if (isStringArrayParameter(param) || isBooleanArrayParameter(param)) {
        return param.values;
    }
    if (isStringParameter(param) || isBooleanParameter(param)) {
        return param.value;
    }
    if (isTupleArrayParameter(param)) {
        return param.values.map(ethParamToChainResponse);
    }
    if (isTupleParameter(param)) {
        return Object.fromEntries(
            param.components.map((comp) => [comp.name, ethParamToChainResponse(comp)])
        );
    }

    return param.hash;
}
