import BigNumber from "bignumber.js";
import { resolveAddressSourcable, resolveBigNumberishSourcable, resolveStringSourcable, } from "../state";
import { resolveStringValue } from "../state";
import { Chain } from "@blockwell/chain-client";
import uniswapAbi from "./uniswap-abi";
import { isContractStep } from "../util";
const getAmountsOut = [uniswapAbi.find((it) => it.name === "getAmountsOut")];
async function amountOutMin(provider, source, arg, step, fallback = null) {
    if (source.name !== "amountOutMin") {
        return null;
    }
    let amountParam = source.amountIn;
    let pathParam = source.path;
    let resolved = await resolveBigNumberishSourcable(provider, [amountParam.value], step);
    let amountBig = new BigNumber(resolved);
    if (!amountBig.gt(0)) {
        if (fallback) {
            amountBig = new BigNumber(fallback);
        }
        else {
            return {};
        }
    }
    amountBig = amountBig.times(0.99);
    let inAmount = amountBig;
    let decimals = await resolveBigNumberishSourcable(provider, [amountParam.decimals], step);
    if (decimals) {
        amountBig = amountBig.times(`1e${decimals}`);
    }
    let amount = amountBig.toFixed(0);
    let path = [];
    for (let it of pathParam) {
        if (typeof it === "string") {
            path.push(it);
        }
        else {
            path.push(await resolveStringValue(provider, it, null, step));
        }
    }
    let res;
    try {
        let net = await resolveStringSourcable(provider, [step.network], step);
        let address = await resolveAddressSourcable(provider, [step.address], step);
        res = (await Chain.readContract(net, address, getAmountsOut, "getAmountsOut", [amount, path], 5000));
    }
    catch (err) {
        if (err.reason === "UniswapV2Library: INSUFFICIENT_INPUT_AMOUNT") {
            err.annotation = "Amount is too small to swap.";
        }
        throw err;
    }
    let big = new BigNumber(res[res.length - 1]).times(0.995);
    decimals = await resolveBigNumberishSourcable(provider, [arg.decimals], step);
    if (decimals) {
        big = big.div(`1e${decimals}`);
    }
    return {
        out: big.sd(5, BigNumber.ROUND_FLOOR).toString(10),
        inAmount,
    };
}
export class UniswapSourceProvider {
    constructor() {
        this.name = "uniswap";
    }
    getValue(provider, source, arg, step) {
        if (source.name === "amountOutMin") {
            return {
                async value() {
                    let { out } = await amountOutMin(provider, source, arg, step);
                    return out;
                },
                async annotation() {
                    let amountArg = step.arguments.find((it) => it.value?.parameters?.amountIn);
                    let amountParam = amountArg.value.parameters.amountIn;
                    let { out, inAmount } = await amountOutMin(provider, source, arg, step, "1");
                    let outAmount = new BigNumber(out);
                    let symbol = await resolveStringSourcable(provider, [amountArg.symbol], step);
                    let price = outAmount.div(inAmount).sd(5).toString(10);
                    return `1 ${amountParam.symbol} ≈ ${price} ${symbol}`;
                },
            };
        }
        else if (source.name === "path") {
            return {
                async value() {
                    let pathParam = source.path;
                    let path = [];
                    for (let it of pathParam) {
                        path.push(await resolveStringSourcable(provider, [it], step));
                    }
                    return path;
                },
            };
        }
        else if (source.name === "amountIn") {
            return {
                async value() {
                    let amountArg = step.arguments.find((it) => it.value?.parameters?.amountIn);
                    let amountParam = amountArg.value.parameters.amountIn;
                    let value = new BigNumber(await resolveBigNumberishSourcable(provider, [amountParam.value], step));
                    if (!value.gt(0)) {
                        return null;
                    }
                    return value.times(0.99).toString(10);
                },
            };
        }
        else if (source.name === "fee-in") {
            return {
                async value() {
                    let swapStep = provider.dumbapp.steps.find((it) => isContractStep(it) && it.method?.startsWith("swap"));
                    if (!swapStep) {
                        // Steps may briefly not match when switching dumbapps
                        return null;
                    }
                    let amountArg = swapStep.arguments.find((it) => it.value?.parameters?.amountIn);
                    let amountParam = amountArg.value.parameters.amountIn;
                    let value = new BigNumber(await resolveBigNumberishSourcable(provider, [amountParam.value], step));
                    if (!value.gt(0)) {
                        return null;
                    }
                    return value.times(0.01).toString(10);
                },
            };
        }
        else if (source.name === "fee-out") {
            return {
                async value() {
                    let swapStep = provider.dumbapp.steps.find((it) => isContractStep(it) && it.method?.startsWith("swap"));
                    if (!swapStep) {
                        // Steps may briefly not match when switching dumbapps
                        return null;
                    }
                    let amountArg = swapStep.arguments.find((it) => it.value?.parameters?.amountIn);
                    let amountParam = amountArg.value.parameters.amountIn;
                    let { out } = await amountOutMin(provider, amountArg.value.parameters, amountParam, swapStep);
                    let value = new BigNumber(out);
                    if (!value.gt(0)) {
                        return null;
                    }
                    return value.times(0.01).toString(10);
                },
            };
        }
    }
}
