import { EthMethod, EthReceipt, EthTransaction, EthTransactionData } from "./schema";
import { omit } from "remeda";
import { EthersTransactionReceipt, EthersTransactionResponse } from "./ethers-types";

export class EthTransactionPair implements EthTransaction {
    hash: string;
    transaction: EthTransactionData;
    receipt: EthReceipt | null;
    method?: EthMethod;
    altMethods?: EthMethod[];

    constructor(
        transaction: EthersTransactionResponse | EthTransactionData,
        receipt?: EthReceipt | EthersTransactionReceipt,
        methods?: EthMethod[]
    ) {
        // confirmations is always null, so remove it
        this.transaction = Object.fromEntries(Object.entries(omit(transaction, ["confirmations"])).map(([key, val]) => {
            // @ts-ignore
            if (val?._hex) {
                return [key, val.toString()]
            }
            return [key, val];
        })) as any;

        this.receipt = Object.fromEntries(Object.entries(receipt).map(([key, val]) => {
            // @ts-ignore
            if (val?._hex) {
                return [key, val.toString()]
            }
            return [key, val];
        })) as any;
        this.receipt.status = receipt.status || 1;
        this.hash = transaction.hash;
        this.method = methods?.[0];
        if (methods?.length > 1) {
            this.altMethods = methods.slice(1);
        }
    }
}
