/**
 * Central controller for managing dumbapp inputs for submission.
 */
import Emittery from "emittery";
import { DumbappController, ResolveParameters } from "./DumbappController";
import { ArgumentValueProvider } from "../state";
import { ExecutionStateValueProvider } from "../state";
import { ExecutionController } from "../state";
import { ExecutionArgumentResolver } from "../state";
import { Dumbapp } from "../Dumbapp";
import { debounce } from "@blockwell/util";
import { CachedContractsApi } from "@blockwell/apiminer-client";
import { CachedContractsLoader } from "./CachedContractsLoader";
import { ApprovalHandler } from "./ApprovalHandler";

export class InputController
    extends Emittery<{ change: ArgumentValueProvider, resolve: ResolveParameters }>
    implements DumbappController
{
    readonly values = new ExecutionStateValueProvider();
    readonly execution: ExecutionController = new ExecutionController();

    private resolver = new ExecutionArgumentResolver();
    private contractsLoader = new CachedContractsLoader();
    private approval = new ApprovalHandler();
    private lastResolveResult: ResolveParameters;

    public dumbapp: Dumbapp = null;
    public account: string = null;
    public hidden = true;

    constructor() {
        super();
        this.execution.addHandler("InputController", "prepareRequest", (simulation) => {
            let created = new Date().toISOString();
            if (!this.dumbapp) {
                return {
                    success: false,
                    code: "no_dumbapp",
                    message: "No dumbapp loaded, probably due to misconfiguration.",
                };
            }

            return {
                success: true,
                data: {
                    simulation,
                    dumbapp: this.dumbapp,
                    created,
                    data: [],
                    extras: {}
                },
            };
        });

        this.execution.addHandler("ExecutionArgumentResolver", "resolve", this.resolver.handler);
        this.execution.addHandler("InputController", "loadContracts", this.contractsLoader.handler);
        this.execution.addHandler("ApprovalHandler", "preExecute", this.approval.handler);
    }

    get lastResolve() {
        return this.lastResolveResult;
    }

    update(dumbapp: Dumbapp, account: string) {
        this.dumbapp = dumbapp;
        this.account = account;
        this.hidden = false;
    }

    setApi(api: CachedContractsApi) {
        this.contractsLoader.setApi(api);
        this.approval.setApi(api);
    }

    setHidden(hidden: boolean) {
        this.hidden = hidden;
        this.trigger();
    }

    placeholderText() {
        return "Enter a value in the form";
    }

    trigger() {
        if (this.dumbapp && !this.hidden) {
            this.execution.simulate().then((res) => {
                if (res) {
                    this.values.state = res;
                    this.account = res.wallet?.account;
                    this.emit("change", this.values);
                    this.doResolve(this.values)
                }
            });
        }
    }

    resolve() {
        if (this.values.state) {
            this.doResolve(this.values);
        }
    }

    protected doResolve = debounce((values: ExecutionStateValueProvider) => {
        this.execution.resolve(values.state)
            .then(async res => {
                if (res.success === true) {
                    this.lastResolveResult = {
                        success: true,
                        arguments: res.data.arguments,
                        approve: res.data.state.approve,
                        state: res.data.state,
                        values
                    };

                    this.emit("resolve", this.lastResolveResult);
                } else {
                    this.lastResolveResult = res;
                    this.emit("resolve", res);
                }
            })
    })
}
