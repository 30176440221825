import { TimedEntry } from "./Cache";
import { MutexMap } from "./MutexMap";

export class SyncMemoryCache<DefaultType = any> extends MutexMap {
    private store: Record<string, TimedEntry<any>> = {};

    constructor(protected cacheDuration: number) {
        super();
    }

    getItem<T = DefaultType>(key: string, updateTime?: boolean, overrideDuration?: number): TimedEntry<T> {
        let item: TimedEntry<T> = this.store[key];

        let duration: number;
        if (item?.exp) {
            duration = item.exp;
        } else if (overrideDuration) {
            duration = overrideDuration;
        } else {
            duration = this.cacheDuration;
        }

        if (duration > 0 && item && Date.now() - item.t.getTime() > duration) {
            delete this.store[key];
            return null;
        }

        if (updateTime && item) {
            let entry: TimedEntry<T> = {
                data: item.data,
                t: new Date(),
            };
            if (item.exp) {
                entry.exp = item.exp;
            }
            this.store[key] = entry;
        }

        return item;
    }

    setItem<T = DefaultType>(key: string, data: T, forceExpire?: number)  {
        let entry: TimedEntry<T> = {
            data,
            t: new Date(),
        };
        if (forceExpire) {
            entry.exp = forceExpire;
        }
        this.store[key] = entry;
    }

    /**
     * @param key Cache key for the data.
     * @param loader Function that loads the data.
     * @param mutexKey If provided, a mutex is used, keyed by this value. If `true`, the first argument `key` is used as the mutex key.
     * @param overrideDuration If provided, overrides the default cache duration
     */
    async withCache<T>(key: string, loader: () => Promise<T>, mutexKey?: string | boolean, overrideDuration?: number): Promise<T> {
        let useMutexKey: string;
        if (mutexKey) {
            if (mutexKey === true) {
                useMutexKey = key;
            } else {
                useMutexKey = mutexKey;
            }
        }
        let release = await (useMutexKey ? this.getMutex(useMutexKey) : null)?.acquire();

        try {
            let item = this.getItem<T>(key, false, overrideDuration);
            if (!item) {
                let data = await loader();
                this.setItem<T>(key, data);
                return data;
            }

            return item.data;
        } finally {
            if (release) {
                release();
                this.clearMutex(useMutexKey);
            }
        }
    }
}
