import { deepCopy, Deferrable } from "@ethersproject/properties";
import { fetchJson } from "@ethersproject/web";
import { JsonRpcProvider, StaticJsonRpcProvider, TransactionRequest } from "@ethersproject/providers";
import { BigNumber } from "@ethersproject/bignumber";
import { hexValue } from "@ethersproject/bytes";

export class RpcProvider extends StaticJsonRpcProvider {
    static async estimateGas(provider: JsonRpcProvider, gasLimit: BigNumber, transaction: Deferrable<TransactionRequest>): Promise<BigNumber> {
        let net = await provider.getNetwork();

        let data = await transaction.data;

        if (gasLimit) {
            if (data) {
                if (net.chainId === 1) {
                    gasLimit = gasLimit.add(22000);
                } else {
                    gasLimit = gasLimit.add(80000);
                }
                let signature = hexValue(data).slice(0, 10);

                // Add to gas limit for Lotto play functions, since they can have a highly variable
                // gas cost. "play()" and "multiPlay(uint256)"
                if (signature === "0x93e84cd9" || signature === "0x5be319ee") {
                    gasLimit = gasLimit.add(150000);
                }
            }
        }

        return gasLimit;
    }

    async estimateGas(transaction: Deferrable<TransactionRequest>): Promise<BigNumber> {
        return RpcProvider.estimateGas(this, await super.estimateGas(transaction), transaction);
    }
}
