import { isPromise } from "remeda";

export type FramedDebounceOptions<Result> = {
    maxWait?: number;
};

export interface FramedDebouncedFunction<Args extends any[], F extends (...args: Args) => any> {
    (this: ThisParameterType<F>, ...args: Args & Parameters<F>): Promise<ReturnType<F>>;

    cancel: (reason?: any) => void;
}

type DebounceInstance = {
    index: number;
    func: (...args: any[]) => any;
    timeout: number;
    promise?: Promise<any>;
    resolve?: (value: any) => void;
    reject?: (reason?: any) => void;
    context?: any;
    args?: any[];
    maxWait?: number;
    lastInvoke?: number;
    firstInvoke?: number;
};

const debounceFrameDelay = 32;
let index = 1;
let debounceCount = 0;
let debounces: Record<number, DebounceInstance> = {};
let intervalId: ReturnType<typeof setInterval> | undefined;
let running = false;

function run() {
    if (running) {
        console.warn("Skipping debounce frame, as previous frame is still running.");
        // Skip run if the last one is still running
        return;
    }
    running = true;
    try {
        let now = Date.now();
        for (let it of Object.values(debounces)) {
            let invoke = false;
            let diff = now - it.lastInvoke;

            if (diff >= it.timeout) {
                invoke = true;
            } else if (it.maxWait) {
                let max = now - it.firstInvoke;
                if (max >= it.maxWait) {
                    invoke = true;
                }
            }

            if (invoke) {
                let resolve = it.resolve;
                let reject = it.reject;
                it.promise = null;
                removeInstance(it);
                try {
                    let result = it.func.apply(it.context, it.args);
                    if (isPromise(result)) {
                        result.then(resolve).catch(reject)
                    } else {
                        resolve(result);
                    }
                } catch (err) {
                    reject(err);
                }
            }
        }
    } finally {
        running = false;
    }
    checkInterval();
}

function checkInterval() {
    if (debounceCount > 0) {
        if (!intervalId) {
            intervalId = setInterval(run, debounceFrameDelay);
        }
    } else {
        if (intervalId) {
            clearInterval(intervalId);
            intervalId = undefined;
        }
    }
}

function addInstance(instance: DebounceInstance) {
    if (debounces[instance.index]) {
        console.error("Debounce instance already in record, this shouldn't happen!");
    } else {
        debounceCount += 1;
    }

    debounces[instance.index] = instance;
    checkInterval();
}

function removeInstance(instance: DebounceInstance) {
    if (!debounces[instance.index]) {
        console.error("Removing debounce failed, instance doesn't exist!");
        return;
    }

    delete debounces[instance.index];
    debounceCount -= 1;
}

function createDebounce(instance: DebounceInstance): FramedDebouncedFunction<any, any> {
    const debouncedFunction = function (this: any, ...args: any[]) {
        instance.context = this;
        instance.args = args;
        instance.lastInvoke = Date.now();

        if (!instance.promise) {
            instance.firstInvoke = instance.lastInvoke;

            instance.promise = new Promise((resolve, reject) => {
                instance.resolve = resolve;
                instance.reject = reject;
            });
            addInstance(instance);
        }

        return instance.promise;
    };
    debouncedFunction.cancel = function (reason?: any) {
        if (instance.promise) {
            instance.promise = null;
            removeInstance(instance);
            instance.reject(reason);
        }
    };

    return debouncedFunction;
}

export function debounce<Args extends any[], F extends (...args: Args) => any>(
    func: F,
    waitMilliseconds = 500,
    options: FramedDebounceOptions<ReturnType<F>> = {}
): FramedDebouncedFunction<Args, F> {
    let instance: DebounceInstance = {
        ...options,
        index: index++,
        func,
        timeout: waitMilliseconds,
    };

    return createDebounce(instance);
}
