import { WalletContext } from "./WalletContext";
import { MetamaskProvider } from "./metamask";

export class DumbappNamespace {
    initialized = false;
    contexts: {[key: string]: WalletContext} = {};
    currentType: string = null;

    currentContext(): WalletContext {
        if (!this.currentType) {
            return Object.values(this.contexts)[0];
        }

        return Object.values(this.contexts).find(it => it.type === this.currentType);
    }

    addContext(context: WalletContext) {
        this.contexts[context.type] = context;
    }

    removeContext(type: string) {
        delete this.contexts[type];
    }

    getContext<T extends WalletContext>(type: string): T {
        return this.contexts[type] as T;
    }

    account(): string {
        return this.currentContext().account;
    }
    chainId(): number {
        return this.currentContext().chainId;
    }
}

export interface WindowLike {
    dumbappNamespace: DumbappNamespace;
    ethereum?: MetamaskProvider;
}

let windowLike: WindowLike;

export function getWindow(): WindowLike {
    if (windowLike) {
        return windowLike;
    }

    // @ts-ignore
    if (window) {
        // @ts-ignore
        let win: WindowLike = window;
        
        if (!win.dumbappNamespace) {
            win.dumbappNamespace = new DumbappNamespace();
        }
        
        windowLike = win;
    } else {
        windowLike = {
            dumbappNamespace: new DumbappNamespace()
        };
    }
    return windowLike;
}
