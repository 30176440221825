import ApiGroup from './ApiGroup';

export interface ApiMinerTokenTransfer {
    from: string;
    to: string;
    value: string;
    transactionHash: string;
    blockNumber: number;
}

export interface ApiMinerTokenInfo {
    address: string;
    name: string;
    symbol: string;
    decimals: string;
    totalSupply: string;
}

class Tokens extends ApiGroup {

    /**
     * List of past token transfers
     */
    transfers(contractId: string, address?: string) {
        let filter: any = {};

        if (address) {
            filter.address = address;
        }

        return this.request<ApiMinerTokenTransfer>({
            url: `/tokens/${contractId}/transfers`,
            params: filter
        }).then(response => response.data.data);
    }

    name(contractId: string) {
        return this.request<string>({
            url: `/contracts/${contractId}/call/name`
        }).then(response => response.data.data);
    }

    symbol(contractId: string) {
        return this.request<string>({
            url: `/contracts/${contractId}/call/symbol`
        }).then(response => response.data.data);
    }

    decimals(contractId: string) {
        return this.request<number>({
            url: `/contracts/${contractId}/call/decimals`
        }).then(response => response.data.data);
    }

    info(network: string | number, address: string) {
        return this.request<ApiMinerTokenInfo>({
            url: `/tokens/${network}/${address}`
        }).then(response => response.data.data);
    }
}

export default Tokens;
