export class SubmissionStepFactory {
    constructor() {
        this.types = {};
    }
    addType(type, creator) {
        this.types[type] = creator;
    }
    create(executor, data, index) {
        let creator = this.types[executor];
        if (creator) {
            return creator(data, index);
        }
        throw new Error("No factory function for dumbapp step with executor: " + executor);
    }
}
