export class ExtrasProvider {
    constructor() {
        this.extras = [];
    }
    get() {
        return this.extras;
    }
    addExtra(extra) {
        if (!this.extras.find((it) => it.key === extra.key)) {
            this.extras.push(extra);
        }
        return this;
    }
}
