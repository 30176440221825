import { BaseCache, TimedEntry } from "./Cache";

export class MemoryCache<DefaultType = any> extends BaseCache<DefaultType> {
    private store: Record<string, TimedEntry<any>> = {};

    constructor(cacheDuration: number) {
        super(cacheDuration);
    }

    protected async read<T>(key: string) {
        return this.store[key];
    }

    protected async write<T>(key: string, entry: TimedEntry<T>){
        this.store[key] = entry;
    }

    async removeItem(key: string) {
        delete this.store[key];
    }
}
