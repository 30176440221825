import { resolveBigNumberishSourcable, resolveStringSourcable } from "../state";
export class StepsSourceProvider {
    constructor() {
        this.name = "steps";
    }
    getValue(provider, source) {
        let dumbapp = provider.dumbapp;
        return {
            value() {
                if (dumbapp) {
                    let stepNumber = source.step - 1;
                    let step = dumbapp.steps[stepNumber];
                    if (!step) {
                        // It's possible to temporarily have the wrong dumbapp context when
                        // switching dumbapps
                        return null;
                    }
                    switch (source.name) {
                        case "network":
                            return resolveStringSourcable(provider, [step.network], step);
                        case "value":
                            return resolveBigNumberishSourcable(provider, [step.value], step);
                        case "address":
                            return resolveStringSourcable(provider, [step.address], step);
                    }
                }
            },
        };
    }
}
