import { Batcher } from "./Batcher";
import { ChainClient } from "./ChainClient";
import { ChainReference } from "@blockwell/chains";
import { ChainResponse } from "./ChainResponse";

export class DeferredBatcher extends Batcher {
    public deferred: boolean;
    protected resolve: (value: any) => void;
    protected reject: (reason?: any) => void;
    protected type?: { new(): any };

    constructor(network: ChainReference, client: ChainClient, protected listener: () => void) {
        super(network, client);
    }

    async executeDeferred() {
        if (!this.deferred) {
            throw new Error("No deferred execution found.");
        }
        let batch = this.batch;
        try {
            let res = await this.client.multicall(this.chain, batch);
            let data = this.finalize(res);
            this.result(data);
        } catch (err) {
            this.error(err);
        }
    }

    finalize<T extends {}>(result: ChainResponse[]): {} {
        return super.finalize(result, this.type);
    }

    execute<T extends {}>(type?: { new(): T }): Promise<T> {
        if (this.deferred) {
            throw new Error("Deferred execution is already present, can't execute again");
        }
        this.type = type;
        this.deferred = true;
        let promise = new Promise<any>((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
        });

        this.listener();

        return promise;
    }

    result(data: any) {
        if (!this.resolve) {
            throw new Error("Can't set result, no resolve function found")
        }

        this.resolve(data);
        this.clean();
    }

    error(data: any) {
        if (!this.reject) {
            throw new Error("Can't set error, no reject function found")
        }

        this.reject(data);
        this.clean();
    }

    protected clean() {
        this.resolve = undefined;
        this.reject = undefined;
    }
}
