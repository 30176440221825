export class LiteralSourceProvider {
    constructor() {
        this.name = "literal";
    }
    getValue(provider, source) {
        return {
            value() {
                return source.value;
            },
        };
    }
}
