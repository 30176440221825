import { DecoderData, LoaderFunction } from "./DecoderData";
import { ErrorFragment, EventFragment, Fragment, FunctionFragment, Indexed, Result } from "@ethersproject/abi";
import { BigNumber as BigNum } from "@ethersproject/bignumber";
import { CustomInterface } from "./CustomCoder";
import * as diff from "fast-array-diff";
import {
    Typing,
    BaseFragment,
    EthBooleanArrayParameter,
    EthBooleanParameter,
    EthIndexedParameter,
    EthLog,
    EthMethod,
    EthParameter,
    EthStringArrayParameter,
    EthStringParameter,
    EthTupleArrayParameter,
    EthTupleParameter,
    ethParamsToValues,
} from "@blockwell/eth-types";
import ErrorCodes from "./ErrorCodes";

export type EthLogDecoded = Pick<EthLog, "event" | "params" | "timestamp">;

const BuiltinErrors: Record<string, BaseFragment> = {
    "08c379a0": { name: "Error", inputs: [ {type: "string", name: "message"} ], type: "builtin" },
    "4e487b71": { name: "Panic", inputs: [ {type: "uint256"} ], type: "builtin" }
}

/**
 * A class that decodes Ethereum method input and logs.
 */
export class Decoder {
    public readonly data: DecoderData;
    private interface = new CustomInterface([]);
    private readonly funcs: LoaderFunction;
    private readonly errors: LoaderFunction;

    constructor(data: DecoderData) {
        this.data = data;
        this.funcs = data.func.bind(data);
        this.errors = data.error.bind(data);
    }

    method(input: string, cache?: Record<string, BaseFragment[]>) {
        return this.methodLike(input, this.funcs, cache);
    }

    async error(input: string, cache?: Record<string, BaseFragment[]>) {
        // No proper data is less than 4 characters. Could be 0x or 0x0.
        if (input.length < 4) {
            return null;
        }
        let trimmed = input.toLowerCase();
        if (trimmed.startsWith("0x")) {
            trimmed = trimmed.slice(2);
        }

        // Blockwell custom error codes are bytes2, so 4 hex characters
        if (trimmed.length === 4 && trimmed.startsWith("e")) {
            let error = ErrorCodes[trimmed];

            if (error) {
                return error;
            }
        }

        return await this.methodLike(trimmed, this.errors, cache);
    }

    protected async methodLike(
        input: string,
        loader: LoaderFunction,
        cache?: Record<string, BaseFragment[]>
    ): Promise<EthMethod[]> {
        let sig = input.slice(0, 8);

        let result: BaseFragment[];


        let builtIn = BuiltinErrors[sig];

        if (builtIn) {
            result = [builtIn];
        } else {
            if (cache) {
                result = cache[sig];
            }
            if (!Array.isArray(result)) {
                result = await loader(sig);
                if (cache) {
                    cache[sig] = result;
                }
            }
        }

        if (!result || result.length === 0) {
            return null;
        }
        result.sort((a, b) => b.inputs?.length || 0 - a.inputs?.length || 0);

        let results: { decoded: EthParameter[]; abi: BaseFragment; distance: number }[] = [];
        for (let abi of result) {
            try {
                let dec: { distance: number; decoded: EthParameter[] };
                if (abi.type === "error" || abi.type === "builtin") {
                    dec = this.tryError(input, abi);
                } else {
                    dec = this.tryMethod(input, abi);
                }
                results.push({
                    decoded: dec.decoded,
                    abi,
                    distance: dec.distance,
                });
            } catch (err) {}
        }

        if (results.length === 0) {
            return null;
        }

        results.sort((a, b) => a.distance - b.distance);

        return results.map((it) => {
            return {
                name: it.abi.name,
                inputs: it.decoded,
                distance: it.distance,
            };
        });
    }

    protected tryMethod(input: string, abi: BaseFragment) {
        let f = {
            ...abi,
        };
        if (!f.stateMutability) {
            f.stateMutability = "payable";
        }

        let frag = FunctionFragment.from(f);
        let decoded = this.interface.decodeFunctionData(frag, "0x" + input);
        let params = this.decodeToParameters(decoded, abi);

        let encoded: string;
        try {
            encoded = this.interface.encodeFunctionData(frag, ethParamsToValues(params));
        } catch (err) {}

        return {
            distance: this.decodeDistance(input, encoded),
            decoded: params,
        };
    }

    protected tryError(input: string, abi: BaseFragment) {
        let decoded: Result;
        let frag: ErrorFragment;
        if (abi.type === "builtin") {
            decoded = this.interface._abiCoder.decode(abi.inputs as any, "0x" + input.slice(8));
        } else {
            frag = ErrorFragment.from(abi);
            decoded = this.interface.decodeErrorResult(frag, "0x" + input);
        }
        let params = this.decodeToParameters(decoded, abi);
        let distance: number;

        if (frag) {
            let encoded: string;
            try {
                encoded = this.interface.encodeErrorResult(frag, ethParamsToValues(params)).slice(2);
            } catch (err) {
            }
            distance = this.decodeDistance(input, encoded);
        } else {
            distance = 0;
        }

        return {
            distance,
            decoded: params,
        };
    }

    protected decodeToParameters(decoded: Result, abi: BaseFragment) {
        let params: EthParameter[] = [];
        let i = 0;
        if (abi.inputs) {
            for (let it of abi.inputs) {
                params.push(Decoder.convertParam(Typing.parse(it), decoded[i]));
                ++i;
            }
        }
        return params;
    }

    protected decodeDistance(input: string, encoded: string) {
        let exact = encoded === input;
        let distance = 0;
        if (!exact) {
            distance = Decoder.functionEncodingDistance(input, encoded);
        }
        return distance;
    }

    logs(events: EthLog[]) {
        return Promise.all(events.map((it) => this.log(it)));
    }

    async log(event: EthLog) {
        if (!event || !event.topics || event.topics.length === 0) {
            return null;
        }

        const topic0 = event.topics[0].slice(2);
        const result = await this.data.event(topic0);

        if (!result || result.length < 1) {
            return null;
        }

        let decoded: EthLogDecoded;

        for (let abi of result) {
            try {
                let dec = this.tryLog(event, abi);
                if (dec) {
                    decoded = dec;
                    break;
                }
            } catch (err) {}
        }

        return decoded;
    }

    protected tryLog(event: EthLog, abi: BaseFragment): EthLogDecoded {
        let decoded = this.interface.decodeEventLog(
            EventFragment.from(abi),
            event.data,
            event.topics
        );
        let params: EthParameter[] = [];
        let i = 0;
        for (let it of abi.inputs) {
            params.push(Decoder.convertParam(Typing.parse(it as EthParameter), decoded[i]));
            ++i;
        }
        return {
            event: abi.name,
            params,
            timestamp: event.timestamp,
        };
    }

    static convertParam(typing: Typing, value: any): EthParameter {
        let it: EthParameter = {
            type: typing.toString(),
        };

        if (typing.parameterName) {
            it.name = typing.parameterName;
        }
        if (typing.indexed) {
            it.indexed = true;
        }

        if (value instanceof Indexed) {
            it = <EthIndexedParameter>{
                ...it,
                hash: value.hash,
            };
        } else {
            if (typing.array) {
                if (!Array.isArray(value)) {
                    throw new Error(
                        "Decoder type is an array, but it was not an array value: " +
                            value +
                            " - " +
                            typing
                    );
                }
                if (typing.name === "tuple") {
                    let param: EthTupleArrayParameter = {
                        ...it,
                        values: [],
                    };
                    for (let element of value) {
                        param.values.push(this.convertParam(typing.arrayElement(), element) as EthTupleParameter);
                    }
                    it = param;
                } else if (typing.name === "bool") {
                    it = <EthBooleanArrayParameter>{
                        ...it,
                        values: value.map((it) => !!it),
                    };
                } else {
                    it = <EthStringArrayParameter>{
                        ...it,
                        values: value.map((it) => {
                            if (BigNum.isBigNumber(it)) {
                                return it.toString();
                            } else if (typeof it === "number") {
                                return it.toString();
                            } else if (typeof it.bytes === "string") {
                                return it.bytes;
                            } else {
                                return it;
                            }
                        }),
                    };
                }
            } else {
                if (Array.isArray(value)) {
                    if (typing.name === "tuple") {
                        let param: EthTupleParameter = {
                            ...it,
                            components: [],
                        };
                        let list = [].concat(value);
                        for (let comp of typing.components) {
                            param.components.push(this.convertParam(comp, list.shift()));
                        }
                        it = param;
                    } else {
                        throw new Error(
                            "Decoder received an array value, but it was not an array or tuple type: " +
                                value +
                                " - " +
                                typing
                        );
                    }
                } else if (typing.name === "bool") {
                    it = <EthBooleanParameter>{
                        ...it,
                        value: !!value,
                    };
                } else {
                    let param: EthStringParameter = {
                        ...it,
                        value: null,
                    };
                    if (BigNum.isBigNumber(value)) {
                        param.value = value.toString();
                    } else if (typeof value === "number") {
                        param.value = value.toString();
                    } else if (value.bytes) {
                        param.bytes = value.bytes;
                    } else {
                        param.value = value;
                    }
                    it = param;
                }
            }
        }

        return it as EthParameter;
    }

    static functionEncodingDistance(original: string, encoded: string) {
        let left = original.slice(8).match(/.{1,64}/g) || [];
        let right = encoded.slice(8).match(/.{1,64}/g) || [];

        let ops = diff.diff(left, right);
        return ops.removed.length + ops.added.length;
    }
}
