import { Mutex } from "async-mutex";

export class MutexMap {


    protected mutexMap: Record<string, Mutex> = {};

    protected getMutex(key: string) {
        let mutex = this.mutexMap[key];

        if (!mutex) {
            mutex = new Mutex();
            this.mutexMap[key] = mutex;
        }

        return mutex;
    }

    protected clearMutex(key: string) {
        let mutex = this.mutexMap[key];
        if (mutex && !mutex.isLocked()) {
            delete this.mutexMap[key];
        }
    }
}
