import { JsonFragment } from "@ethersproject/abi";
import BigNumber from "bignumber.js";
import { DumbappDataProvider } from "./DumbappDataProvider";
import {
    DumbappArgument,
    DumbappCallSourceParameters,
    DumbappSource,
    DumbappStep,
    DumbappValue,
} from "../schema";
import { ArgumentValueProvider, resolveBigNumberishSourcable, resolveStringSourcable } from "../state";
import { DumbappValueFunction } from "../DumbappValueFunction";
import standardAbis from "./standard-abis";
import { resolveBigNumberishValue, resolveStringValue } from "../state";
import { Chain } from "@blockwell/chain-client";

export class CallSourceProvider implements DumbappDataProvider<DumbappCallSourceParameters> {
    name = "call";

    getValue(
        provider: ArgumentValueProvider,
        source: DumbappCallSourceParameters,
        arg: DumbappArgument,
        step?: DumbappStep
    ): DumbappValueFunction {
        if (!source.abi) {
            throw new Error(`Missing ABI for CallProvider.`);
        }

        let abi: JsonFragment;
        if (typeof source.abi === "string") {
            abi = standardAbis[source.abi];
        } else {
            abi = source.abi;
        }

        return {
            async value() {
                let res;

                let addressSources = [source.address, step?.address];
                let networkSources = [step?.network];

                let address = await resolveStringSourcable(provider, addressSources, step);
                if (!address) {
                    console.warn("No address in call source provider");
                    return null;
                }

                let network = await resolveStringSourcable(provider, networkSources, step);
                if (!address) {
                    console.warn("No network in call source provider");
                    return null;
                }

                let args: any[] = [];
                if (source.arguments) {
                    if (Array.isArray(source.arguments)) {
                        args = source.arguments;
                    } else {
                        args.push(source.arguments);
                    }
                }
                try {
                    res = await Chain.readContract(network, address, [abi], abi.name, args);
                } catch (err) {
                    console.warn("CallSourceProvider readContract error", {
                        err,
                        source,
                        address,
                    });
                }

                const decimals = await resolveBigNumberishSourcable(
                    provider,
                    [arg?.decimals],
                    step
                );

                if (res && decimals) {
                    if (Array.isArray(res)) {
                        res = res.map((it) => {
                            if (typeof it === "string" || typeof it === "number") {
                                return new BigNumber(it).div(`1e${decimals}`).toString(10)
                            }
                            return null;
                        });
                    } else {
                        if (typeof res === "string" || typeof res === "number") {
                            res = new BigNumber(res).div(`1e${decimals}`).toString(10);
                        }
                    }
                }

                return res;
            },
        };
    }
}
