import { DumbappExtraProcessor } from "./DumbappExtraProcessor";
import { ExecutorParameters } from "../executor";
import { DumbappSubmission } from "../submission";
import { findEvents, getTokenData } from "./util";
import { ethResolveStringParameter } from "@blockwell/eth-types";
import { LottoExtra } from "../schema";

export class LottoExtraProcessor implements DumbappExtraProcessor {
    key = "lotto";

    async process(parameters: ExecutorParameters, submission: DumbappSubmission) {
        let wins: LottoExtra[] = [];

        for (let { event, step } of findEvents(submission, /^(TokenWin|ItemDropped)$/)) {
            let address = ethResolveStringParameter(event.params, "token");
            let token = await getTokenData(parameters.contracts, step.network, address);
            let value = ethResolveStringParameter(event.params, "value");
            let dropAmount = ethResolveStringParameter(event.params, "dropAmount");
            wins.push({
                token: token,
                value: value || dropAmount,
            });
        }
        for (let { event, step } of findEvents(submission, "Play")) {
            let value = ethResolveStringParameter(event.params, "win");
            let transfer = step.events.find(
                (it) => it.event === "Transfer" && ethResolveStringParameter(it.params, "2") === value
            );
            if (transfer) {
                let address = transfer.address;
                let token = await getTokenData(parameters.contracts, step.network, address);
                wins.push({
                    token: token,
                    value,
                });
            }
        }
        return wins;
    }
}
